import React from 'react';
import styles from './App.module.scss';

import AzureAD, { AuthenticationState, IAzureADFunctionProps } from 'react-aad-msal';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { mergeThemes, Provider, teamsTheme } from '@fluentui/react-northstar';
import { AuthService } from './services/AuthService';
import { Group, PermissionService } from './services/PermissionService';

import Header from './components/header/Header';
import Sidebar from './components/sidebar/Sidebar';

import Home from './pages/content/Home';

import Dashboard from './pages/content/Dashboard';
import CANCOMProducts from './pages/cancom/products/CANCOMProducts';
import MicrosoftDashboard from './pages/microsoft/dashboard/MicrosoftDashboard';
import MicrosoftSubscriptions from './pages/microsoft/subscriptions/MicrosoftSubscriptions';
import MicrosoftProductRequest from './pages/microsoft/products/MicrosoftProducts';
import MicrosoftContacts from './pages/microsoft/contacts/MicrosoftContacts';

import AdministrationDashboard from './pages/admin/dashboard/AdministrationDashboard';
import UserManagement from './pages/admin/users/UserManagement';
import ContactManagement from './pages/admin/contacts/ContactManagement';
import CANCOMProductManagement from './pages/admin/cancom/products/CANCOMProductManagement';
import MicrosoftTenants from './pages/admin/microsoft/tenants/MicrosoftTenants';
import MicrosoftProducts from './pages/admin/microsoft/products/MicrosoftProducts';

import { GetUserMicrosoftTenantsDTO } from './models/Microsoft/getUserMicrosoftTenantsDTO';
import { initializeIcons } from '@fluentui/react';
import MigrationNotice from './components/migration/MigrationNotice';

const theme: any = {
  siteVariables: {
    colors: {
      brand: {}
    }
  },
  componentVariables: {
    Button: {
      primaryColor: '#ffffff',
      primaryColorHover: '#ffffff',
      primaryBackgroundColor: '#ff0035',
      primaryBackgroundColorActive: '#cc002a',
      primaryBackgroundColorHover: '#cc002a',
    },
  },
}

interface IAppProps { }

interface IAppState {
  initialized: boolean;
  initializing: boolean;
  microsoftTenants: GetUserMicrosoftTenantsDTO[];
}

class App extends React.Component<IAppProps, IAppState> {

  public state: IAppState = {
    initialized: false,
    initializing: false,
    microsoftTenants: [],
  }

  public componentDidMount() {
    initializeIcons();
  }

  public onApplicationInitializationFinished = (response: any) => {
    this.setState({ initialized: true, ...response });
  }

  public getRoutes = (accountInfo: any, authenticationState: any): any[] => {

    let key: number = 0;
    const routes: any[] = [];

    if (authenticationState === AuthenticationState.Authenticated && PermissionService.isMemberOfAnyGroup([Group.ADMINISTRATORS, Group.EMPLOYEES, Group.CUSTOMERS])) {
      routes.push(<Route key={key++} path="/cancom/products" component={CANCOMProducts} />);
      routes.push(<Route key={key++} path="/microsoft/:tenantId/dashboard" component={MicrosoftDashboard} />);
      routes.push(<Route key={key++} path="/microsoft/:tenantId/subscriptions" component={MicrosoftSubscriptions} />);
      routes.push(<Route key={key++} path="/microsoft/:tenantId/products" component={MicrosoftProductRequest} />);
      routes.push(<Route key={key++} path="/microsoft/:tenantId/contacts" component={MicrosoftContacts} />);
      routes.push(<Route key={key++} exact path="/microsoft/:tenantId" render={props => <Redirect to={`/microsoft/${props.match.params.tenantId}/dashboard`} />} />);
    }

    if (authenticationState === AuthenticationState.Authenticated && PermissionService.isMemberOfAnyGroup([Group.ADMINISTRATORS, Group.EMPLOYEES])) {
      routes.push(<Route key={key++} path="/admin/dashboard" component={AdministrationDashboard} />);
      if (PermissionService.isMemberOfAnyGroup([Group.ADMINISTRATORS])) routes.push(<Route key={key++} path="/admin/users" component={UserManagement} />);
      routes.push(<Route key={key++} path="/admin/contacts" component={ContactManagement} />);
      routes.push(<Route key={key++} path="/admin/cancom/products" component={CANCOMProductManagement} />);
      routes.push(<Route key={key++} path="/admin/microsoft/tenants" component={MicrosoftTenants} />);
      routes.push(<Route key={key++} path="/admin/microsoft/products" component={MicrosoftProducts} />);
      routes.push(<Redirect key={key++} from="/admin/microsoft" to="/admin/microsoft/tenants" />);
      routes.push(<Redirect key={key++} from="/admin" to="/admin/dashboard" />);
    }

    if (authenticationState === AuthenticationState.Authenticated) {
      routes.push(<Route key={key++} component={Dashboard} />);
    } else {
      routes.push(<Route key={key++} component={Home} />);
    }

    return routes;

  }

  render() {
    return (
      <AzureAD provider={AuthService.getAuthProvider()}>
        {({ accountInfo, authenticationState }: IAzureADFunctionProps) => {
          return (
            <Provider theme={mergeThemes(teamsTheme, theme)}>
              <BrowserRouter>
                <MigrationNotice />
                <div className={styles.Layout}>
                  <Header authenticationState={authenticationState} accountInfo={accountInfo} />
                  <div className={styles.ContentContainer}>
                    <div className={styles.Sidebar}>
                      <Sidebar authenticationState={authenticationState} accountInfo={accountInfo} />
                    </div>
                    <div className={styles.MainContent}>
                      <Switch>
                        {this.getRoutes(accountInfo, authenticationState).map((route) => route)}
                      </Switch>
                    </div>
                  </div>
                </div>
              </BrowserRouter>
            </Provider>
          );
        }}
      </AzureAD>
    );
  }

}

export default App;

import { Flex } from '@fluentui/react-northstar';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import MicrosoftActivityFeed from './MicrosoftActivityFeed';
import MicrosoftActivityStatistic from './MicrosoftActivityStatistic';

interface IAdministrationDashboardProps extends WithTranslation {

}

interface IAdministrationDashboardState {

}

class AdministrationDashboard extends React.Component<IAdministrationDashboardProps, IAdministrationDashboardState> {
    render() {
        return (
            <div>
                <h1>{this.props.t('admin.dashboard.title')}</h1>
                <Flex gap="gap.small">
                    <div style={{ width: '50%' }}>
                        <MicrosoftActivityStatistic />
                    </div>
                    <div style={{ width: '50%' }}>
                        <MicrosoftActivityFeed />
                    </div>
                </Flex>
            </div>
        );
    }
}

export default withTranslation()(AdministrationDashboard);
import { Flex, Loader, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import { GetUserMicrosoftTenantDetailsDTO } from '../../../models/Microsoft/getUserMicrosoftTenantDetailsDTO';
import styles from './MicrosoftDashboard.module.scss';

interface IMicrosoftDashboardProfileProps extends WithTranslation, RouteComponentProps {

}

interface IMicrosoftDashboardProfileState {
    error: IAlertErrorProps | null;
    loading: boolean;
    tenantDetails: GetUserMicrosoftTenantDetailsDTO | null;
}

class MicrosoftDashboardProfile extends React.Component<IMicrosoftDashboardProfileProps, IMicrosoftDashboardProfileState> {

    public state: IMicrosoftDashboardProfileState = {
        error: null,
        loading: false,
        tenantDetails: null,
    }

    public componentDidMount() {
        this.getTenantDetails();
    }

    public componentDidUpdate(prevProps: IMicrosoftDashboardProfileProps) {
        if ((prevProps.match.params as any).tenantId !== (this.props.match.params as any).tenantId) {
            this.getTenantDetails();
        }
    }

    public getTenantDetails = async () => {
        try {
            this.setState({ error: null, loading: true, tenantDetails: null });
            const tenantId = (this.props.match.params as any).tenantId;
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/tenants/' + tenantId);
            this.setState({ loading: false, tenantDetails: response.data });
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getTenantDetails() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.microsoft.tenant.undefined', retry: () => this.getTenantDetails() } });
            }
        }
    }

    render() {
        return (
            <div>
                <Segment style={{ marginBottom: '1px' }}>
                    <Text size="large">{this.props.t('microsoft.dashboard.profile.title')}</Text>
                </Segment>
                <Segment>
                    {this.state.loading ? <Loader /> : null}
                    {!this.state.loading && this.state.error ? <AlertError {...this.state.error} /> : null}
                    {!this.state.loading && !this.state.error ? <React.Fragment>
                        <Flex gap="gap.small" className={styles.MicrosoftDashboardProfile}>
                            <div style={{ width: '50%' }}>
                                <p><Text weight="semibold">{this.props.t('microsoft.dashboard.profile.company.title')}</Text></p>
                                <p>
                                    <Text weight="semibold">{this.props.t('microsoft.dashboard.profile.company.name')}:</Text> {this.state.tenantDetails?.companyProfile?.name}<br />
                                    <Text weight="semibold">{this.props.t('microsoft.dashboard.profile.company.domain')}:</Text> {this.state.tenantDetails?.companyProfile?.domain}
                                </p>
                            </div>
                            <div style={{ width: '50%' }}>
                                <p><Text weight="semibold">{this.props.t('microsoft.dashboard.profile.billing.title')}</Text></p>
                                <p >
                                    <Text weight="semibold">{this.props.t('microsoft.dashboard.profile.billing.name')}: </Text>
                                    {this.state.tenantDetails?.billingProfile?.address?.firstname || this.state.tenantDetails?.billingProfile?.address?.lastname
                                        ? `${this.state.tenantDetails?.billingProfile?.address?.firstname} ${this.state.tenantDetails?.billingProfile?.address?.lastname}`
                                        : `${this.state.tenantDetails?.billingProfile?.firstname} ${this.state.tenantDetails?.billingProfile?.lastname}`}
                                    <br /><Text weight="semibold">{this.props.t('microsoft.dashboard.profile.billing.mail')}:</Text> {this.state.tenantDetails?.billingProfile?.mail}
                                </p>
                            </div>
                        </Flex>
                    </React.Fragment> : null}
                </Segment>
            </div>
        );
    }

}

export default withTranslation()(MicrosoftDashboardProfile);
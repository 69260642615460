import { Alert, Button, CloseIcon, Dialog, Flex, Input, Text, TextArea } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ReactImageUploading from 'react-images-uploading';
import validate from 'validate.js';
import { FormValidationMessages } from '../../../components/formValidation/FormValidationMessages';
import { CreateContactDTO } from '../../../models/Administration/ContactController/createContactDTO';

interface IContactCreateDialogProps extends WithTranslation {
    onClose?: any;
}

interface IContactCreateDialogState {
    error: string | null;
    saving: boolean;
    formData: CreateContactDTO;
    formValidation?: {
        firstname?: string[];
        lastname?: string[];
        jobTitle?: string[];
        phone?: string[];
        mobile?: string[];
        mail?: string[];
        description?: string[];
    };
}

class ContactCreateDialog extends React.Component<IContactCreateDialogProps, IContactCreateDialogState> {

    public state: IContactCreateDialogState = {
        error: null,
        saving: false,
        formData: {} as CreateContactDTO,
        formValidation: {},
    }

    public onChange = (field: string, value: any) => {
        const formData: any = { ...this.state.formData };
        formData[field] = value;
        this.setState({ formData: formData });
    }

    public submitForm = async () => {

        const errors = validate(this.state.formData, {
            firstname: {
                presence: { allowEmpty: false, message: this.props.t('admin.contacts.dialog.common.validation.presence') },
            },
            lastname: {
                presence: { allowEmpty: false, message: this.props.t('admin.contacts.dialog.common.validation.presence') },
            },
            phone: {
                presence: { allowEmpty: false, message: this.props.t('admin.contacts.dialog.common.validation.presence') },
            },
            mail: {
                presence: { allowEmpty: false, message: this.props.t('admin.contacts.dialog.common.validation.presence') },
                email: { message: this.props.t('admin.contacts.dialog.common.validation.email') },
            },
        });

        this.setState({ formValidation: errors });

        if (!errors) {
            try {
                this.setState({ saving: true, error: null });
                await Axios.post(window.env.REACT_APP_API_BASE + '/api/admin/contacts', this.state.formData);
                this.setState({ saving: false });
                this.props.onClose(true);
            } catch (error: any) {
                console.error(error);
                if (error?.response?.data?.errorCode) {
                    this.setState({ saving: false, error: error.response.data.errorCode });
                } else {
                    this.setState({ saving: false, error: 'error.admin.contacts.createcontact' });
                }
            }
        }

    }

    render() {

        const content = (
            <React.Fragment>
                {!this.state.saving && this.state.error ? <Alert danger={true} content={this.props.t(this.state.error)} /> : null}
                <Flex gap="gap.small">
                    <div style={{ width: '50%', marginTop: '12px' }}>
                        <Input label={this.props.t('admin.contacts.dialog.common.labels.firstname')} required={true} fluid={true}
                            value={this.state.formData.firstname} disabled={this.state.saving}
                            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('firstname', evt.target?.value) }}
                            error={this.state.formValidation?.firstname && this.state.formValidation?.firstname.length > 0} />
                        <FormValidationMessages messages={this.state.formValidation?.firstname} />
                    </div>
                    <div style={{ width: '50%', marginTop: '12px' }}>
                        <Input label={this.props.t('admin.contacts.dialog.common.labels.phone')} required={true} fluid={true}
                            value={this.state.formData.phone} disabled={this.state.saving}
                            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('phone', evt.target?.value) }}
                            error={this.state.formValidation?.phone && this.state.formValidation?.phone.length > 0} />
                        <FormValidationMessages messages={this.state.formValidation?.phone} />
                    </div>
                </Flex>
                <Flex gap="gap.small">
                    <div style={{ width: '50%', marginTop: '12px' }}>
                        <Input label={this.props.t('admin.contacts.dialog.common.labels.lastname')} required={true} fluid={true}
                            value={this.state.formData.lastname} disabled={this.state.saving}
                            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('lastname', evt.target?.value) }}
                            error={this.state.formValidation?.lastname && this.state.formValidation?.lastname.length > 0} />
                        <FormValidationMessages messages={this.state.formValidation?.lastname} />
                    </div>
                    <div style={{ width: '50%', marginTop: '12px' }}>
                        <Input label={this.props.t('admin.contacts.dialog.common.labels.mobile')} required={false} fluid={true}
                            value={this.state.formData.mobile} disabled={this.state.saving}
                            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('mobile', evt.target?.value) }}
                            error={this.state.formValidation?.mobile && this.state.formValidation?.mobile.length > 0} />
                        <FormValidationMessages messages={this.state.formValidation?.mobile} />
                    </div>
                </Flex>
                <Flex gap="gap.small">
                    <div style={{ width: '50%', marginTop: '12px' }}>
                        <Input label={this.props.t('admin.contacts.dialog.common.labels.jobtitle')} required={false} fluid={true}
                            value={this.state.formData.jobTitle} disabled={this.state.saving}
                            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('jobTitle', evt.target?.value) }}
                            error={this.state.formValidation?.jobTitle && this.state.formValidation?.jobTitle.length > 0} />
                        <FormValidationMessages messages={this.state.formValidation?.jobTitle} />
                    </div>
                    <div style={{ width: '50%', marginTop: '12px' }}>
                        <Input label={this.props.t('admin.contacts.dialog.common.labels.mail')} required={true} fluid={true}
                            value={this.state.formData.mail} disabled={this.state.saving}
                            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('mail', evt.target?.value) }}
                            error={this.state.formValidation?.mail && this.state.formValidation?.mail.length > 0} />
                        <FormValidationMessages messages={this.state.formValidation?.mail} />
                    </div>
                </Flex>
                <Flex gap="gap.small">
                    <div style={{ width: '50%', marginTop: '12px' }}>
                        <div style={{ marginBottom: '4px' }}><Text content={this.props.t('admin.contacts.dialog.common.labels.description')} /></div>
                        <TextArea required={false} fluid={true} value={this.state.formData.description} disabled={this.state.saving}
                            variables={{ height: '160px' }} resize="vertical" onChange={(evt: any) => { this.onChange('description', evt.target?.value) }} />
                    </div>
                    <div style={{ width: '50%', marginTop: '12px' }}>
                        <div style={{ marginBottom: '4px' }}><Text content={this.props.t('admin.contacts.dialog.common.labels.picture')} /></div>
                        <ReactImageUploading acceptType={['jpg', 'png']} resolutionType="less" resolutionWidth={600} resolutionHeight={800}
                            value={this.state.formData.picture ? [{ dataURL: this.state.formData.picture }] : []}
                            onChange={(images: any[]) => { this.onChange('picture', images && images.length > 0 ? images[0]['dataURL'] : null) }}>
                            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps, errors }) => (
                                <div>
                                    {imageList && imageList.length > 0 ?
                                        <div {...dragProps}>
                                            {imageList.map((image, index) => (
                                                <div key={index} >
                                                    <img src={image['dataURL']} alt="" style={{ maxHeight: '128px' }} />
                                                    <div style={{ marginTop: '4px' }}>
                                                        <Button size="small" content={this.props.t('admin.contacts.dialog.common.labels.change')} onClick={() => onImageUpdate(index)} />&nbsp;
                                                        <Button size="small" content={this.props.t('admin.contacts.dialog.common.labels.remove')} onClick={() => onImageRemove(index)} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div style={{ border: isDragging ? '2px dashed #888888' : '2px dashed #cccccc', textAlign: 'center', padding: '20px', cursor: 'pointer' }} onClick={onImageUpload}  {...dragProps}>
                                            <strong>{this.props.t('admin.contacts.dialog.common.labels.dropzone')}</strong><br />
                                            <small>{this.props.t('admin.contacts.dialog.common.labels.filesize')}</small>
                                        </div>
                                    }
                                    {errors && errors.acceptType ? <FormValidationMessages messages={[this.props.t('admin.contacts.dialog.common.validation.filetype')]} /> : null}
                                    {errors && errors.resolution ? <FormValidationMessages messages={[this.props.t('admin.contacts.dialog.common.validation.filesize')]} /> : null}
                                </div>
                            )}
                        </ReactImageUploading>
                    </div>
                </Flex>
            </React.Fragment>
        );

        return (
            <Dialog
                open={true}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose(false)}
                onConfirm={this.submitForm}
                cancelButton={{ content: this.props.t('admin.contacts.dialog.common.cancel'), disabled: this.state.saving }}
                confirmButton={{ content: this.props.t('admin.contacts.dialog.create.confirm'), primary: true, disabled: this.state.saving, loading: this.state.saving }}
                content={content}
                header={this.props.t('admin.contacts.dialog.create.title')}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.contacts.dialog.common.close'), onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.saving }} />
        );

    }

}

export default withTranslation()(ContactCreateDialog);
import Axios from 'axios';
import React from 'react';
import { Alert, CloseIcon, Dialog } from '@fluentui/react-northstar';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { GetContactsDTO } from '../../../models/Administration/ContactController/getContactsDTO';

interface IContactDeleteDialogProps extends WithTranslation {
    onClose?: any;
    contact: GetContactsDTO;
}

interface IContactDeleteDialogState {
    error: string | null;
    loading: boolean;
}

class ContactDeleteDialog extends React.Component<IContactDeleteDialogProps, IContactDeleteDialogState> {

    public state: IContactDeleteDialogState = {
        error: null,
        loading: false,
    }

    public async componentDidMount() {

    }

    public submitForm = async () => {
        try {
            this.setState({ loading: true, error: null });
            await Axios.delete(window.env.REACT_APP_API_BASE + '/api/admin/contacts/' + this.props.contact?.id);
            this.setState({ loading: false });
            this.props.onClose(true);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: error.response.data.errorCode });
            } else {
                this.setState({ loading: false, error: 'error.admin.contacts.deletecontact' });
            }
        }
    }

    render() {

        const content = (
            <React.Fragment>
                {!this.state.loading && this.state.error ? <Alert danger={true} content={this.props.t(this.state.error)} /> : null}
                <div style={{ marginTop: '6px' }}><Trans i18nKey="admin.contacts.dialog.delete.message" values={{ name: this.props.contact.lastname + ' ' + this.props.contact.firstname }} /></div>
            </React.Fragment>
        );

        return (
            <Dialog
                open={true}
                styles={{ width: '420px' }}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose(false)}
                onConfirm={this.submitForm}
                cancelButton={{ content: this.props.t('admin.contacts.dialog.common.cancel'), disabled: this.state.loading }}
                confirmButton={{ content: this.props.t('admin.contacts.dialog.delete.confirm'), primary: true, disabled: this.state.loading, loading: this.state.loading }}
                content={content}
                header={this.props.t('admin.contacts.dialog.delete.title')}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.contacts.dialog.common.close'), onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.loading }} />
        );

    }

}

export default withTranslation()(ContactDeleteDialog);
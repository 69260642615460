import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Checkbox, CloseIcon, Dialog, Flex, Input, SearchIcon } from '@fluentui/react-northstar';
import Axios from 'axios';
import { GetTenantsDTO } from '../../../../models/Administration/MicrosoftTenantController/getTenantsDTO';
import { GetAvailableTenantProductsDTO } from '../../../../models/Administration/MicrosoftTenantController/getAvailableTenantProductsDTO';
import { GetTenantProductsDTO } from '../../../../models/Administration/MicrosoftTenantController/getTenantProductsDTO';
import styles from './MicrosoftTenants.module.scss';
import { LocalizationService } from '../../../../services/LocalizationService';
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import CartVariantIcon from 'mdi-react/CartVariantIcon';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import AlertError, { IAlertErrorProps } from '../../../../components/alerts/AlertError';
import MicrosoftProductTypeBadge from '../../../../components/microsoft/ProductTypeBadge';

interface IMicrosoftTenantProductsDialogProps extends WithTranslation {
    onClose?: any;
    tenant: GetTenantsDTO;
}

interface IMicrosoftTenantProductsDialogState {
    loading: boolean;
    loadingError: IAlertErrorProps | null;
    saving: boolean;
    savingError: IAlertErrorProps | null;
    assignedProducts: GetTenantProductsDTO[];
    availableProducts: GetAvailableTenantProductsDTO[];
    searchInput: string;
    items: GetAvailableTenantProductsDTO[];
}

class MicrosoftTenantProductsDialog extends React.Component<IMicrosoftTenantProductsDialogProps, IMicrosoftTenantProductsDialogState> {

    public state: IMicrosoftTenantProductsDialogState = {
        loading: true,
        loadingError: null,
        saving: false,
        savingError: null,
        assignedProducts: [],
        availableProducts: [],
        searchInput: '',
        items: [],
    }

    public componentDidMount() {
        this.getProducts();
    }

    public getProducts = async () => {
        try {
            this.setState({ loading: true, assignedProducts: [], availableProducts: [], loadingError: null });
            const responseAssignedProducts = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/products');
            const responseAvailableProducts = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/products/available');
            this.setState({ loading: false, assignedProducts: responseAssignedProducts.data, availableProducts: responseAvailableProducts.data });
            this.onSearch(this.state.searchInput);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, loadingError: { message: error.response.data.errorCode, retry: () => this.getProducts() } });
            } else {
                this.setState({ loading: false, loadingError: { message: 'error.admin.microsoft.tenants.loadproducts', retry: () => this.getProducts() } });
            }
        }
    }

    public onSearch = (searchInput: string) => {
        const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
        const items = this.state.availableProducts.filter((product: GetAvailableTenantProductsDTO) => LocalizationService.getLocalizedString(product.title, this.props.i18n.language)?.match(searchRegex) || LocalizationService.getLocalizedString(product.summary, this.props.i18n.language)?.match(searchRegex));
        this.setState({ searchInput: searchInput, items: items });
    }

    public onChange = (product: GetAvailableTenantProductsDTO, value: string, checked: boolean) => {

        const assignedProducts = [...this.state.assignedProducts];
        const assignedProductIndex: number = assignedProducts.findIndex((assignedProduct: GetTenantProductsDTO) => assignedProduct.productId === product.id);

        let assignedProduct: GetTenantProductsDTO;
        if (assignedProductIndex === -1) {
            assignedProduct = {
                productId: product.id,
                purchasable: false,
                visible: false,
            }
        } else {
            assignedProduct = { ...assignedProducts[assignedProductIndex] };
            assignedProducts.splice(assignedProductIndex, 1);
        }

        (assignedProduct as any)[value] = checked;
        assignedProducts.push(assignedProduct);

        this.setState({ assignedProducts: assignedProducts });

    }

    public resetDefaultProductValue = (field: string) => {

        const assignedProducts = [...this.state.assignedProducts];

        this.state.availableProducts.forEach((product: GetAvailableTenantProductsDTO) => {

            const assignedProductIndex: number = assignedProducts.findIndex((assignedProduct: GetTenantProductsDTO) => assignedProduct.productId === product.id);

            let assignedProduct: GetTenantProductsDTO;
            if (assignedProductIndex === -1) {
                assignedProduct = {
                    productId: product.id,
                    purchasable: false,
                    visible: false,
                }
            } else {
                assignedProduct = { ...assignedProducts[assignedProductIndex] };
                assignedProducts.splice(assignedProductIndex, 1);
            }

            if (field === 'visible') {
                (assignedProduct as any)['visible'] = product.defaultVisible;
            } else if (field === 'purchasable') {
                (assignedProduct as any)['purchasable'] = product.defaultPurchasable;
            }

            assignedProducts.push(assignedProduct);

        });

        this.setState({ assignedProducts: assignedProducts });

    }

    public onSubmit = async () => {
        try {
            this.setState({ saving: true, savingError: null });
            await Axios.post(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/products', this.state.assignedProducts);
            this.setState({ saving: false });
            this.props.onClose();
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ saving: false, savingError: { message: error.response.data.errorCode } });
            } else {
                this.setState({ saving: false, savingError: { message: 'error.admin.microsoft.tenants.saveproducts' } });
            }
        }
    }

    render() {

        const columns: IColumn[] = [{
            key: 'name',
            name: this.props.t('admin.microsoft.tenants.dialog.products.headers.name'),
            minWidth: 300,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (product: GetAvailableTenantProductsDTO) => {
                return <React.Fragment>
                    {LocalizationService.getLocalizedString(product.title, this.props.i18n.language)}
                    <MicrosoftProductTypeBadge productType={product.productType} />
                </React.Fragment>;
            }
        }, {
            key: 'visible',
            name: this.props.t('admin.microsoft.tenants.dialog.products.headers.visible'),
            minWidth: 160,
            maxWidth: 160,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (product: GetAvailableTenantProductsDTO) => {
                const assignedProduct: GetTenantProductsDTO | undefined = this.state.assignedProducts.find((assignedProduct: GetTenantProductsDTO) => assignedProduct.productId === product.id);
                return <Checkbox checked={assignedProduct && assignedProduct.visible} disabled={this.state.saving}
                    onChange={(evt: any, input: any) => { this.onChange(product, 'visible', input.checked) }} />;
            }
        }, {
            key: 'purchasable',
            name: this.props.t('admin.microsoft.tenants.dialog.products.headers.purchasable'),
            minWidth: 160,
            maxWidth: 160,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (product: GetAvailableTenantProductsDTO) => {
                const assignedProduct: GetTenantProductsDTO | undefined = this.state.assignedProducts.find((assignedProduct: GetTenantProductsDTO) => assignedProduct.productId === product.id);
                return <Checkbox checked={assignedProduct && assignedProduct.purchasable} disabled={this.state.saving}
                    onChange={(evt: any, input: any) => { this.onChange(product, 'purchasable', input.checked) }} />;
            }
        }];

        const table = <ShimmeredDetailsList
            compact={true}
            columns={columns}
            items={this.state.items}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            enableShimmer={this.state.loading} />;

        const form = (
            <div className={styles.MicrosoftTenantProductsDialog}>
                {!this.state.loading && this.state.loadingError ? <AlertError {...this.state.loadingError} /> : null}
                {!this.state.loading && !this.state.loadingError ? <Flex space="between" style={{ marginTop: '8px' }}>
                    <Flex gap="gap.small">
                        <Button content={this.props.t('admin.microsoft.tenants.dialog.products.resetVisibility')} icon={<EyeOutlineIcon />}
                            onClick={() => this.resetDefaultProductValue('visible')} disabled={this.state.saving} />
                        <Button content={this.props.t('admin.microsoft.tenants.dialog.products.resetPurchasability')} icon={<CartVariantIcon />}
                            onClick={() => this.resetDefaultProductValue('purchasable')} disabled={this.state.saving} />
                    </Flex>
                    <div style={{ width: '280px' }}>
                        <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off" icon={<SearchIcon />} placeholder={this.props.t('admin.microsoft.tenants.dialog.products.search')}
                            value={this.state.searchInput} onChange={(evt: any) => this.onSearch(evt.target?.value)} disabled={this.state.saving} />
                    </div>
                </Flex> : null}
                {!this.state.loadingError ? <div className={styles.MicrosoftTenantProductsDialogTableContainer}>{table}</div> : null}
                {!this.state.saving && this.state.savingError ? <div style={{ marginTop: '12px' }}><AlertError {...this.state.savingError} /></div> : null}
            </div>
        );

        return (
            <Dialog
                open={true}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose()}
                onConfirm={this.onSubmit}
                cancelButton={{ content: this.props.t('admin.microsoft.tenants.dialog.common.cancel'), disabled: this.state.loading || this.state.saving }}
                confirmButton={{ content: this.props.t('admin.microsoft.tenants.dialog.products.confirm'), primary: true, disabled: (this.state.loading || this.state.loadingError !== null || this.state.saving), loading: this.state.saving }}
                content={form}
                header={this.props.t('admin.microsoft.tenants.dialog.products.title', { name: this.props.tenant.name })}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.microsoft.tenants.dialog.common.close'), onClick: () => this.props.onClose && this.props.onClose(), disabled: this.state.loading || this.state.saving }} />
        );

    }

}

export default withTranslation()(MicrosoftTenantProductsDialog);
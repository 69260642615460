import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Alert, CloseIcon, Dialog } from '@fluentui/react-northstar';
import styles from './UserManagement.module.scss';
import Axios from 'axios';
import { GetUsersDTO } from '../../../models/Administration/UserController/getUsersDTO';
import { Persona, PersonaSize } from '@fluentui/react';

interface IUserAccountEnabledDialogProps extends WithTranslation {
    user: GetUsersDTO;
    onClose?: any;
}

interface IUserAccountEnabledDialogState {
    error: string | null;
    saving: boolean;
}

class UserAccountEnabledDialog extends React.Component<IUserAccountEnabledDialogProps, IUserAccountEnabledDialogState> {

    public state: IUserAccountEnabledDialogState = {
        error: null,
        saving: false,
    }

    public onSubmit = async () => {
        try {
            this.setState({ saving: true, error: null });
            await Axios.post(window.env.REACT_APP_API_BASE + '/api/admin/users/' + this.props.user.id + '/' + (this.props.user.accountEnabled ? 'disable' : 'enable'));
            this.setState({ saving: false });
            this.props.onClose(true);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ saving: false, error: error.response.data.errorCode });
            } else {
                this.setState({ saving: false, error: `error.admin.users.${this.props.user.accountEnabled ? 'disableuser' : 'enableuser'}` });
            }
        }
    }

    render() {

        const form = (
            <div className={styles.UserAccountEnabledDialog}>
                {!this.state.saving && this.state.error ? <Alert danger={true} content={this.props.t(this.state.error)} /> : null}
                <Persona text={this.props.user.displayName} secondaryText={this.props.user.mail} showSecondaryText={true} size={PersonaSize.size32} />
            </div>
        );

        return (
            <Dialog
                open={true}
                styles={{ width: '420px' }}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose()}
                onConfirm={this.onSubmit}
                cancelButton={{ content: this.props.t('admin.users.dialog.status.cancel'), disabled: this.state.saving }}
                confirmButton={{ content: this.props.t(`admin.users.dialog.status.${this.props.user.accountEnabled ? 'disable' : 'enable'}.confirm`), primary: true, disabled: this.state.saving, loading: this.state.saving }}
                content={form}
                header={this.props.t(`admin.users.dialog.status.${this.props.user.accountEnabled ? 'disable' : 'enable'}.title`)}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.users.dialog.status.close'), onClick: () => this.props.onClose && this.props.onClose(), disabled: this.state.saving }} />
        );

    }

}

export default withTranslation()(UserAccountEnabledDialog);
import { Alert, Button, Divider, Flex, Input, Loader, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { GetMicrosoftSubscriptionsDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsDTO';
import { LocalizationService } from '../../../services/LocalizationService';
import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import { GetMicrosoftSubscriptionsFilterDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsFilterDTO';
import MicrosoftProductRequest from '../products/MicrosoftProductRequest';
import styles from './MicrosoftSubscriptions.module.scss';
import placeholder from '../../../images/product-placeholder.png';
import { GetMicrosoftSubscriptionsSubscriptionStatusDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsSubscriptionStatusDTO';
import { GetMicrosoftSubscriptionsSubscriptionDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsSubscriptionDTO';
import MicrosoftProductTypeBadge from '../../../components/microsoft/ProductTypeBadge';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import { debounce } from 'lodash';
import moment from 'moment';
import MicrosoftSubscriptionScheduledChangesDialog from './MicrosoftSubscriptionScheduledChangesDialog';

interface IMicrosoftSubscriptionsFormProps extends WithTranslation, RouteComponentProps {
    subscriptions: GetMicrosoftSubscriptionsDTO[];
    formData: any;
    onChange: any;
    getValidationMessage: any;
    refreshSubscriptions: any;
}

interface IMicrosoftSubscriptionsFormState {
    additionalSubscriptionsLoading: boolean;
    additionalSubscriptionsLoaded: boolean;
    additionalSubscriptions: GetMicrosoftSubscriptionsDTO[];
    openRequestDialog: boolean;
    openScheduledChangesDialog: boolean;
    selectedProduct: string | null;
    selectedSubscription: GetMicrosoftSubscriptionsDTO | null;
    validating: string[];
    errors: any;
}

class MicrosoftSubscriptionsForm extends React.Component<IMicrosoftSubscriptionsFormProps, IMicrosoftSubscriptionsFormState> {

    public state: IMicrosoftSubscriptionsFormState = {
        additionalSubscriptionsLoading: false,
        additionalSubscriptionsLoaded: false,
        additionalSubscriptions: [],
        openRequestDialog: false,
        openScheduledChangesDialog: false,
        selectedProduct: null,
        selectedSubscription: null,
        validating: [],
        errors: {},
    }

    public componentDidMount() {
        this.resetState();
    }

    public componentDidUpdate(prevProps: IMicrosoftSubscriptionsFormProps) {
        if ((prevProps.match.params as any).tenantId !== (this.props.match.params as any).tenantId) {
            this.resetState();
        }
    }

    public resetState = () => {
        this.setState({
            additionalSubscriptionsLoading: false,
            additionalSubscriptionsLoaded: false,
            additionalSubscriptions: [],
            openRequestDialog: false,
            openScheduledChangesDialog: false,
            selectedProduct: null,
            selectedSubscription: null,
        });
    }

    public getAdditionalTenantSubscriptions = async () => {
        const tenantId = (this.props.match.params as any).tenantId;
        this.setState({ additionalSubscriptionsLoading: true, additionalSubscriptionsLoaded: false, additionalSubscriptions: [] });
        try {
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/' + tenantId + '/subscriptions?filter=' + GetMicrosoftSubscriptionsFilterDTO.notPurchasable);
            this.setState({ additionalSubscriptionsLoading: false, additionalSubscriptionsLoaded: true, additionalSubscriptions: response.data });
        } catch (error: any) {
            console.error(error);
            this.setState({ additionalSubscriptionsLoading: false, additionalSubscriptionsLoaded: true });
        }
    }

    public subscriptionsChanged = () => {
        let subscriptionsChanged = false;
        this.props.subscriptions.forEach((subscription: GetMicrosoftSubscriptionsDTO) => {
            if (subscription.subscription.quantity !== this.props.formData[subscription.subscription.id]) subscriptionsChanged = true;
        });
        return subscriptionsChanged;
    }

    public onChange = (subscription: GetMicrosoftSubscriptionsSubscriptionDTO, value: string) => {
        const formData: any = { ...this.props.formData };
        formData[subscription.id] = parseInt(value) >= 1 ? parseInt(value) : 1;
        this.onChangeValidate(subscription, formData[subscription.id]);
        this.props.onChange(formData);
    }

    public onChangeValidate = debounce(async (subscription: GetMicrosoftSubscriptionsSubscriptionDTO, newQuantity: number) => {
        this.setValidation(subscription.id, true);
        const error: string = await this.props.getValidationMessage(subscription.id, newQuantity);
        this.setValidation(subscription.id, false, error);
    }, 500);

    public onDecreaseQuantity = async (subscription: GetMicrosoftSubscriptionsSubscriptionDTO) => {

        const formData: any = { ...this.props.formData };
        formData[subscription.id] = formData[subscription.id] > 1 ? formData[subscription.id] - 1 : 1;

        this.setValidation(subscription.id, true);
        const error: string = await this.props.getValidationMessage(subscription.id, formData[subscription.id]);
        this.setValidation(subscription.id, false, error);
        setTimeout(() => this.setValidation(subscription.id, false), 5000);
        if (error?.length === 0) this.props.onChange(formData);

    }

    public onIncreaseQuantity = async (subscription: GetMicrosoftSubscriptionsSubscriptionDTO) => {

        const formData: any = { ...this.props.formData };
        formData[subscription.id] = formData[subscription.id] + 1;
        this.props.onChange(formData);

        this.setValidation(subscription.id, true);
        const error: string = await this.props.getValidationMessage(subscription.id, formData[subscription.id]);
        this.setValidation(subscription.id, false, error);

    }

    public setValidation = (subscriptionId: string, active: boolean, error?: string) => {
        this.setState((prevState) => {
            const validating = [...prevState.validating];
            const errors = { ...prevState.errors };
            if (active) {
                if (validating.indexOf(subscriptionId) === -1) validating.push(subscriptionId);
            } else {
                if (validating.indexOf(subscriptionId) !== -1) validating.splice(validating.indexOf(subscriptionId));
            }
            if (error && error.length > 0) {
                errors[subscriptionId] = error;
            } else {
                delete errors[subscriptionId];
            }
            return { validating: validating, errors: errors };
        });
    }

    public openRequestDialog = (subscription: GetMicrosoftSubscriptionsDTO) => {
        this.setState({ openRequestDialog: true, selectedProduct: subscription.product.id });
    }

    public closeRequestDialog = () => {
        this.setState({ openRequestDialog: false, selectedProduct: null });
    }

    public openScheduledChangesDialog = (subscription: GetMicrosoftSubscriptionsDTO) => {
        this.setState({ openScheduledChangesDialog: true, selectedSubscription: subscription });
    }

    public closeScheduledChangesDialog = (refresh: boolean = false) => {
        this.setState({ openScheduledChangesDialog: false, selectedSubscription: null });
        if (refresh) this.props.refreshSubscriptions();
    }

    public getSubscriptionStatus = (subscription: GetMicrosoftSubscriptionsSubscriptionDTO): string => {
        if (subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.none) {
            return this.props.t('microsoft.subscriptions.form.status.none');
        } else if (subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.active) {
            return this.props.t('microsoft.subscriptions.form.status.active');
        } else if (subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.suspended) {
            return this.props.t('microsoft.subscriptions.form.status.suspended');
        } else if (subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.deleted) {
            return this.props.t('microsoft.subscriptions.form.status.deleted');
        }
        return this.props.t('microsoft.subscriptions.form.status.unknown');
    }

    public getButtonDecreaseDisabled = (subscription: GetMicrosoftSubscriptionsSubscriptionDTO): boolean => {
        if (subscription.status !== GetMicrosoftSubscriptionsSubscriptionStatusDTO.active) return true;
        if (this.props.formData[subscription.id] <= 1) return true;
        if (subscription.refundableQuantity && this.props.formData[subscription.id] <= subscription.quantity - subscription.refundableQuantity.totalQuantity) return true;
        return false;
    }

    render() {
        return (
            <div className={styles.MicrosoftSubscriptionsForm}>
                {this.props.subscriptions.map((subscription: GetMicrosoftSubscriptionsDTO) => {
                    return (
                        <Segment key={subscription.subscription.id} className={`item ${subscription.product.productType === 'OnlineServicesNCE' ? ' item-nce' : ''}`}>
                            <Flex hAlign="start" space="between">
                                <Flex gap="gap.small" className="product">
                                    <img src={subscription.product.tileImage ? subscription.product.tileImage : placeholder} alt="" />
                                    <div>
                                        <div>
                                            <Text weight="semibold">
                                                {LocalizationService.getLocalizedString(subscription.product.title, this.props.i18n.language)}
                                                <MicrosoftProductTypeBadge productType={subscription.product.productType} />
                                            </Text>
                                        </div>
                                        <div><Text size="small">{LocalizationService.getLocalizedString(subscription.product.summary, this.props.i18n.language)}</Text></div>
                                    </div>
                                </Flex>
                                {subscription.product.productType === 'OnlineServicesNCE' && <React.Fragment>
                                    <div className="renewal">
                                        {subscription.subscription.autoRenewEnabled
                                            ? <Text><Trans i18nKey={'microsoft.subscriptions.form.renewal.enabled'} values={{ date: moment(subscription.subscription.commitmentEndDate).add(1, 'days').format('L') }} /></Text>
                                            : <Text color="red"><Trans i18nKey={'microsoft.subscriptions.form.renewal.disabled'} values={{ date: moment(subscription.subscription.commitmentEndDate).add(1, 'days').format('L') }} /></Text>}
                                        <br /><Trans i18nKey={'microsoft.subscriptions.form.renewal.termduration'} />: <Trans i18nKey={subscription.subscription.termDuration?.length > 0 ? `microsoft.subscriptions.form.renewal.terms.${subscription.subscription.termDuration}` : `microsoft.subscriptions.form.renewal.terms.unknown`} />
                                    </div>
                                    <div className="scheduled-changes">
                                        {moment().isBefore(subscription.subscription.cancellationAllowedUntilDate) ? <React.Fragment>
                                            <Flex hAlign="start">
                                                <div style={{ marginRight: '4px' }}>
                                                    <InformationOutlineIcon style={{ width: '20px' }} />
                                                </div>
                                                <div style={{ width: 'calc(100% - 24px)' }}>
                                                    <Text weight="semibold"><Trans i18nKey={'microsoft.subscriptions.form.changes.unrestricted'} values={{ datetime: moment(subscription.subscription.cancellationAllowedUntilDate).format('L LT') }} /></Text>
                                                </div>
                                            </Flex>
                                        </React.Fragment> : <React.Fragment>
                                            <Flex hAlign="start">
                                                <div style={{ marginRight: '4px' }}>
                                                    <ClockOutlineIcon style={{ width: '20px' }} />
                                                </div>
                                                <div style={{ width: 'calc(100% - 24px)' }}>
                                                    {subscription.subscription.scheduledNextTermInstructions ? <Text>
                                                        <Trans i18nKey={'microsoft.subscriptions.form.changes.some'} values={{ date: moment(subscription.subscription.commitmentEndDate).add(1, 'days').format('L') }} />:<br />
                                                        {subscription.subscription.scheduledNextTermInstructions.quantity < subscription.subscription.quantity
                                                            ? <Trans i18nKey={`microsoft.subscriptions.form.changes.${subscription.subscription.unitType.toLowerCase().replace(/[^a-z]/gi, '')}.reduction`} count={subscription.subscription.scheduledNextTermInstructions.quantity} />
                                                            : <Trans i18nKey={`microsoft.subscriptions.form.changes.${subscription.subscription.unitType.toLowerCase().replace(/[^a-z]/gi, '')}.increase`} count={subscription.subscription.scheduledNextTermInstructions.quantity} />}
                                                    </Text> : <React.Fragment>
                                                        <Trans i18nKey={'microsoft.subscriptions.form.changes.none'} />
                                                    </React.Fragment>}<br />
                                                    {subscription.subscription.autoRenewEnabled ? <span className="edit" onClick={() => this.openScheduledChangesDialog(subscription)}>
                                                        <Trans i18nKey={'microsoft.subscriptions.form.changes.edit'} />
                                                    </span> : <span className="edit-disabled" title={this.props.t('microsoft.subscriptions.form.changes.editdisabled')} >
                                                        <Trans i18nKey={'microsoft.subscriptions.form.changes.edit'} />
                                                        <InformationOutlineIcon style={{ width: '16px', verticalAlign: 'top', marginLeft: '2px', marginTop: '-2px' }} />
                                                    </span>}
                                                </div>
                                            </Flex>
                                        </React.Fragment>}
                                    </div>
                                </React.Fragment>}
                                <div className="form">
                                    {this.state.validating?.indexOf(subscription.subscription.id) !== -1 && <div className="validating"><Loader size="small" /></div>}
                                    <Flex gap="gap.small">
                                        <Button icon={<MinusIcon />} iconOnly={true} onClick={() => this.onDecreaseQuantity(subscription.subscription)} disabled={this.getButtonDecreaseDisabled(subscription.subscription)} />
                                        <div className="input-field">
                                            <Input fluid={true} value={this.props.formData[subscription.subscription.id]}
                                                disabled={subscription.subscription.status !== GetMicrosoftSubscriptionsSubscriptionStatusDTO.active}
                                                onChange={(evt: any) => { this.onChange(subscription.subscription, evt.target?.value) }} />
                                        </div>
                                        <Button icon={<PlusIcon />} iconOnly={true} onClick={() => this.onIncreaseQuantity(subscription.subscription)}
                                            disabled={subscription.subscription.status !== GetMicrosoftSubscriptionsSubscriptionStatusDTO.active} />
                                    </Flex>
                                    <div className="changes">
                                        {subscription.subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.active ? <Text size="small">
                                            {this.props.formData[subscription.subscription.id] === subscription.subscription.quantity ? this.props.t('microsoft.subscriptions.form.nochanges') : null}
                                            {this.props.formData[subscription.subscription.id] > subscription.subscription.quantity ? <Trans i18nKey={`microsoft.subscriptions.form.${subscription.subscription.unitType.toLowerCase().replace(/[^a-z]/gi, '')}.increased`} count={this.props.formData[subscription.subscription.id] - subscription.subscription.quantity} /> : null}
                                            {this.props.formData[subscription.subscription.id] < subscription.subscription.quantity ? <Trans i18nKey={`microsoft.subscriptions.form.${subscription.subscription.unitType.toLowerCase().replace(/[^a-z]/gi, '')}.decreased`} count={subscription.subscription.quantity - this.props.formData[subscription.subscription.id]} /> : null}
                                        </Text> : <Text error size="small">{this.getSubscriptionStatus(subscription.subscription)}</Text>}
                                    </div>
                                    {this.state.errors && this.state.errors[subscription.subscription.id] && <div className="errors">
                                        <Alert danger><Text size="small">{this.state.errors[subscription.subscription.id]}</Text></Alert>
                                    </div>}
                                </div>
                            </Flex>
                        </Segment>
                    );
                })}

                {!this.state.additionalSubscriptionsLoaded && this.state.additionalSubscriptionsLoading ? <Loader /> : null}
                {!this.state.additionalSubscriptionsLoaded && !this.state.additionalSubscriptionsLoading ? <div style={{ textAlign: 'center' }}><Button content={this.props.t('microsoft.subscriptions.form.showall')} onClick={() => this.getAdditionalTenantSubscriptions()} /></div> : null}
                {this.state.additionalSubscriptionsLoaded && this.state.additionalSubscriptions.length > 0 ? <React.Fragment>
                    <Divider content={<Text size="large">{this.props.t('microsoft.subscriptions.form.additional')}</Text>} />
                    {this.state.additionalSubscriptions.map((subscription: GetMicrosoftSubscriptionsDTO) => {
                        return (
                            <Segment key={subscription.subscription.id} className="item">

                                <Flex space="between">
                                    <Flex gap="gap.small" className="product">
                                        <img src={subscription.product.tileImage ? subscription.product.tileImage : placeholder} alt="" />
                                        <div>
                                            <div><Text weight="bold">{LocalizationService.getLocalizedString(subscription.product.title, this.props.i18n.language)}</Text></div>
                                            <div>{LocalizationService.getLocalizedString(subscription.product.summary, this.props.i18n.language)}</div>
                                        </div>
                                    </Flex>
                                    <div className="contact">
                                        <Button content={this.props.t('microsoft.subscriptions.form.contact')} onClick={() => this.openRequestDialog(subscription)} />
                                        <div>
                                            <Text size="small">{this.props.t(`microsoft.subscriptions.form.${subscription.subscription.unitType.toLowerCase().replace(/[^a-z]/gi, '')}.label`, { count: subscription.subscription.quantity })}</Text>
                                            {subscription.subscription.status !== GetMicrosoftSubscriptionsSubscriptionStatusDTO.active ? <React.Fragment><br /><Text error size="small">{this.getSubscriptionStatus(subscription.subscription)}</Text></React.Fragment> : null}
                                        </div>
                                    </div>
                                </Flex>
                            </Segment>
                        );
                    })}
                </React.Fragment> : null}
                {this.state.openRequestDialog && this.state.selectedProduct ? <MicrosoftProductRequest onClose={this.closeRequestDialog} tenantId={(this.props.match.params as any).tenantId} productId={this.state.selectedProduct} /> : null}
                {this.state.openScheduledChangesDialog && this.state.selectedSubscription ? <MicrosoftSubscriptionScheduledChangesDialog onClose={this.closeScheduledChangesDialog} tenantId={(this.props.match.params as any).tenantId} subscription={this.state.selectedSubscription} /> : null}
                <div className={styles.MicrosoftSubscriptionsFooter}>
                    <Button primary content={this.props.t('microsoft.subscriptions.form.checkout')} onClick={() => this.props.history.push(`${this.props.location.pathname}/checkout`)} disabled={!this.subscriptionsChanged() || this.state.validating.length > 0 || Object.keys(this.state.errors).length > 0} />
                </div>
            </div>
        );
    }

}

export default withTranslation()(MicrosoftSubscriptionsForm);
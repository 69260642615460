import { Button, Flex, Input, SearchIcon } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GetContactsDTO } from '../../../models/Administration/ContactController/getContactsDTO';
import ReloadIcon from 'mdi-react/ReloadIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import AccountEditIcon from 'mdi-react/AccountEditIcon';
import AccountRemoveIcon from 'mdi-react/AccountRemoveIcon';
import ContactCreateDialog from './ContactCreateDialog';
import ContactEditDialog from './ContactEditDialog';
import ContactDeleteDialog from './ContactDeleteDialog';
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';

interface IContactManagementProps extends WithTranslation {

}

interface IContactManagementState {
    error: IAlertErrorProps | null;
    loading: boolean;
    contacts: GetContactsDTO[],
    openCreateContactDialog: boolean;
    openEditContactDialog: boolean;
    openDeleteContactDialog: boolean;
    selectedContact: GetContactsDTO | null;
    searchInput: string;
    items: GetContactsDTO[],
}

class ContactManagement extends React.Component<IContactManagementProps, IContactManagementState> {

    public state: IContactManagementState = {
        error: null,
        loading: false,
        contacts: [],
        openCreateContactDialog: false,
        openEditContactDialog: false,
        openDeleteContactDialog: false,
        selectedContact: null,
        searchInput: '',
        items: [],
    }

    public async componentDidMount() {
        this.getContacts();
    }

    public getContacts = async () => {
        try {
            this.setState({ loading: true, contacts: [], items: [], error: null });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/contacts');
            this.setState({ loading: false, contacts: response.data, items: response.data });
            this.onSearch(this.state.searchInput);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getContacts() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.admin.contacts.loadlist', retry: () => this.getContacts() } });
            }
        }
    }

    public openCreateContactDialog = () => {
        this.setState({ openCreateContactDialog: true });
    }

    public closeCreateContactDialog = (refresh: boolean) => {
        this.setState({ openCreateContactDialog: false });
        if (refresh) this.getContacts();
    }

    public openEditContactDialog = (contact: GetContactsDTO) => {
        this.setState({ openEditContactDialog: true, selectedContact: contact });
    }

    public closeEditContactDialog = (refresh: boolean) => {
        this.setState({ openEditContactDialog: false, selectedContact: null });
        if (refresh) this.getContacts();
    }

    public openDeleteContactDialog = (contact: GetContactsDTO) => {
        this.setState({ openDeleteContactDialog: true, selectedContact: contact });
    }

    public closeDeleteContactDialog = (refresh: boolean) => {
        this.setState({ openDeleteContactDialog: false, selectedContact: null });
        if (refresh) this.getContacts();
    }

    public onSearch = (searchInput: string) => {
        const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
        const items = this.state.contacts.filter((contact: GetContactsDTO) => contact.firstname?.match(searchRegex) || contact.lastname?.match(searchRegex) || contact.jobTitle?.match(searchRegex) || contact.mail?.match(searchRegex));
        this.setState({ searchInput: searchInput, items: items });
    }

    render() {

        const columns: IColumn[] = [{
            key: 'name',
            name: this.props.t('admin.contacts.list.headers.name'),
            fieldName: 'name',
            minWidth: 240,
            isResizable: true,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (contact: GetContactsDTO) => {
                return contact.lastname + ' ' + contact.firstname;
            }
        }, {
            key: 'jobtitle',
            name: this.props.t('admin.contacts.list.headers.jobtitle'),
            fieldName: 'jobTitle',
            minWidth: 300,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true
        }, {
            key: 'mail',
            name: this.props.t('admin.contacts.list.headers.mail'),
            fieldName: 'mail',
            minWidth: 300,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true
        }, {
            key: 'actions',
            name: this.props.t('admin.contacts.list.headers.actions'),
            minWidth: 320,
            maxWidth: 320,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (contact: GetContactsDTO) => {
                return <Flex gap="gap.small">
                    <Button content={this.props.t('admin.contacts.list.actions.edit')} icon={<AccountEditIcon />} iconPosition="before" onClick={() => this.openEditContactDialog(contact)} />
                    <Button content={this.props.t('admin.contacts.list.actions.delete')} icon={<AccountRemoveIcon />} iconPosition="before" onClick={() => this.openDeleteContactDialog(contact)} />
                </Flex>;
            }
        }];

        const table = <ShimmeredDetailsList
            columns={columns}
            items={this.state.items}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            enableShimmer={this.state.loading} />;

        return (
            <div>
                <h1>{this.props.t('admin.contacts.list.title')}</h1>
                <Flex space="between">
                    <Flex gap="gap.small">
                        <Button content={this.props.t('admin.contacts.list.create')} icon={<AccountPlusIcon />} iconPosition="before" onClick={this.openCreateContactDialog} />
                        <Button content={this.props.t('admin.contacts.list.refresh')} icon={<ReloadIcon />} iconPosition="before" onClick={this.getContacts} />
                    </Flex>
                    <div style={{ width: '280px' }}>
                        {!this.state.loading && !this.state.error ? <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off" icon={<SearchIcon />} placeholder={this.props.t('admin.contacts.list.search')}
                            value={this.state.searchInput} onChange={(evt: any) => this.onSearch(evt.target?.value)} /> : null}
                    </div>
                </Flex>
                {!this.state.error ? table : null}
                {!this.state.loading && this.state.error ? <div style={{ marginTop: '12px' }}><AlertError {...this.state.error} /></div> : null}
                {this.state.openCreateContactDialog ? <ContactCreateDialog onClose={this.closeCreateContactDialog} /> : null}
                {this.state.openEditContactDialog && this.state.selectedContact ? <ContactEditDialog onClose={this.closeEditContactDialog} contact={this.state.selectedContact} /> : null}
                {this.state.openDeleteContactDialog && this.state.selectedContact ? <ContactDeleteDialog onClose={this.closeDeleteContactDialog} contact={this.state.selectedContact} /> : null}
            </div>
        );

    }

}

export default withTranslation()(ContactManagement);
import React from 'react';
import { Button } from '@fluentui/react-northstar';
import { AuthenticationState, IAccountInfo } from 'react-aad-msal';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthService } from '../../services/AuthService';
import styles from './Header.module.scss';
import logo from '../../images/cancom.svg';
import { Persona, PersonaSize } from '@fluentui/react';

interface IHeaderProps extends WithTranslation {
    authenticationState: AuthenticationState | undefined;
    accountInfo: IAccountInfo | null;
}

class Header extends React.Component<IHeaderProps> {

    public login = async () => {
        try {
            await AuthService.login();
        } catch (error: any) {
            console.error(error);
        }
    }

    public logout = async () => {
        try {
            await AuthService.logout();
        } catch (error: any) {
            console.error(error);
        }
    }

    render() {
        return (
            <div className={styles.Header}>
                {window.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT' ? <div className={`${styles.System} ${styles.SystemDevelopment}`}>DEVELOPMENT</div> : null}
                {window.env.REACT_APP_ENVIRONMENT === 'STAGING' ? <div className={`${styles.System} ${styles.SystemStaging}`}>STAGING</div> : null}
                <div className={styles.Logo}>
                    <Link to="/"><img src={logo} alt="CANCOM Tune" title="CANCOM Tune" /></Link>
                </div>
                {this.props.authenticationState === AuthenticationState.Authenticated ? (
                    <React.Fragment>
                        <div className={styles.Profile}>
                            <Persona text={this.props.accountInfo?.account?.name} secondaryText={this.props.accountInfo?.account?.userName} showSecondaryText={true} size={PersonaSize.size32} />
                            <div className="logout">
                                <Button primary content={this.props.t('header.logout')} onClick={this.logout} />
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                        <div className={styles.Login}>
                            <Button primary content={this.props.t('header.login')} onClick={this.login} disabled={this.props.authenticationState === AuthenticationState.InProgress} />
                        </div>
                    )}
            </div>
        );
    }

}

export default withTranslation()(Header)

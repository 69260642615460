import { Alert, CloseIcon, Dialog, Loader } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IMicrosoftTenantsRefreshProps extends WithTranslation {
    onClose?: any;
}

interface IMicrosoftTenantsRefreshState {
    error: string | null;
    loading: boolean;
}

class MicrosoftTenantsRefresh extends React.Component<IMicrosoftTenantsRefreshProps, IMicrosoftTenantsRefreshState> {

    public state: IMicrosoftTenantsRefreshState = {
        error: null,
        loading: true,
    }

    public async componentDidMount() {
        this.refresh();
    }

    public refresh = async () => {
        try {
            this.setState({ loading: true, error: null });
            await Axios.post(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/refresh');
            this.setState({ loading: false });
            this.props.onClose();
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: error.response.data.errorCode });
            } else {
                this.setState({ loading: false, error: 'error.admin.microsoft.tenants.synchronize' });
            }
        }
    }

    render() {

        const content = (
            <React.Fragment>
                {this.state.loading ? <div><Loader /></div> : null}
                {!this.state.loading && this.state.error ? <div style={{ marginTop: '12px' }}><Alert danger={true} content={this.props.t(this.state.error)} /></div> : null}
            </React.Fragment>
        );

        return (
            <Dialog
                open={true}
                styles={{ width: '420px' }}
                closeOnOutsideClick={false}
                onConfirm={() => this.refresh()}
                confirmButton={!this.state.loading && this.state.error ? { content: this.props.t('admin.microsoft.tenants.dialog.refresh.confirm'), primary: true, disabled: this.state.loading } : null}
                content={content}
                header={this.props.t('admin.microsoft.tenants.dialog.refresh.title')}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.microsoft.tenants.dialog.common.close'), onClick: () => this.props.onClose && this.props.onClose(), disabled: this.state.loading }} />
        );

    }

}

export default withTranslation()(MicrosoftTenantsRefresh);
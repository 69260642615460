import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Flex, Input, SearchIcon } from '@fluentui/react-northstar';
import ReloadIcon from 'mdi-react/ReloadIcon';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import AccountCancelIcon from 'mdi-react/AccountCancelIcon';
import AccountCheckIcon from 'mdi-react/AccountCheckIcon';
import UserInvite from './UserInviteDialog';
import UserGroupMembershipDialog from './UserGroupMembershipDialog';
import UserAccountEnabledDialog from './UserAccountEnabledDialog';
import { GetUsersDTO } from '../../../models/Administration/UserController/getUsersDTO';
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import { GetUsersGroupsDTO } from '../../../models/Administration/UserController/getUsersGroupsDTO';

interface IUserManagementProps extends WithTranslation {

}

interface IUserManagementState {
    error: IAlertErrorProps | null;
    loading: boolean;
    users: GetUsersDTO[];
    openUserInviteDialog: boolean;
    openGroupMembershipManagement: boolean;
    openUserAccountEnabledDialog: boolean;
    selectedUser: GetUsersDTO | null;
    searchInput: string;
    items: GetUsersDTO[];
}

class UserManagement extends React.Component<IUserManagementProps, IUserManagementState> {

    public state: IUserManagementState = {
        error: null,
        loading: false,
        users: [],
        openUserInviteDialog: false,
        openGroupMembershipManagement: false,
        openUserAccountEnabledDialog: false,
        selectedUser: null,
        searchInput: '',
        items: [],
    }

    public async componentDidMount() {
        await this.getUsers();
    }

    public getUsers = async () => {
        try {
            this.setState({ loading: true, users: [], items: [], error: null });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/users');
            this.setState({ loading: false, users: response.data, items: response.data });
            this.onSearch(this.state.searchInput);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getUsers() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.admin.users.loadlist', retry: () => this.getUsers() } });
            }
        }
    }

    public onSearch = (searchInput: string) => {
        const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
        const items = this.state.users.filter((user: GetUsersDTO) => user.displayName?.match(searchRegex) || user.mail?.match(searchRegex));
        this.setState({ searchInput: searchInput, items: items });
    }

    public openInviteUserDialog = () => {
        this.setState({ openUserInviteDialog: true });
    }

    public closeInviteUserDialog = (refresh?: boolean) => {
        this.setState({ openUserInviteDialog: false });
        if (refresh) this.getUsers();
    }

    public openGroupMembershipManagement = (user: GetUsersDTO) => {
        this.setState({
            openGroupMembershipManagement: true,
            selectedUser: user,
        });
    }

    public closeGroupMembershipManagement = (refresh?: boolean) => {
        this.setState({
            openGroupMembershipManagement: false,
            selectedUser: null,
        });
        if (refresh) this.getUsers();
    }

    public openUserAccountEnabledDialog = (user: GetUsersDTO) => {
        this.setState({
            openUserAccountEnabledDialog: true,
            selectedUser: user,
        });
    }

    public closeUserAccountEnabledDialog = (refresh?: boolean) => {
        this.setState({
            openUserAccountEnabledDialog: false,
            selectedUser: null,
        });
        if (refresh) this.getUsers();
    }

    render() {

        const columns: IColumn[] = [{
            key: 'name',
            name: this.props.t('admin.users.list.headers.name'),
            fieldName: 'displayName',
            minWidth: 360,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true
        }, {
            key: 'mail',
            name: this.props.t('admin.users.list.headers.mail'),
            fieldName: 'mail',
            minWidth: 360,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true
        }, {
            key: 'groups',
            name: this.props.t('admin.users.list.headers.groups'),
            minWidth: 200,
            isMultiline: true,
            isResizable: true,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (user: GetUsersDTO) => {
                return user.groups.map((group: GetUsersGroupsDTO) => {
                    switch (group.id) {
                        case window.env.REACT_APP_AAD_GROUP_ADMINISTRATORS:
                            return <div key={group.id}>{this.props.t('admin.users.groups.administrators')}</div>;
                        case window.env.REACT_APP_AAD_GROUP_EMPLOYEES:
                            return <div key={group.id}>{this.props.t('admin.users.groups.employees')}</div>;
                        case window.env.REACT_APP_AAD_GROUP_CUSTOMERS:
                            return <div key={group.id}>{this.props.t('admin.users.groups.customers')}</div>;
                    }
                    return null;
                });
            }
        }, {
            key: 'actions',
            name: this.props.t('admin.users.list.headers.actions'),
            minWidth: 400,
            maxWidth: 400,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (user: GetUsersDTO) => {
                return <Flex gap="gap.small">
                    <Button content={this.props.t('admin.users.list.actions.groups')} icon={<AccountMultipleIcon />} iconPosition="before" onClick={() => { this.openGroupMembershipManagement(user) }} />
                    <Button content={user.accountEnabled ? this.props.t('admin.users.list.actions.disable') : this.props.t('admin.users.list.actions.enable')}
                        icon={user.accountEnabled ? <AccountCancelIcon /> : <AccountCheckIcon />} iconPosition="before" onClick={() => { this.openUserAccountEnabledDialog(user) }} />
                </Flex>;
            }
        }];

        const table = <ShimmeredDetailsList
            columns={columns}
            items={this.state.items}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            enableShimmer={this.state.loading} />;

        return (
            <div>
                <h1>{this.props.t('admin.users.list.title')}</h1>
                <Flex space="between">
                    <Flex gap="gap.small">
                        <Button content={this.props.t('admin.users.list.invite')} icon={<AccountPlusIcon />} iconPosition="before" onClick={this.openInviteUserDialog} />
                        <Button content={this.props.t('admin.users.list.refresh')} icon={<ReloadIcon />} iconPosition="before" onClick={this.getUsers} />
                    </Flex>
                    <div style={{ width: '280px' }}>
                        {!this.state.loading && !this.state.error ? <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off" icon={<SearchIcon />} placeholder={this.props.t('admin.users.list.search')}
                            value={this.state.searchInput} onChange={(evt: any) => this.onSearch(evt.target?.value)} /> : null}
                    </div>
                </Flex>
                {!this.state.error ? table : null}
                {!this.state.loading && this.state.error ? <div style={{ marginTop: '12px' }}><AlertError {...this.state.error} /></div> : null}
                {this.state.openUserInviteDialog ? <UserInvite onClose={this.closeInviteUserDialog} /> : null}
                {this.state.openGroupMembershipManagement && this.state.selectedUser ? <UserGroupMembershipDialog user={this.state.selectedUser} onClose={this.closeGroupMembershipManagement} /> : null}
                {this.state.openUserAccountEnabledDialog && this.state.selectedUser ? <UserAccountEnabledDialog user={this.state.selectedUser} onClose={this.closeUserAccountEnabledDialog} /> : null}
            </div>
        );

    }

}

export default withTranslation()(UserManagement);
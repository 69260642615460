import { AuthenticationParameters, Configuration } from 'msal';
import { MsalAuthProvider, LoginType, IMsalAuthProviderConfig, AccessTokenResponse, AuthenticationState } from 'react-aad-msal';

const msalConfiguration: Configuration = {
    auth: {
        authority: window.env.REACT_APP_MSAL_AUTHORITY || '',
        clientId: window.env.REACT_APP_MSAL_CLIENT_ID || '',
        redirectUri: `${window.location.protocol}//${window.location.host}`,
        postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}/`
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

const msalAuthenticationParameters: AuthenticationParameters = {
    scopes: [
        'user.read',
    ]
}

const msalAccessTokenParameters: AuthenticationParameters = {
    scopes: [
        window.env.REACT_APP_MSAL_API_SCOPE || ''
    ]
}

const msalOptions: IMsalAuthProviderConfig = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.protocol}//${window.location.host}/auth.html`
}

export class AuthService {

    private static provider: MsalAuthProvider;

    public static getAuthProvider(): MsalAuthProvider {
        if (!this.provider) {
            this.provider = new MsalAuthProvider(msalConfiguration, msalAuthenticationParameters, msalOptions);
        }
        return this.provider;
    }

    public static login(): Promise<void> {
        if (this.getAuthenticationState() !== AuthenticationState.Unauthenticated) return Promise.resolve();
        return AuthService.getAuthProvider().login();
    }

    public static logout(): void {
        if (this.getAuthenticationState() !== AuthenticationState.Authenticated) return;
        return AuthService.getAuthProvider().logout();
    }

    public static getAuthenticationState(): AuthenticationState {
        return AuthService.getAuthProvider().authenticationState;
    }

    public static async getAccessToken(): Promise<string> {
        try {
            const token: AccessTokenResponse = await AuthService.getAuthProvider().getAccessToken(msalAccessTokenParameters);
            return token.accessToken;
        } catch (error: any) {
            console.error(error);
        }
        return '';
    }

    public static getGroups(): string[] {
        if (AuthService.getAuthProvider().authenticationState === AuthenticationState.Authenticated) {
            return (AuthService.getAuthProvider().getAccount().idTokenClaims.groups as unknown) as string[];
        }
        return [];
    }

}
import { Loader, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { withTranslation, WithTranslation } from 'react-i18next';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import styles from './AdministrationDashboard.module.scss';
import moment from 'moment';

interface IMicrosoftActivityStatisticProps extends WithTranslation {

}

interface IMicrosoftActivityStatisticState {
    error: IAlertErrorProps | null;
    loading: boolean;
    chart: any;
}

class MicrosoftActivityStatistic extends React.Component<IMicrosoftActivityStatisticProps, IMicrosoftActivityStatisticState> {

    public state: IMicrosoftActivityStatisticState = {
        error: null,
        loading: false,
        chart: null,
    }

    public componentDidMount() {
        this.getStatisticData();
    }

    public getStatisticData = async () => {
        try {

            this.setState({ loading: true, chart: null, error: null });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/dashboard/microsoft/activitystatistic');

            const chart = response.data;
            chart.data.labels = chart.data.labels.map((label: string) => moment(label).format('L'));
            chart.data.datasets = chart.data.datasets.map((dataset: any) => { return { ...dataset, label: this.props.t(dataset.label) } });

            this.setState({ loading: false, chart: chart, error: null });

        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, chart: null, error: { message: error.response.data.errorCode, retry: () => this.getStatisticData() } });
            } else {
                this.setState({ loading: false, chart: null, error: { message: 'error.admin.dashboard.microsoft.statistic', retry: () => this.getStatisticData() } });
            }
        }
    }

    render() {
        return (
            <div className={styles.MicrosoftActivityStatistic}>
                <Segment style={{ marginBottom: '1px' }}>
                    <Text size="large">{this.props.t('admin.dashboard.microsoft.statistic.title')}</Text>
                </Segment>
                <Segment>
                    {this.state.loading ? <Loader /> : null}
                    {!this.state.loading && this.state.error ? <AlertError {...this.state.error} /> : null}
                    {!this.state.loading && !this.state.error && this.state.chart ? <Line {...this.state.chart} /> : null}
                </Segment>
            </div>
        );
    }

}

export default withTranslation()(MicrosoftActivityStatistic);
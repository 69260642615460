import { Button, Flex, Loader, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { GetMicrosoftActivityFeedResultDTO } from '../../../models/Microsoft/getMicrosoftActivityFeedResultDTO';
import { LocalizationService } from '../../../services/LocalizationService';
import ViewGridPlusOutlineIcon from 'mdi-react/ViewGridPlusOutlineIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import placeholder from '../../../images/product-placeholder.png';
import styles from './MicrosoftDashboard.module.scss';
import moment from 'moment';

interface IMicrosoftDashboardActivityFeedProps extends WithTranslation, RouteComponentProps {

}

interface IMicrosoftDashboardActivityFeedState {
    error: IAlertErrorProps | null;
    loading: boolean;
    activities: GetMicrosoftActivityFeedResultDTO[];
    totalCount: number;
    page: number;
}

class MicrosoftDashboardActivityFeed extends React.Component<IMicrosoftDashboardActivityFeedProps, IMicrosoftDashboardActivityFeedState> {

    private pageLimit: number = 5;

    public state: IMicrosoftDashboardActivityFeedState = {
        error: null,
        loading: true,
        activities: [],
        totalCount: -1,
        page: 1,
    }

    public componentDidMount() {
        this.getTenantActivities();
    }

    public componentDidUpdate(prevProps: IMicrosoftDashboardActivityFeedProps) {
        if ((prevProps.match.params as any).tenantId !== (this.props.match.params as any).tenantId) {
            this.getTenantActivities();
        }
    }

    public getTenantActivities = async (page: number = 1) => {

        if (page > 1) {
            this.setState({ error: null, loading: true, page: page });
        } else {
            this.setState({ error: null, loading: true, activities: [], totalCount: -1, page: 1 });
        }

        try {
            const tenantId = (this.props.match.params as any).tenantId;
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/' + tenantId + '/activities?limit=' + this.pageLimit + '&page=' + page);
            if (page > 1) {
                this.setState({ loading: false, activities: [...this.state.activities, ...response.data.results] });
            } else {
                this.setState({ loading: false, totalCount: response.data.totalCount, activities: response.data.results });
            }
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getTenantActivities(this.state.page) } });
            } else {
                this.setState({ loading: false, error: { message: 'error.microsoft.activityfeed.undefined', retry: () => this.getTenantActivities(this.state.page) } });
            }
        }

    }

    public getMoreTenantActivities = async () => {
        this.getTenantActivities(this.state.page + 1);
    }

    render() {
        return (
            <div className={styles.MicrosoftDashboardActivityFeed}>
                <Segment style={{ marginBottom: '1px' }}>
                    <Text size="large">{this.props.t('microsoft.dashboard.activityfeed.title')}</Text>
                </Segment>
                <Segment>
                    {this.state.activities.map((activity: GetMicrosoftActivityFeedResultDTO) => {
                        return (
                            <Flex gap="gap.small" key={activity.id} className="item">
                                <div>
                                    {activity.quantity > 0 ? <ViewGridPlusOutlineIcon /> : <TrashCanOutlineIcon />}
                                </div>
                                <div>
                                    <div>
                                        <Trans i18nKey={`microsoft.dashboard.activityfeed.${activity.unitType.toLowerCase().replace(/[^a-z]/gi, '')}.${activity.quantity > 0 ? 'added' : 'removed'}`} count={Math.abs(activity.quantity)} values={{
                                            user: activity.user?.displayName,
                                            quantity: Math.abs(activity.quantity),
                                            product: LocalizationService.getLocalizedString(activity.product?.title, this.props.i18n.language)
                                        }} />
                                    </div>
                                    <Flex gap="gap.small" className="product">
                                        <img src={activity.product.tileImage ? activity.product.tileImage : placeholder} alt="" />
                                        <div>
                                            <div><Text weight="semibold">{LocalizationService.getLocalizedString(activity.product?.title, this.props.i18n.language)}</Text></div>
                                            <div><Text size="small">{LocalizationService.getLocalizedString(activity.product?.summary, this.props.i18n.language)}</Text></div>
                                        </div>
                                    </Flex>
                                    <div><Text timestamp size="small">{moment(activity.date).fromNow()}</Text></div>
                                </div>
                            </Flex>
                        );
                    })}
                    {this.state.loading ? <Loader /> : null}
                    {this.state.error ? <AlertError {...this.state.error} /> : null}
                    {!this.state.loading && this.state.totalCount === 0 ? <div>{this.props.t('microsoft.dashboard.activityfeed.noresults')}</div> : null}
                    {!this.state.loading && this.state.page * this.pageLimit < this.state.totalCount && !this.state.error ? <React.Fragment>
                        <div className="show-more">
                            <Button primary content={this.props.t('microsoft.dashboard.activityfeed.showmore')} onClick={() => this.getMoreTenantActivities()} />
                        </div>
                    </React.Fragment> : null}
                </Segment>
            </div>
        );
    }

}

export default withTranslation()(MicrosoftDashboardActivityFeed);
import { Flex } from '@fluentui/react-northstar';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import MicrosoftDashboardActivityFeed from './MicrosoftDashboardActivityFeed';
import MicrosoftDashboardProfile from './MicrosoftDashboardProfile';
import MicrosoftDashboardSubscriptions from './MicrosoftDashboardSubscriptions';

interface IMicrosoftDashboardProps extends WithTranslation, RouteComponentProps {

}

interface IMicrosoftDashboardState {

}

class MicrosoftDashboard extends React.Component<IMicrosoftDashboardProps, IMicrosoftDashboardState> {
    render() {
        return (
            <div>
                <h1>{this.props.t('microsoft.dashboard.title')}</h1>
                <div style={{ marginBottom: '12px' }}>
                    <MicrosoftDashboardProfile {...this.props} />
                </div>
                <Flex gap="gap.small">
                    <div style={{ width: '50%' }}>
                        <MicrosoftDashboardSubscriptions {...this.props} />
                    </div>
                    <div style={{ width: '50%' }}>
                        <MicrosoftDashboardActivityFeed {...this.props} />
                    </div>
                </Flex>
            </div>
        );
    }
}

export default withTranslation()(MicrosoftDashboard);
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseIcon, Dialog, Flex, Loader, RadioGroup, Text, TextArea } from '@fluentui/react-northstar';
import Axios from 'axios';
import { Persona, PersonaSize } from '@fluentui/react';
import { GetMicrosoftContactsDTO } from '../../../models/Microsoft/getMicrosoftContactsDTO';
import { LocalizationService } from '../../../services/LocalizationService';
import { GetMicrosoftProductsDTO } from '../../../models/Microsoft/getMicrosoftProductsDTO';
import { SendMicrosoftProductRequestDTO } from '../../../models/Microsoft/sendMicrosoftProductRequestDTO';
import placeholder from '../../../images/product-placeholder.png';
import styles from './MicrosoftProducts.module.scss';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import MicrosoftProductTypeBadge from '../../../components/microsoft/ProductTypeBadge';

interface IMicrosoftProductRequestProps extends WithTranslation {
    onClose?: any;
    tenantId: string;
    productId: string;
}

interface IMicrosoftProductRequestState {
    loading: boolean;
    loadingError: IAlertErrorProps | null;
    sending: boolean;
    sendingError: IAlertErrorProps | null;
    contacts: GetMicrosoftContactsDTO[];
    product: GetMicrosoftProductsDTO | null;
    formData: SendMicrosoftProductRequestDTO;
}

class MicrosoftProductRequest extends React.Component<IMicrosoftProductRequestProps, IMicrosoftProductRequestState> {

    private subjects: any[] = [{
        name: 'subject',
        key: 'licenses',
        value: this.props.t('microsoft.products.request.subjects.licenses'),
        label: this.props.t('microsoft.products.request.subjects.licenses'),
    }, {
        name: 'subject',
        key: 'consulting',
        value: this.props.t('microsoft.products.request.subjects.consulting'),
        label: this.props.t('microsoft.products.request.subjects.consulting'),
    }, {
        name: 'subject',
        key: 'meeting',
        value: this.props.t('microsoft.products.request.subjects.meeting'),
        label: this.props.t('microsoft.products.request.subjects.meeting'),
    }];

    public state: IMicrosoftProductRequestState = {
        loading: false,
        loadingError: null,
        sending: false,
        sendingError: null,
        contacts: [],
        product: null,
        formData: { message: '' } as SendMicrosoftProductRequestDTO,
    }

    public async componentDidMount() {
        this.getFormData();
    }

    public getFormData = async () => {
        this.setState({ loading: true, loadingError: null, contacts: [], product: null });
        try {
            const contactResponse = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/' + this.props.tenantId + '/contacts');
            const productResponse = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/' + this.props.tenantId + '/products/' + this.props.productId);
            this.setState({ loading: false, contacts: contactResponse.data, product: productResponse.data });
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, loadingError: { message: error.response.data.errorCode, retry: () => this.getFormData() } });
            } else {
                this.setState({ loading: false, loadingError: { message: 'error.microsoft.productrequest.undefined', retry: () => this.getFormData() } });
            }
        }
    }

    public onChange = (field: string, value: any) => {
        const formData: any = { ...this.state.formData };
        formData[field] = value;
        this.setState({ formData: formData });
    }

    public submitForm = async () => {
        this.setState({ sending: true, sendingError: null });
        try {
            const data: SendMicrosoftProductRequestDTO = {
                subject: this.state.formData.subject && this.state.formData.subject.length > 0 ? this.state.formData.subject : this.subjects[0].value,
                message: this.state.formData.message ? this.state.formData.message : '',
            };
            await Axios.post(window.env.REACT_APP_API_BASE + '/api/microsoft/' + this.props.tenantId + '/products/' + this.props.productId + '/request', data);
            if (this.props.onClose) this.props.onClose();
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ sending: false, sendingError: { message: error.response.data.errorCode } });
            } else {
                this.setState({ sending: false, sendingError: { message: 'error.microsoft.productrequest.sendmail' } });
            }
        }
    }

    render() {

        const form = (
            <React.Fragment>
                <div className={styles.MicrosoftProductRequest}>
                    {this.state.loading ? <Loader /> : null}
                    {!this.state.loading && this.state.loadingError ? <AlertError {...this.state.loadingError} /> : null}
                    {!this.state.sending && this.state.sendingError ? <div style={{ marginBottom: '12px' }}><AlertError {...this.state.sendingError} /></div> : null}
                    {!this.state.loading && !this.state.loadingError ? <React.Fragment>
                        <div><Text weight="bold">{this.props.t('microsoft.products.request.labels.recipients', { count: this.state.contacts.length })}</Text></div>
                        <div className="form-field-recipients">
                            {this.state.contacts.length > 0 ? this.state.contacts.map((contact: GetMicrosoftContactsDTO) => (
                                <div key={contact.id} className="recipient">
                                    <Persona imageUrl={contact.picture} text={`${contact.firstname} ${contact.lastname}`} secondaryText={contact.jobTitle} showSecondaryText={true} size={PersonaSize.size32} />
                                </div>
                            )) : <AlertError message={this.props.t('microsoft.products.request.nocontacts')} />}
                        </div>
                        <div><Text weight="bold">{this.props.t('microsoft.products.request.labels.product')}</Text></div>
                        <div className="form-field-product">
                            {this.state.product ? <Flex gap="gap.small" className="product">
                                <img src={this.state.product.tileImage ? this.state.product.tileImage : placeholder} alt="" />
                                <div>
                                    <div>
                                        <Text weight="semibold">{LocalizationService.getLocalizedString(this.state.product.title, this.props.i18n.language)}</Text>
                                        <MicrosoftProductTypeBadge productType={this.state.product.productType} />
                                    </div>
                                    <div><Text size="small">{LocalizationService.getLocalizedString(this.state.product.summary, this.props.i18n.language)}</Text></div>
                                </div>
                            </Flex> : null}
                        </div>
                        <div><Text weight="bold">{this.props.t('microsoft.products.request.labels.subject')}</Text></div>
                        <div className="form-field-subject">
                            <RadioGroup vertical items={this.subjects.map((subject: any) => { return { ...subject, disabled: this.state.sending } })} defaultCheckedValue={this.subjects[0].value}
                                checkedValue={this.state.formData.subject} onCheckedValueChange={(evt: any, props: any) => { this.onChange('subject', props.value) }} />
                        </div>
                        <div><Text weight="bold">{this.props.t('microsoft.products.request.labels.message')}</Text></div>
                        <div className="form-field-message">
                            <TextArea required={false} fluid={true} disabled={this.state.sending} variables={{ height: '120px' }} resize="vertical"
                                value={this.state.formData.message} onChange={(evt: any) => { this.onChange('message', evt.target?.value) }}
                                placeholder={this.props.t('microsoft.products.request.placeholder.message')} />
                        </div>
                    </React.Fragment> : null}
                </div>
            </ React.Fragment>
        );

        return (
            <Dialog
                open={true}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose(false)}
                onConfirm={this.submitForm}
                cancelButton={{ content: this.props.t('microsoft.products.request.cancel'), disabled: this.state.sending }}
                confirmButton={{ content: this.props.t('microsoft.products.request.confirm'), primary: true, disabled: this.state.sending || this.state.contacts.length === 0, loading: this.state.sending }}
                content={form}
                header={this.props.t('microsoft.products.request.title')}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('microsoft.products.request.close'), onClick: () => this.props.onClose && this.props.onClose(), disabled: this.state.sending }} />
        );

    }

}

export default withTranslation()(MicrosoftProductRequest);
import { LocalizedString } from "../models/Common/localizedString";
import moment from 'moment';
import i18n from 'i18next';

export class LocalizationService {

    public static changeLanguage(language: string): void {
        localStorage.setItem('language', language);
        i18n.changeLanguage(language);
        moment.locale(language);
    }

    public static getLocalizedString(localizedString: LocalizedString, language: string): string {
        if (localizedString !== null) {
            switch (language) {
                case 'de':
                    return localizedString.de;
                case 'en':
                default:
                    return localizedString.en;
            }
        }
        return '';
    }

}
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CloseIcon, Dialog } from '@fluentui/react-northstar';
import { LocalizationService } from '../../../services/LocalizationService';
import styles from './CANCOMProducts.module.scss';
import { GetProductsDTO } from '../../../models/Administration/CANCOMProductController/getProductsDTO';
import sanitizeHtml from 'sanitize-html';

interface ICANCOMProductDetailsProps extends WithTranslation {
    onClose?: any;
    product: GetProductsDTO;
}

interface ICANCOMProductDetailsState {

}

class CANCOMProductDetails extends React.Component<ICANCOMProductDetailsProps, ICANCOMProductDetailsState> {

    private allowedTags = ['a', 'i', 'p', 'ul', 'ol', 'li', 'span', 'div', 'b', 'strong', 'code', 'table', 'thead', 'tbody', 'tr', 'th', 'td', 'br'];

    public state: ICANCOMProductDetailsState = {

    }

    render() {

        const html = sanitizeHtml(LocalizationService.getLocalizedString(this.props.product.description, this.props.i18n.language), { allowedTags: this.allowedTags, allowedAttributes: false });

        const content = (
            <React.Fragment>
                <div className={styles.CANCOMProductDetails} dangerouslySetInnerHTML={{ __html: html }}></div>
            </ React.Fragment>
        );

        return (
            <Dialog
                open={true}
                closeOnOutsideClick={true}
                content={content}
                header={LocalizationService.getLocalizedString(this.props.product.title, this.props.i18n.language)}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('cancom.products.details.close'), onClick: () => this.props.onClose && this.props.onClose() }} />
        );

    }

}

export default withTranslation()(CANCOMProductDetails);
import React, { CSSProperties } from 'react';
import styles from './Badge.module.scss';

interface IBadgeProps {
    content: string;
    title?: string;
    color?: 'default' | 'primary' | 'microsoft';
    style?: CSSProperties;
}

const Badge: React.FC<IBadgeProps> = (props: IBadgeProps) => {

    let style: any = {};

    if (props.color === 'primary') {
        style.color = '#ffffff';
        style.backgroundColor = '#ff0035';
    } if (props.color === 'microsoft') {
        style.color = '#ffffff';
        style.backgroundColor = '#005ca4';
    }

    return (
        <span className={styles.Badge} style={{ ...style, ...props.style }} title={props.title}>{props.content}</span>
    );

}

export default Badge;
import { Flex, Grid, Loader, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { GetMicrosoftContactsDTO } from '../../../models/Microsoft/getMicrosoftContactsDTO';
import PhoneClassicIcon from 'mdi-react/PhoneClassicIcon';
import CellphoneIcon from 'mdi-react/CellphoneIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import styles from './MicrosoftContacts.module.scss';

interface IMicrosoftContactsProps extends WithTranslation, RouteComponentProps {

}

interface IMicrosoftContactsState {
    error: IAlertErrorProps | null;
    loading: boolean;
    contacts: GetMicrosoftContactsDTO[];
}

class MicrosoftContacts extends React.Component<IMicrosoftContactsProps, IMicrosoftContactsState> {

    public state: IMicrosoftContactsState = {
        error: null,
        loading: false,
        contacts: [],
    }

    public componentDidMount() {
        this.getTenantContacts();
    }

    public componentDidUpdate(prevProps: IMicrosoftContactsProps) {
        if ((prevProps.match.params as any).tenantId !== (this.props.match.params as any).tenantId) {
            this.getTenantContacts();
        }
    }

    public getTenantContacts = async () => {
        const tenantId = (this.props.match.params as any).tenantId;
        this.setState({ error: null, loading: true, contacts: [] });
        try {
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/' + tenantId + '/contacts');
            this.setState({ loading: false, contacts: response.data });
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getTenantContacts() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.microsoft.contacts.undefined', retry: () => this.getTenantContacts() } });
            }
        }
    }

    render() {
        return (
            <div className={styles.MicrosoftContacts}>
                <h1>{this.props.t('microsoft.contacts.title')}</h1>
                {this.state.loading ? <Loader /> : null}
                {!this.state.loading && this.state.error ? <AlertError {...this.state.error} /> : null}
                {!this.state.loading && !this.state.error ? <React.Fragment>
                    <Grid columns={3} >
                        {this.state.contacts.map((contact: GetMicrosoftContactsDTO) => {
                            return (
                                <Segment key={contact.id} className="item">
                                    <Flex gap="gap.small" className="contact">
                                        {contact.picture && contact.picture.length > 0 ? <img src={contact.picture} alt="" /> : null}
                                        <div>
                                            <div className="name"><Text weight="bold">{contact.firstname} {contact.lastname}</Text></div>
                                            <div className="jobtitle"><Text>{contact.jobTitle}</Text></div>
                                            <div className="information">
                                                {contact.phone && contact.phone.length > 0 ? <div><PhoneClassicIcon /> <a href={`tel:${contact.phone}`}>{contact.phone}</a></div> : null}
                                                {contact.mobile && contact.mobile.length > 0 ? <div><CellphoneIcon /> <a href={`tel:${contact.mobile}`}>{contact.mobile}</a></div> : null}
                                                {contact.mail && contact.mail.length > 0 ? <div><EmailOutlineIcon /> <a href={`mailto:${contact.mail}`}>{contact.mail}</a></div> : null}
                                            </div>
                                            {contact.description && contact.description.length > 0 ? <div className="description">{contact.description}</div> : null}
                                        </div>
                                    </Flex>
                                </Segment>
                            );
                        })}
                    </Grid>
                </React.Fragment> : null}
            </div>
        );
    }

}

export default withTranslation()(MicrosoftContacts);
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Alert, Button, CloseIcon, Dialog, Flex, Input, Loader, SearchIcon, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import { GetTenantsDTO } from '../../../../models/Administration/MicrosoftTenantController/getTenantsDTO';
import { Persona, PersonaSize } from '@fluentui/react';
import AccountRemoveIcon from 'mdi-react/AccountRemoveIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import { GetTenantContactsDTO } from '../../../../models/Administration/MicrosoftTenantController/getTenantContactsDTO';
import { GetContactsDTO } from '../../../../models/Administration/ContactController/getContactsDTO';
import AlertError, { IAlertErrorProps } from '../../../../components/alerts/AlertError';
import styles from './MicrosoftTenants.module.scss';

interface IMicrosoftTenantContactsDialogProps extends WithTranslation {
    onClose?: any;
    tenant: GetTenantsDTO;
}

interface IMicrosoftTenantContactsDialogState {
    error: string | null;
    assigned: { loading: boolean, contacts?: GetTenantContactsDTO[], error: IAlertErrorProps | null };
    assignedContactRemoved: GetTenantContactsDTO | null;
    available: { loading: boolean, contacts?: GetContactsDTO[], error: IAlertErrorProps | null };
    search: { input: string, contacts: GetTenantContactsDTO[] };
}

class MicrosoftTenantContactsDialog extends React.Component<IMicrosoftTenantContactsDialogProps, IMicrosoftTenantContactsDialogState> {

    public state: IMicrosoftTenantContactsDialogState = {
        error: null,
        assigned: { loading: true, error: null },
        assignedContactRemoved: null,
        available: { loading: true, error: null },
        search: { input: '', contacts: [] },
    }

    public async componentDidMount() {
        this.getAssignedContacts();
        this.getAvailableContacts();
    }

    public getAssignedContacts = async () => {
        try {
            this.setState({ assigned: { loading: true, error: null } });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/contacts');
            this.setState({ assigned: { loading: false, contacts: response.data, error: null } });
            this.onSearch(this.state.search.input);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ assigned: { loading: false, error: { message: error.response.data.errorCode, retry: () => this.getAssignedContacts() } } });
            } else {
                this.setState({ assigned: { loading: false, error: { message: 'error.admin.microsoft.tenants.loadassignedcontacts', retry: () => this.getAssignedContacts() } } });
            }
        }
    }

    public getAvailableContacts = async () => {
        try {
            this.setState({ available: { loading: true, error: null } });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/contacts');
            this.setState({ available: { loading: false, contacts: response.data, error: null } });
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ available: { loading: false, error: { message: error.response.data.errorCode, retry: () => this.getAvailableContacts() } } });
            } else {
                this.setState({ available: { loading: false, error: { message: 'error.admin.microsoft.tenants.loadavailablecontacts', retry: () => this.getAvailableContacts() } } });
            }
        }
    }

    public onSearch = (searchInput: string) => {
        const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
        let searchResults: GetContactsDTO[] = [];
        if (this.state.assigned.contacts && this.state.available.contacts && searchInput && searchInput.length >= 2) {
            searchResults = this.state.available.contacts.filter((contact: GetContactsDTO) => this.state.assigned.contacts && !this.state.assigned.contacts.some((tenantContact: GetTenantContactsDTO) => tenantContact.id === contact.id) && (contact.firstname?.match(searchRegex) || contact.lastname?.match(searchRegex) || contact.mail?.match(searchRegex)));
        }
        this.setState({ search: { input: searchInput, contacts: searchResults.slice(0, 10) } });
    }

    public onAddContact = async (contact: GetContactsDTO | GetTenantContactsDTO | null) => {
        try {
            if (contact === null) return;
            this.setState({ assigned: { ...this.state.assigned, loading: true }, assignedContactRemoved: null });
            await Axios.put(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/contacts/' + contact?.id);
            this.getAssignedContacts();
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ assigned: { ...this.state.assigned, loading: false, error: { message: error.response.data.errorCode, retry: () => this.onAddContact(contact) } } });
            } else {
                this.setState({ assigned: { ...this.state.assigned, loading: false, error: { message: 'error.admin.microsoft.tenants.addcontact', retry: () => this.onAddContact(contact) } } });
            }
        }
    }

    public onRemoveContact = async (contact: GetTenantContactsDTO) => {
        try {
            this.setState({ assigned: { ...this.state.assigned, loading: true }, assignedContactRemoved: null });
            await Axios.delete(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/contacts/' + contact.id);
            this.setState({ assignedContactRemoved: contact });
            this.getAssignedContacts();
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ assigned: { ...this.state.assigned, loading: false, error: { message: error.response.data.errorCode, retry: () => this.onRemoveContact(contact) } } });
            } else {
                this.setState({ assigned: { ...this.state.assigned, loading: false, error: { message: 'error.admin.microsoft.tenants.removecontact', retry: () => this.onRemoveContact(contact) } } });
            }
        }
    }

    render() {

        const form = (
            <div className={styles.MicrosoftTenantUsersDialog}>
                <Flex gap="gap.small" style={{ marginTop: '8px' }}>
                    <div style={{ width: '50%' }}>
                        <Segment style={{ marginBottom: '1px' }}>
                            <strong>{this.props.t('admin.microsoft.tenants.dialog.contacts.sections.assigned.title')}</strong>
                        </Segment>
                        <Segment className="assigned">
                            {!this.state.assigned.loading && this.state.assigned.error ? <AlertError {...this.state.assigned.error} /> : null}
                            {this.state.assigned.loading ? <Loader /> : <React.Fragment>
                                {this.state.assignedContactRemoved ? <Alert warning={true} className="removed">
                                    {this.props.t('admin.microsoft.tenants.dialog.contacts.sections.assigned.removed', { name: this.state.assignedContactRemoved.firstname + ' ' + this.state.assignedContactRemoved.lastname })}
                                    <Text weight="semibold" className="revert" onClick={() => this.onAddContact(this.state.assignedContactRemoved)}>
                                        {this.props.t('admin.microsoft.tenants.dialog.contacts.sections.assigned.revert')}
                                    </Text>
                                </Alert> : null}
                                {this.state.assigned.contacts && this.state.assigned.contacts.length === 0 ? <div>{this.props.t('admin.microsoft.tenants.dialog.contacts.sections.assigned.empty')}</div> : null}
                                {this.state.assigned.contacts && this.state.assigned.contacts.length > 0 ? this.state.assigned.contacts.map((contact: GetTenantContactsDTO) => (
                                    <Flex key={contact.id} space="between" vAlign="center" className="item">
                                        <Persona text={`${contact.firstname} ${contact.lastname}`} secondaryText={contact.mail} showSecondaryText={true} size={PersonaSize.size32} />
                                        <Button size="small" icon={<AccountRemoveIcon />} content={this.props.t('admin.microsoft.tenants.dialog.contacts.sections.assigned.remove')} onClick={() => this.onRemoveContact(contact)} />
                                    </Flex>
                                )) : null}
                            </React.Fragment>}
                        </Segment>
                    </div>
                    <div style={{ width: '50%' }}>
                        <Segment style={{ marginBottom: '1px' }}>
                            <strong>{this.props.t('admin.microsoft.tenants.dialog.contacts.sections.available.title')}</strong>
                        </Segment>
                        <Segment className="available">
                            {!this.state.available.loading && this.state.available.error ? <AlertError {...this.state.available.error} /> : null}
                            {!this.state.available.error ? <div>
                                <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off" icon={<SearchIcon />} placeholder={this.props.t('admin.microsoft.tenants.dialog.contacts.sections.available.search')}
                                    disabled={this.state.available.loading} value={this.state.search.input} onChange={(evt: any) => this.onSearch(evt.target?.value)} />
                            </div> : null}
                            {this.state.search.contacts.map((contact: GetContactsDTO) => (
                                <Flex key={contact.id} space="between" vAlign="center" style={{ marginTop: '12px' }}>
                                    <Persona text={`${contact.firstname} ${contact.lastname}`} secondaryText={contact.mail} showSecondaryText={true} size={PersonaSize.size32} />
                                    <Button size="small" icon={<AccountPlusIcon />} content={this.props.t('admin.microsoft.tenants.dialog.contacts.sections.available.add')} onClick={() => this.onAddContact(contact)} />
                                </Flex>
                            ))}
                        </Segment>
                    </div>
                </Flex>
            </div>
        );

        return (
            <Dialog
                open={true}
                closeOnOutsideClick={false}
                content={form}
                header={this.props.t('admin.microsoft.tenants.dialog.contacts.title', { name: this.props.tenant.name })}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.microsoft.tenants.dialog.common.close'), onClick: () => this.props.onClose && this.props.onClose() }} />
        );

    }

}

export default withTranslation()(MicrosoftTenantContactsDialog);
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { validate } from 'validate.js';
import { Alert, CloseIcon, Dialog, Input } from '@fluentui/react-northstar';
import { FormValidationMessages } from '../../../components/formValidation/FormValidationMessages';

interface IUserInviteDialogProps extends WithTranslation {
    onClose?: any;
}

interface IUserInviteDialogState {
    error: string | null;
    sending: boolean;
    formData: {
        invitedUserDisplayName: string;
        invitedUserEmailAddress: string;
        inviteRedirectUrl: string;
    },
    formValidation?: {
        invitedUserDisplayName?: string[];
        invitedUserEmailAddress?: string[];
    };
}

class UserInviteDialog extends React.Component<IUserInviteDialogProps, IUserInviteDialogState> {

    public state: IUserInviteDialogState = {
        error: null,
        sending: false,
        formData: {
            invitedUserDisplayName: '',
            invitedUserEmailAddress: '',
            inviteRedirectUrl: `${window.location.protocol}//${window.location.host}`,
        },
        formValidation: {},
    }

    public onChange = (field: string, value: any) => {
        const formData: any = { ...this.state.formData };
        formData[field] = value;
        this.setState({ formData: formData });
    }

    public submitForm = async () => {

        const errors = validate(this.state.formData, {
            invitedUserDisplayName: {
                presence: { allowEmpty: false, message: this.props.t('admin.users.dialog.invite.validation.presence') },
            },
            invitedUserEmailAddress: {
                presence: { allowEmpty: false, message: this.props.t('admin.users.dialog.invite.validation.presence') },
                email: { message: this.props.t('admin.users.dialog.invite.validation.email') },
            },
        });

        this.setState({ formValidation: errors });

        if (!errors) {
            try {
                this.setState({ sending: true, error: null });
                await Axios.post(window.env.REACT_APP_API_BASE + '/api/admin/users/invite', this.state.formData);
                this.setState({ sending: false });
                this.props.onClose(true);
            } catch (error: any) {
                console.error(error);
                if (error?.response?.data?.errorCode) {
                    this.setState({ sending: false, error: error.response.data.errorCode });
                } else {
                    this.setState({ sending: false, error: 'error.admin.users.inviteuser' });
                }
            }
        }

    }

    render() {

        const form = (
            <React.Fragment>
                {!this.state.sending && this.state.error ? <Alert danger={true} content={this.props.t(this.state.error)} /> : null}
                <div style={{ marginTop: '12px' }}>
                    <Input label={this.props.t('admin.users.dialog.invite.labels.name')} required={true} fluid={true} value={this.state.formData.invitedUserDisplayName} disabled={this.state.sending}
                        showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('invitedUserDisplayName', evt.target?.value) }}
                        error={this.state.formValidation?.invitedUserDisplayName && this.state.formValidation?.invitedUserDisplayName.length > 0} />
                    <FormValidationMessages messages={this.state.formValidation?.invitedUserDisplayName} />
                </div>
                <div style={{ marginTop: '12px' }}>
                    <Input type="email" label={this.props.t('admin.users.dialog.invite.labels.mail')} required={true} fluid={true} value={this.state.formData.invitedUserEmailAddress} disabled={this.state.sending}
                        showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('invitedUserEmailAddress', evt.target?.value) }}
                        error={this.state.formValidation?.invitedUserEmailAddress && this.state.formValidation?.invitedUserEmailAddress.length > 0} />
                    <FormValidationMessages messages={this.state.formValidation?.invitedUserEmailAddress} />
                </div>
            </React.Fragment>
        );

        return (
            <Dialog
                open={true}
                styles={{ width: '420px' }}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose(false)}
                onConfirm={this.submitForm}
                cancelButton={{ content: this.props.t('admin.users.dialog.invite.cancel'), disabled: this.state.sending }}
                confirmButton={{ content: this.props.t('admin.users.dialog.invite.confirm'), primary: true, disabled: this.state.sending, loading: this.state.sending }}
                content={form}
                header={this.props.t('admin.users.dialog.invite.title')}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.users.dialog.invite.close'), onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.sending }} />
        );

    }

}

export default withTranslation()(UserInviteDialog);
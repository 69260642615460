import React from 'react';
import Badge from '../badge/Badge';

interface IMicrosoftProductTypeBadgeProps {
    productType: string;
}

const MicrosoftProductTypeBadge: React.FC<IMicrosoftProductTypeBadgeProps> = (props: IMicrosoftProductTypeBadgeProps) => {
    if (props.productType === 'OnlineServicesNCE') {
        return <Badge color="microsoft" content="NCE" title="New Commerce Experience" style={{ marginLeft: '5px' }} />;
    }
    return null;
}

export default MicrosoftProductTypeBadge;
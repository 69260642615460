import { Loader, } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import { GetMicrosoftSubscriptionsDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsDTO';
import { GetMicrosoftSubscriptionsFilterDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsFilterDTO';
import MicrosoftSubscriptionsCheckout from './MicrosoftSubscriptionsCheckout';
import MicrosoftSubscriptionsForm from './MicrosoftSubscriptionsForm';
import moment from 'moment';

interface IMicrosoftSubscriptionsProps extends WithTranslation, RouteComponentProps {

}

interface IMicrosoftSubscriptionsState {
    error: IAlertErrorProps | null;
    loading: boolean;
    subscriptions: GetMicrosoftSubscriptionsDTO[];
    formData: any;
}

class MicrosoftSubscriptions extends React.Component<IMicrosoftSubscriptionsProps, IMicrosoftSubscriptionsState> {

    public state: IMicrosoftSubscriptionsState = {
        error: null,
        loading: false,
        subscriptions: [],
        formData: {}
    }

    public componentDidMount() {
        this.getTenantSubscriptions();
    }

    public componentDidUpdate(prevProps: IMicrosoftSubscriptionsProps) {
        if ((prevProps.match.params as any).tenantId !== (this.props.match.params as any).tenantId) {
            this.getTenantSubscriptions();
        }
    }

    public getTenantSubscriptions = async () => {
        const tenantId = (this.props.match.params as any).tenantId;
        this.setState({ loading: true, subscriptions: [], formData: {}, error: null });
        try {
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/' + tenantId + '/subscriptions?filter=' + GetMicrosoftSubscriptionsFilterDTO.purchasable);
            const subscriptions: GetMicrosoftSubscriptionsDTO[] = response.data;
            const formData: any = {};
            subscriptions.forEach((subscription: GetMicrosoftSubscriptionsDTO) => {
                formData[subscription.subscription.id] = subscription.subscription.quantity;
            });
            this.setState({ loading: false, subscriptions: subscriptions, formData: formData });
        } catch (error: any) {
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getTenantSubscriptions() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.microsoft.subscriptions.undefined', retry: () => this.getTenantSubscriptions() } });
            }
        }
    }

    public onChange = (formData: any) => {
        this.setState({ formData: { ...formData } });
    }

    public getValidationMessage = async (subscriptionId: string, newQuantity: number): Promise<string> => {

        const subscriptionIndex = this.state.subscriptions.findIndex((subscription: GetMicrosoftSubscriptionsDTO) => subscription.subscription.id === subscriptionId)
        let subscription = this.state.subscriptions[subscriptionIndex];

        if (subscription && subscription.product.productType === 'OnlineServicesNCE') {

            if (newQuantity >= subscription.subscription.quantity || moment().isBefore(subscription.subscription.cancellationAllowedUntilDate)) {
                return '';
            }

            if (!subscription.subscription.refundableQuantity) {

                try {
                    const tenantId = (this.props.match.params as any).tenantId;
                    const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/' + tenantId + '/subscriptions/' + subscriptionId);
                    subscription = response.data;
                } catch (error: any) {
                    if (error?.response?.data?.errorCode) {
                        return this.props.t(error.response.data.errorCode);
                    } else {
                        return this.props.t('error.microsoft.subscriptions.undefined');
                    }
                }

                const subscriptions = [...this.state.subscriptions];
                subscriptions.splice(subscriptionIndex, 1, subscription);
                this.setState({ subscriptions: subscriptions });

            }

            if (subscription.subscription.refundableQuantity && newQuantity >= subscription.subscription.quantity - subscription.subscription.refundableQuantity.totalQuantity) {
                return '';
            }

            return this.props.t(`microsoft.subscriptions.form.errors.minquantity.${subscription.subscription.unitType.toLowerCase().replace(/[^a-z]/gi, '')}`, { count: subscription.subscription.quantity - subscription.subscription.refundableQuantity.totalQuantity });

        }

        return ''; // default for other products

    }

    render() {
        return (
            <div>
                <h1>{this.props.t('microsoft.subscriptions.title')}</h1>
                {this.state.loading ? <Loader /> : null}
                {!this.state.loading && this.state.error ? <AlertError {...this.state.error} /> : null}
                {!this.state.loading && !this.state.error ? <React.Fragment>
                    <Switch>
                        <Route path="/microsoft/:tenantId/subscriptions/checkout" render={(props: RouteComponentProps) => <MicrosoftSubscriptionsCheckout subscriptions={this.state.subscriptions} formData={this.state.formData} {...props} />} />
                        <Route render={(props: RouteComponentProps) => <MicrosoftSubscriptionsForm subscriptions={this.state.subscriptions} formData={this.state.formData} onChange={this.onChange} getValidationMessage={this.getValidationMessage} refreshSubscriptions={this.getTenantSubscriptions} {...props} />} />
                    </Switch>
                </React.Fragment> : null}
            </div>
        );
    }

}

export default withTranslation()(MicrosoftSubscriptions);
import { Button, Dropdown, Flex, Loader, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { LocalizationService } from '../../../services/LocalizationService';
import ViewGridPlusOutlineIcon from 'mdi-react/ViewGridPlusOutlineIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import { GetMicrosoftActivityFeedResultDTO } from '../../../models/Administration/DashboardController/getMicrosoftActivityFeedResultDTO';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import placeholder from '../../../images/product-placeholder.png';
import styles from './AdministrationDashboard.module.scss';
import moment from 'moment';

interface IMicrosoftActivityFeedProps extends WithTranslation {

}

interface IMicrosoftActivityFeedState {
    tenants: { loading: boolean, results: GetMicrosoftActivityFeedResultDTO[], error: IAlertErrorProps | null }
    activities: { loading: boolean, totalCount: number, results: GetMicrosoftActivityFeedResultDTO[], error: IAlertErrorProps | null }
    page: number;
    tenantId: string | null;
}

class MicrosoftActivityFeed extends React.Component<IMicrosoftActivityFeedProps, IMicrosoftActivityFeedState> {

    private pageLimit: number = 5;

    public state: IMicrosoftActivityFeedState = {
        tenants: { loading: false, results: [], error: null },
        activities: { loading: false, totalCount: -1, results: [], error: null },
        page: 1,
        tenantId: null,
    }

    public componentDidMount() {
        this.getTenants();
        this.getActivities();
    }

    public getTenants = async () => {
        try {
            this.setState({ tenants: { loading: false, results: [], error: null } });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants');
            this.setState({ tenants: { loading: false, results: response.data, error: null } });
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ tenants: { loading: false, results: [], error: { message: error.response.data.errorCode, retry: () => this.getTenants() } } });
            } else {
                this.setState({ tenants: { loading: false, results: [], error: { message: 'error.admin.dashboard.microsoft.activityfeedtenants', retry: () => this.getTenants() } } });
            }
        }
    }

    public getActivities = async (page: number = 1, tenantId: string | null = null) => {

        if (page > 1 && tenantId === this.state.tenantId) {
            this.setState({ activities: { ...this.state.activities, loading: true, error: null }, page: page, tenantId: tenantId });
        } else {
            this.setState({ activities: { loading: true, totalCount: -1, results: [], error: null }, page: page, tenantId: tenantId });
        }

        try {
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/dashboard/microsoft/activityfeed?limit=' + this.pageLimit + '&page=' + page + (tenantId ? '&tenantId=' + tenantId : ''));
            if (page > 1) {
                this.setState({ activities: { ...this.state.activities, loading: false, results: [...this.state.activities.results, ...response.data.results] } });
            } else {
                this.setState({ activities: { ...this.state.activities, loading: false, totalCount: response.data.totalCount, results: response.data.results } });
            }
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ activities: { ...this.state.activities, loading: false, error: { message: error.response.data.errorCode, retry: () => this.getActivities(this.state.page, this.state.tenantId) } } });
            } else {
                this.setState({ activities: { ...this.state.activities, loading: false, error: { message: 'error.admin.dashboard.microsoft.activityfeedentries', retry: () => this.getActivities(this.state.page, this.state.tenantId) } } });
            }
        }

    }

    public getMoreActivities = async () => {
        this.getActivities(this.state.page + 1, this.state.tenantId);
    }

    public onChangeFilter = (value: any) => {
        this.getActivities(1, value?.id);
    }

    render() {
        return (
            <div className={styles.MicrosoftActivityFeed}>
                <Segment style={{ marginBottom: '1px' }}>
                    <Text size="large">{this.props.t('admin.dashboard.microsoft.activityfeed.title')}</Text>
                </Segment>
                <Segment>
                    {!this.state.tenants.loading && this.state.tenants.error ? <div style={{marginBottom: '12px'}}><AlertError {...this.state.tenants.error} /></div> : null}
                    {!this.state.tenants.error ? <div style={{ marginBottom: '16px' }}>
                        <Dropdown fluid search clearable loading={this.state.tenants.loading} items={this.state.tenants.results}
                            itemToString={(props: any) => props?.name} onChange={(evt: any, { value }) => this.onChangeFilter(value)}
                            renderItem={(Item, props: any) => <Item {...props} key={props.id} header={props.name} style={{ cursor: 'pointer' }} />}
                            loadingMessage={this.props.t('admin.dashboard.microsoft.activityfeed.filterLoading')}
                            placeholder={this.props.t('admin.dashboard.microsoft.activityfeed.filterSelect')}
                            noResultsMessage={this.props.t('admin.dashboard.microsoft.activityfeed.filterNoResults')} />
                    </div> : null}
                    {this.state.activities.results.map((activity: GetMicrosoftActivityFeedResultDTO) => {
                        return (
                            <Flex gap="gap.small" key={activity.id} className="item">
                                <div>
                                    {activity.quantity > 0 ? <ViewGridPlusOutlineIcon /> : <TrashCanOutlineIcon />}
                                </div>
                                <div>
                                    <div>
                                        <Trans i18nKey={`admin.dashboard.microsoft.activityfeed.${this.state.tenantId ? 'filtered' : 'all'}.${activity.unitType.toLowerCase().replace(/[^a-z]/gi, '')}.${activity.quantity > 0 ? 'added' : 'removed'}`} count={Math.abs(activity.quantity)} values={{
                                            tenant: activity.tenant?.name,
                                            user: activity.user?.displayName,
                                            quantity: Math.abs(activity.quantity),
                                            product: LocalizationService.getLocalizedString(activity.product?.title, this.props.i18n.language)
                                        }} />
                                    </div>
                                    <Flex gap="gap.small" className="product">
                                        <img src={activity.product.tileImage ? activity.product.tileImage : placeholder} alt="" />
                                        <div>
                                            <div><Text weight="semibold">{LocalizationService.getLocalizedString(activity.product?.title, this.props.i18n.language)}</Text></div>
                                            <div><Text size="small">{LocalizationService.getLocalizedString(activity.product?.summary, this.props.i18n.language)}</Text></div>
                                        </div>
                                    </Flex>
                                    <div><Text timestamp size="small">{moment(activity.date).fromNow()}</Text></div>
                                </div>
                            </Flex>
                        );
                    })}
                    {this.state.activities.loading ? <Loader /> : null}
                    {this.state.activities.error ? <AlertError {...this.state.activities.error} /> : null}
                    {!this.state.activities.loading && this.state.activities.totalCount === 0 ? <div>{this.props.t('admin.dashboard.microsoft.activityfeed.noresults')}</div> : null}
                    {!this.state.activities.loading && this.state.page * this.pageLimit < this.state.activities.totalCount && !this.state.activities.error ? <React.Fragment>
                        <div className="show-more">
                            <Button primary content={this.props.t('admin.dashboard.microsoft.activityfeed.showmore')} onClick={() => this.getMoreActivities()} />
                        </div>
                    </React.Fragment> : null}
                </Segment>
            </div>
        );
    }

}

export default withTranslation()(MicrosoftActivityFeed);
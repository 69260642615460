import { Button, CloseIcon, Dialog, Flex, FlexItem, Input, Text } from '@fluentui/react-northstar';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import MicrosoftProductTypeBadge from '../../../components/microsoft/ProductTypeBadge';
import { GetMicrosoftSubscriptionsDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsDTO';
import { LocalizationService } from '../../../services/LocalizationService';
import placeholder from '../../../images/product-placeholder.png';
import styles from './MicrosoftSubscriptions.module.scss';
import moment from 'moment';
import Axios from 'axios';
import { SetMicrosoftSubscriptionScheduledChangesDTO } from '../../../models/Microsoft/setMicrosoftSubscriptionScheduledChangesDTO';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import MicrosoftSubscriptionScheduledChangesDeleteDialog from './MicrosoftSubscriptionScheduledChangesDeleteDialog';

interface IMicrosoftSubscriptionScheduledChangesDialogProps extends WithTranslation {
    onClose?: any;
    tenantId: string;
    subscription: GetMicrosoftSubscriptionsDTO;
}

interface IMicrosoftSubscriptionScheduledChangesDialogState {
    saving: boolean;
    deleting: boolean;
    error: IAlertErrorProps | null;
    showDeleteDialog: boolean;
    quantity: number;
}

class MicrosoftSubscriptionScheduledChangesDialog extends React.Component<IMicrosoftSubscriptionScheduledChangesDialogProps, IMicrosoftSubscriptionScheduledChangesDialogState> {

    constructor(props: IMicrosoftSubscriptionScheduledChangesDialogProps) {
        super(props);
        this.state = {
            saving: false,
            deleting: false,
            error: null,
            showDeleteDialog: false,
            quantity: props.subscription.subscription.scheduledNextTermInstructions?.quantity || props.subscription.subscription.quantity,
        };
    }

    private onChangeQuantity = (value: string) => {
        this.setState({ quantity: parseInt(value) >= 1 ? parseInt(value) : 1 });
    }

    private onSubmit = async () => {
        try {
            const data: SetMicrosoftSubscriptionScheduledChangesDTO = {
                subscriptionId: this.props.subscription.subscription.id,
                quantity: this.state.quantity,
            };
            this.setState({ saving: true, error: null });
            await Axios.patch(window.env.REACT_APP_API_BASE + '/api/microsoft/' + this.props.tenantId + '/subscriptions/scheduled-changes', data);
            if (this.props.onClose) this.props.onClose(true);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ saving: false, error: { message: error.response.data.errorCode } });
            } else {
                this.setState({ saving: false, error: { message: 'error.microsoft.scheduledchanges.undefined' } });
            }
        }
    }

    private openConfirmDeleteDialog = () => {
        this.setState({ showDeleteDialog: true });
    }

    private closeConfirmDeleteDialog = (confirmed: boolean = false) => {
        this.setState({ showDeleteDialog: false });
        if (confirmed) this.onDelete();
    }

    private onDelete = async () => {
        try {
            this.setState({ deleting: true, error: null });
            await Axios.delete(window.env.REACT_APP_API_BASE + '/api/microsoft/' + this.props.tenantId + '/subscriptions/scheduled-changes/' + this.props.subscription.subscription.id);
            if (this.props.onClose) this.props.onClose(true);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ deleting: false, error: { message: error.response.data.errorCode } });
            } else {
                this.setState({ deleting: false, error: { message: 'error.microsoft.scheduledchanges.undefined' } });
            }
        }
    }

    public render() {

        const form = (
            <div className={styles.MicrosoftSubscriptionsScheduledChanges}>
                {!this.state.saving && !this.state.deleting && this.state.error ? <div style={{ marginBottom: '14px' }}><AlertError {...this.state.error} /></div> : null}
                <div className="form-field-product">
                    <Flex gap="gap.small" className="product">
                        <img src={this.props.subscription.product.tileImage ? this.props.subscription.product.tileImage : placeholder} alt="" />
                        <div>
                            <div>
                                <Text weight="semibold">
                                    {LocalizationService.getLocalizedString(this.props.subscription.product.title, this.props.i18n.language)}
                                    <MicrosoftProductTypeBadge productType={this.props.subscription.product.productType} />
                                </Text>
                            </div>
                            <div><Text size="small">{LocalizationService.getLocalizedString(this.props.subscription.product.summary, this.props.i18n.language)}</Text></div>
                        </div>
                    </Flex>
                </div>
                <div className="form-field-quantity">
                    <p><Trans i18nKey={'microsoft.subscriptions.dialog.changes.effectivedate'} />: <Text weight="semibold">{moment(this.props.subscription.subscription.commitmentEndDate).add(1, 'days').format('L')}</Text></p>
                    <p><strong><Trans i18nKey={'microsoft.subscriptions.dialog.changes.currentquantity'} /></strong>: {this.props.subscription.subscription.quantity}</p>
                    <Input label={<strong>{this.props.t('microsoft.subscriptions.dialog.changes.newquantity')}</strong>} fluid={true} value={this.state.quantity}
                        disabled={this.state.saving || this.state.deleting} onChange={(evt: any) => { this.onChangeQuantity(evt.target?.value) }} />
                </div>
            </div>
        );

        return (
            <Dialog
                open={true}
                styles={{ width: '600px' }}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose()}
                onConfirm={this.onSubmit}
                cancelButton={{ content: this.props.t('microsoft.subscriptions.dialog.changes.cancel'), disabled: this.state.saving || this.state.deleting }}
                confirmButton={{ content: this.props.t('microsoft.subscriptions.dialog.changes.confirm'), primary: true, disabled: this.state.saving || this.state.deleting, loading: this.state.saving }}
                content={form}
                footer={{
                    children: (Component, props) => {
                        const { styles, ...rest } = props
                        return (
                            <Flex styles={{ ...styles, width: '100%', gridColumn: '1 / span 2' }} space="between">
                                <FlexItem>{this.props.subscription.subscription.scheduledNextTermInstructions ? <React.Fragment>
                                    <Button content={this.props.t('microsoft.subscriptions.dialog.changes.delete')} onClick={this.openConfirmDeleteDialog} loading={this.state.deleting} disabled={this.state.deleting} styles={{ color: '#A33D2A', ':hover': { color: '#8E192E' }, '[disabled]': { color: '#c8c6c4' } }} />
                                    {this.state.showDeleteDialog ? <MicrosoftSubscriptionScheduledChangesDeleteDialog onClose={this.closeConfirmDeleteDialog} /> : null}
                                </React.Fragment> : <span>&nbsp;</span>}</FlexItem>
                                <FlexItem><Component {...rest} /></FlexItem>
                            </Flex>
                        );
                    }
                }}
                header={this.props.t('microsoft.subscriptions.dialog.changes.title')}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('microsoft.subscriptions.dialog.changes.close'), onClick: () => this.props.onClose && this.props.onClose(), disabled: this.state.saving || this.state.deleting }} />
        );

    }

}

export default withTranslation()(MicrosoftSubscriptionScheduledChangesDialog);
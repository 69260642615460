import { Button } from '@fluentui/react-northstar';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AuthService } from '../../services/AuthService';
import styles from './Content.module.scss';
import logo from '../../images/cancom.svg';
import { AuthenticationState } from 'react-aad-msal';

class Home extends React.Component<WithTranslation> {

    public login = async () => {
        try {
            await AuthService.login();
        } catch (error: any) {
            console.error(error);
        }
    }

    public getSupportLink = (): string => {
        const recipient = window.env.REACT_APP_SUPPORT_RECIPIENT;
        const subject = encodeURIComponent(window.env[`REACT_APP_SUPPORT_SUBJECT_${this.props.i18n.language}`.toUpperCase()]);
        const message = encodeURIComponent(window.env[`REACT_APP_SUPPORT_MESSAGE_${this.props.i18n.language}`.toUpperCase()]);
        return `mailto:${recipient}?subject=${subject}&body=${message}`;
    }

    render() {
        return (
            <div className={styles.ContentFullpage}>
                <div className={styles.ContentFullpageContainer}>
                    <div className={styles.ContentContainerFlex}>
                        <div className={styles.HeaderImageContainer}>
                            <img src={logo} alt="CANCOM Tune" title="CANCOM Tune" className={styles.HeaderImage} />
                        </div>
                        <div className={styles.LoginButtonContainer}>
                            <Button primary fluid content={this.props.t('header.login')} onClick={this.login}
                                disabled={AuthService.getAuthenticationState() === AuthenticationState.InProgress} />
                        </div>
                        <div className={styles.ContentFooter}>
                            <span className={styles.FooterTitle}>powered by </span>
                            <a href="https://www.cancom.at" target="_blank" rel="noreferrer">CANCOM Austria AG</a>
                        </div>
                    </div>
                </div>
                <div className={styles.ContentFullpageFooter}>
                    &copy; CANCOM Austria AG
                    &nbsp;|&nbsp;
                    <a href={window.env[`REACT_APP_LEGAL_IMPRINT_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.imprint')}</a>
                    &nbsp;|&nbsp;
                    <a href={window.env[`REACT_APP_LEGAL_TOU_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.tou')}</a>
                    &nbsp;|&nbsp;
                    <a href={window.env[`REACT_APP_LEGAL_PRIVACY_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.privacy')}</a>
                    &nbsp;|&nbsp;
                    <a href={window.env[`REACT_APP_LEGAL_GTC_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.gtc')}</a>
                    &nbsp;|&nbsp;
                    <a href={window.env[`REACT_APP_LEGAL_MSCA_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.msca')}</a>
                    &nbsp;|&nbsp;
                    <a href={this.getSupportLink()}>{this.props.t('footer.support')}</a>
                </div>
            </div>
        );
    }

}

export default withTranslation()(Home);
import { Button, Flex, Input, SearchIcon } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GetProductsDTO } from '../../../../models/Administration/MicrosoftProductController/getProductsDTO';
import PencilOutlineIcon from 'mdi-react/PencilOutlineIcon';
import ReloadIcon from 'mdi-react/ReloadIcon';
import MicrosoftProductEditDialog from './MicrosoftProductEditDialog';
import { LocalizationService } from '../../../../services/LocalizationService';
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import AlertError, { IAlertErrorProps } from '../../../../components/alerts/AlertError';
import MicrosoftProductTypeBadge from '../../../../components/microsoft/ProductTypeBadge';

interface IMicrosoftProductsProps extends WithTranslation {

}

interface IMicrosoftProductsState {
    error: IAlertErrorProps | null;
    loading: boolean;
    products: GetProductsDTO[],
    openEditProductDialog: boolean;
    selectedProduct: GetProductsDTO | null;
    searchInput: string;
    items: GetProductsDTO[],
}

class MicrosoftProducts extends React.Component<IMicrosoftProductsProps, IMicrosoftProductsState> {

    public state: IMicrosoftProductsState = {
        error: null,
        loading: false,
        products: [],
        openEditProductDialog: false,
        selectedProduct: null,
        searchInput: '',
        items: [],
    }

    public componentDidMount() {
        this.getProducts();
    }

    public getProducts = async () => {
        try {
            this.setState({ loading: true, products: [], error: null });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/products');
            this.setState({ loading: false, products: response.data });
            this.onSearch(this.state.searchInput);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getProducts() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.admin.microsoft.products.loadlist', retry: () => this.getProducts() } });
            }
        }
    }

    public openEditProductDialog = (product: GetProductsDTO) => {
        this.setState({ openEditProductDialog: true, selectedProduct: product });
    }

    public closeEditProductDialog = (refresh: boolean) => {
        this.setState({ openEditProductDialog: false, selectedProduct: null });
        if (refresh) this.getProducts();
    }

    public onSearch = (searchInput: string) => {
        const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
        const items = this.state.products.filter((product: GetProductsDTO) => LocalizationService.getLocalizedString(product.title, this.props.i18n.language)?.match(searchRegex) || LocalizationService.getLocalizedString(product.summary, this.props.i18n.language)?.match(searchRegex));
        this.setState({ searchInput: searchInput, items: items });
    }

    render() {

        const columns: IColumn[] = [{
            key: 'name',
            name: this.props.t('admin.microsoft.products.list.headers.name'),
            minWidth: 0,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true,
            onRender: (product: GetProductsDTO) => {
                return <React.Fragment>
                    {LocalizationService.getLocalizedString(product.title, this.props.i18n.language)}
                    <MicrosoftProductTypeBadge productType={product.productType} />
                </React.Fragment>;
            }
        }, {
            key: 'summary',
            name: this.props.t('admin.microsoft.products.list.headers.summary'),
            minWidth: 480,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true,
            onRender: (product: GetProductsDTO) => {
                return LocalizationService.getLocalizedString(product.summary, this.props.i18n.language);
            }
        }, {
            key: 'actions',
            name: this.props.t('admin.microsoft.products.list.headers.actions'),
            minWidth: 240,
            maxWidth: 240,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (product: GetProductsDTO) => {
                return <Flex gap="gap.small">
                    <Button content={this.props.t('admin.microsoft.products.list.actions.edit')} icon={<PencilOutlineIcon />} iconPosition="before" onClick={() => this.openEditProductDialog(product)} />
                </Flex>;
            }
        }];

        const table = <ShimmeredDetailsList
            columns={columns}
            items={this.state.items}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            enableShimmer={this.state.loading} />;

        return (
            <div>
                <h1>{this.props.t('admin.microsoft.products.list.title')}</h1>
                <Flex space="between">
                    <Flex gap="gap.small">
                        <Button content={this.props.t('admin.microsoft.products.list.refresh')} icon={<ReloadIcon />} iconPosition="before" onClick={this.getProducts} />
                    </Flex>
                    <div style={{ width: '280px' }}>
                        {!this.state.loading ? <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off" icon={<SearchIcon />} placeholder={this.props.t('admin.microsoft.products.list.search')}
                            value={this.state.searchInput} onChange={(evt: any) => this.onSearch(evt.target?.value)} /> : null}
                    </div>
                </Flex>
                {!this.state.error ? table : null}
                {!this.state.loading && this.state.error ? <div style={{ marginTop: '12px' }}><AlertError {...this.state.error} /></div> : null}
                {this.state.openEditProductDialog && this.state.selectedProduct ? <MicrosoftProductEditDialog onClose={this.closeEditProductDialog} product={this.state.selectedProduct} /> : null}
            </div>
        );

    }

}

export default withTranslation()(MicrosoftProducts);
import React from 'react';
import { Text } from '@fluentui/react-northstar';

interface IFormValidationMessagesProps {
    messages: string[] | undefined;
}

export class FormValidationMessages extends React.Component<IFormValidationMessagesProps> {
    render() {
        return (
            <div>
                {this.props.messages?.map((message, index) => <Text error size="small" content={message} key={index} style={{ display: 'block' }} />)}
            </div>
        )

    }
}
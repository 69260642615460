import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Alert, Checkbox, CloseIcon, Dialog } from '@fluentui/react-northstar';
import styles from './UserManagement.module.scss';
import Axios from 'axios';
import { Persona, PersonaSize } from '@fluentui/react';

interface IUserGroupMembershipDialogProps extends WithTranslation {
    user: any;
    onClose?: any;
}

interface IUserGroupMembershipDialogState {
    error: string | null;
    saving: boolean;
    formData: {
        groupAdministrators: boolean;
        groupEmployees: boolean;
        groupCustomers: boolean;
    }
}

class UserGroupMembershipDialog extends React.Component<IUserGroupMembershipDialogProps, IUserGroupMembershipDialogState> {

    public state: IUserGroupMembershipDialogState = {
        error: null,
        saving: false,
        formData: {
            groupAdministrators: false,
            groupEmployees: false,
            groupCustomers: false,
        }
    }

    public componentDidMount() {
        this.setState({
            formData: {
                groupAdministrators: this.props.user.groups.some((group: any) => group.id === window.env.REACT_APP_AAD_GROUP_ADMINISTRATORS),
                groupEmployees: this.props.user.groups.some((group: any) => group.id === window.env.REACT_APP_AAD_GROUP_EMPLOYEES),
                groupCustomers: this.props.user.groups.some((group: any) => group.id === window.env.REACT_APP_AAD_GROUP_CUSTOMERS),
            }
        })
    }

    public onChange = (field: string, value: any) => {
        const formData: any = { ...this.state.formData };
        formData[field] = value;
        this.setState({ formData: formData });
    }

    public onSubmit = async () => {
        try {
            this.setState({ saving: true, error: null });
            await Axios.post(window.env.REACT_APP_API_BASE + '/api/admin/users/' + this.props.user.id + '/groups', this.state.formData);
            this.setState({ saving: false });
            this.props.onClose(true);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ saving: false, error: error.response.data.errorCode });
            } else {
                this.setState({ saving: false, error: 'error.admin.users.savegroups' });
            }
        }
    }

    render() {

        const form = (
            <div className={styles.UserGroupMembershipDialog}>
                {!this.state.saving && this.state.error ? <Alert danger={true} content={this.props.t(this.state.error)} /> : null}
                <Persona text={this.props.user.displayName} secondaryText={this.props.user.mail} showSecondaryText={true} size={PersonaSize.size32} />
                <div style={{ marginTop: '18px' }}>
                    <div style={{ marginBottom: '4px' }}>
                        <strong>{this.props.t('admin.users.dialog.groups.title')}</strong>
                    </div>
                    <div>
                        <Checkbox label={this.props.t('admin.users.groups.administrators')} checked={this.state.formData.groupAdministrators} disabled={this.state.saving}
                            onChange={(evt: any, input: any) => { this.onChange('groupAdministrators', input.checked) }} />
                    </div>
                    <div>
                        <Checkbox label={this.props.t('admin.users.groups.employees')} checked={this.state.formData.groupEmployees} disabled={this.state.saving}
                            onChange={(evt: any, input: any) => { this.onChange('groupEmployees', input.checked) }} />
                    </div>
                    <div>
                        <Checkbox label={this.props.t('admin.users.groups.customers')} checked={this.state.formData.groupCustomers} disabled={this.state.saving}
                            onChange={(evt: any, input: any) => { this.onChange('groupCustomers', input.checked) }} />
                    </div>
                </div>
            </div>
        );

        return (
            <Dialog
                open={true}
                styles={{ width: '420px' }}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose(false)}
                onConfirm={this.onSubmit}
                cancelButton={{ content: this.props.t('admin.users.dialog.groups.cancel'), disabled: this.state.saving }}
                confirmButton={{ content: this.props.t('admin.users.dialog.groups.confirm'), primary: true, disabled: this.state.saving, loading: this.state.saving }}
                content={form}
                header={this.props.t('admin.users.dialog.groups.title')}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.users.dialog.groups.close'), onClick: () => this.props.onClose(false), disabled: this.state.saving }} />
        );

    }

}

export default withTranslation()(UserGroupMembershipDialog);
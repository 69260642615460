import { Alert, Button, Checkbox, CloseIcon, Dialog, Flex, Input, Text, TextArea } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ReactImageUploading from 'react-images-uploading';
import validate from 'validate.js';
import AlertError, { IAlertErrorProps } from '../../../../components/alerts/AlertError';
import { FormValidationMessages } from '../../../../components/formValidation/FormValidationMessages';
import { GetProductsDTO } from '../../../../models/Administration/MicrosoftProductController/getProductsDTO';
import { UpdateProductDTO } from '../../../../models/Administration/MicrosoftProductController/updateProductDTO';
import { UtilService } from '../../../../services/UtilService';

interface IMicrosoftProductEditDialogProps extends WithTranslation {
    onClose?: any;
    product: GetProductsDTO;
}

interface IMicrosoftProductEditDialogState {
    error: IAlertErrorProps | null;
    loading: boolean;
    formData: UpdateProductDTO;
    formValidation?: any;

}

class MicrosoftProductEditDialog extends React.Component<IMicrosoftProductEditDialogProps, IMicrosoftProductEditDialogState> {

    public state: IMicrosoftProductEditDialogState = {
        error: null,
        loading: false,
        formData: {} as UpdateProductDTO,
        formValidation: {},
    }

    public async componentDidMount() {
        if (this.props.product) {
            this.setState({
                formData: {
                    ...UtilService.cloneDeep(this.props.product),
                    forceDefaultVisible: false,
                    forceDefaultPurchasable: false,
                }
            });
        }
    }

    public onChange = (field: string, value: any) => {
        const formData: any = { ...this.state.formData };
        if (field.indexOf('.') !== -1) {
            const fields = field.split('.');
            formData[fields[0]][fields[1]] = value;
        } else {
            formData[field] = value;
        }
        this.setState({ formData: formData });
    }

    public submitForm = async () => {

        if (this.state.loading) return;

        const errors = validate(this.state.formData, {
            "title.de": {
                presence: { allowEmpty: false, message: this.props.t('admin.microsoft.products.dialog.edit.validation.presence') },
            },
            "title.en": {
                presence: { allowEmpty: false, message: this.props.t('admin.microsoft.products.dialog.edit.validation.presence') },
            },
        });

        this.setState({ formValidation: errors });

        if (!errors) {
            try {
                this.setState({ loading: true, error: null });
                await Axios.patch(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/products/' + this.props.product?.id, this.state.formData);
                this.setState({ loading: false });
                this.props.onClose(true);
            } catch (error: any) {
                console.error(error);
                if (error?.response?.data?.errorCode) {
                    this.setState({ loading: false, error: { message: error.response.data.errorCode } });
                } else {
                    this.setState({ loading: false, error: { message: 'error.admin.microsoft.products.saveproduct' } });
                }
            }
        }

    }

    render() {

        const content = (
            <React.Fragment>
                {!this.state.loading && this.state.error ? <AlertError {...this.state.error} /> : null}
                <Flex gap="gap.small" style={{ marginTop: '12px' }}>
                    <div style={{ width: '50%' }}>
                        <Input label={this.props.t('admin.microsoft.products.dialog.edit.labels.title_en')} required={true} fluid={true} value={this.state.formData.title?.en} disabled={this.state.loading}
                            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('title.en', evt.target?.value) }}
                            error={this.state.formValidation && this.state.formValidation['title.en'] && this.state.formValidation['title.en'].length > 0} />
                        <FormValidationMessages messages={this.state.formValidation ? this.state.formValidation['title.en'] : null} />
                    </div>
                    <div style={{ width: '50%' }}>
                        <Input label={this.props.t('admin.microsoft.products.dialog.edit.labels.title_de')} required={true} fluid={true} value={this.state.formData.title?.de} disabled={this.state.loading}
                            showSuccessIndicator={false} onChange={(evt: any) => { this.onChange('title.de', evt.target?.value) }}
                            error={this.state.formValidation && this.state.formValidation['title.de'] && this.state.formValidation['title.de'].length > 0} />
                        <FormValidationMessages messages={this.state.formValidation ? this.state.formValidation['title.de'] : null} />
                    </div>
                </Flex>
                <Flex gap="gap.small" style={{ marginTop: '12px' }}>
                    <div style={{ width: '50%' }}>
                        <div style={{ marginBottom: '4px' }}><Text content={this.props.t('admin.microsoft.products.dialog.edit.labels.summary_en')} /></div>
                        <TextArea required={false} fluid={true} value={this.state.formData.summary?.en} disabled={this.state.loading}
                            variables={{ height: '80px' }} resize="vertical" onChange={(evt: any) => { this.onChange('summary.en', evt.target?.value) }} />
                    </div>
                    <div style={{ width: '50%' }}>
                        <div style={{ marginBottom: '4px' }}><Text content={this.props.t('admin.microsoft.products.dialog.edit.labels.summary_de')} /></div>
                        <TextArea required={false} fluid={true} value={this.state.formData.summary?.de} disabled={this.state.loading}
                            variables={{ height: '80px' }} resize="vertical" onChange={(evt: any) => { this.onChange('summary.de', evt.target?.value) }} />
                    </div>
                </Flex>
                <Flex gap="gap.small" style={{ marginTop: '12px' }}>
                    <div style={{ width: '50%' }}>
                        <div style={{ marginBottom: '4px' }}><Text content={this.props.t('admin.microsoft.products.dialog.edit.labels.description_en')} /></div>
                        <TextArea required={false} fluid={true} value={this.state.formData.description?.en} disabled={this.state.loading}
                            variables={{ height: '160px' }} resize="vertical" onChange={(evt: any) => { this.onChange('description.en', evt.target?.value) }} />
                    </div>
                    <div style={{ width: '50%' }}>
                        <div style={{ marginBottom: '4px' }}><Text content={this.props.t('admin.microsoft.products.dialog.edit.labels.description_de')} /></div>
                        <TextArea required={false} fluid={true} value={this.state.formData.description?.de} disabled={this.state.loading}
                            variables={{ height: '160px' }} resize="vertical" onChange={(evt: any) => { this.onChange('description.de', evt.target?.value) }} />
                    </div>
                </Flex>
                <Flex gap="gap.small" style={{ marginTop: '12px' }}>
                    <div style={{ width: '50%' }}>
                        <div style={{ marginBottom: '4px' }}><Text content={this.props.t('admin.microsoft.products.dialog.edit.labels.defaults')} /></div>
                        <div>
                            <Checkbox label={this.props.t('admin.microsoft.products.dialog.edit.labels.defaultVisible')} checked={this.state.formData.defaultVisible} disabled={this.state.loading}
                                onChange={(evt: any, input: any) => { this.onChange('defaultVisible', input.checked) }} />
                        </div>
                        <div>
                            <Checkbox label={this.props.t('admin.microsoft.products.dialog.edit.labels.defaultPurchasable')} checked={this.state.formData.defaultPurchasable} disabled={this.state.loading}
                                onChange={(evt: any, input: any) => { this.onChange('defaultPurchasable', input.checked) }} />
                        </div>
                        <div style={{ marginTop: '12px', marginBottom: '4px' }}><Text content={this.props.t('admin.microsoft.products.dialog.edit.labels.forceDefaults')} /></div>
                        <div>
                            <Checkbox label={this.props.t('admin.microsoft.products.dialog.edit.labels.forceDefaultVisible')} checked={this.state.formData.forceDefaultVisible} disabled={this.state.loading}
                                onChange={(evt: any, input: any) => { this.onChange('forceDefaultVisible', input.checked) }} />
                        </div>
                        <div>
                            <Checkbox label={this.props.t('admin.microsoft.products.dialog.edit.labels.forceDefaultPurchasable')} checked={this.state.formData.forceDefaultPurchasable} disabled={this.state.loading}
                                onChange={(evt: any, input: any) => { this.onChange('forceDefaultPurchasable', input.checked) }} />
                        </div>
                        {this.state.formData.forceDefaultVisible || this.state.formData.forceDefaultPurchasable ? <div style={{ marginTop: '12px' }}>
                            <Alert danger>{this.props.t('admin.microsoft.products.dialog.edit.labels.forceDefaultWarning')}</Alert>
                        </div> : null}
                    </div>
                    <div style={{ width: '50%' }}>
                        <div style={{ marginBottom: '4px' }}><Text content={this.props.t('admin.microsoft.products.dialog.edit.labels.tileImage')} /></div>
                        <ReactImageUploading acceptType={['jpg', 'png']} resolutionType="less" resolutionWidth={512} resolutionHeight={512}
                            value={this.state.formData.tileImage ? [{ dataURL: this.state.formData.tileImage }] : []}
                            onChange={(images: any[]) => { this.onChange('tileImage', images && images.length > 0 ? images[0]['dataURL'] : null) }}>
                            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps, errors }) => (
                                <div>
                                    {imageList && imageList.length > 0 ?
                                        <div {...dragProps}>
                                            {imageList.map((image, index) => (
                                                <div key={index} >
                                                    <img src={image['dataURL']} alt="" style={{ maxHeight: '128px' }} />
                                                    <div style={{ marginTop: '4px' }}>
                                                        <Button size="small" content={this.props.t('admin.microsoft.products.dialog.edit.labels.change')} onClick={() => onImageUpdate(index)} />&nbsp;
                                                        <Button size="small" content={this.props.t('admin.microsoft.products.dialog.edit.labels.remove')} onClick={() => onImageRemove(index)} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div style={{ border: isDragging ? '2px dashed #888888' : '2px dashed #cccccc', textAlign: 'center', padding: '20px', cursor: 'pointer' }} onClick={onImageUpload}  {...dragProps}>
                                            <strong>{this.props.t('admin.microsoft.products.dialog.edit.labels.dropzone')}</strong><br />
                                            <small>{this.props.t('admin.microsoft.products.dialog.edit.labels.filesize')}</small>
                                        </div>
                                    }
                                    {errors && errors.acceptType ? <FormValidationMessages messages={[this.props.t('admin.microsoft.products.dialog.edit.validation.filetype')]} /> : null}
                                    {errors && errors.resolution ? <FormValidationMessages messages={[this.props.t('admin.microsoft.products.dialog.edit.validation.filesize')]} /> : null}
                                </div>
                            )}
                        </ReactImageUploading>
                    </div>
                </Flex>
            </React.Fragment >
        );

        return (
            <Dialog
                open={true}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose(false)}
                onConfirm={this.submitForm}
                cancelButton={{ content: this.props.t('admin.microsoft.products.dialog.common.cancel'), disabled: this.state.loading }}
                confirmButton={{ content: this.props.t('admin.microsoft.products.dialog.edit.confirm'), primary: true, disabled: this.state.loading, loading: this.state.loading }}
                content={content}
                header={this.props.t('admin.microsoft.products.dialog.edit.title')}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.microsoft.products.dialog.common.close'), onClick: () => this.props.onClose && this.props.onClose(false), disabled: this.state.loading }} />
        );

    }

}

export default withTranslation()(MicrosoftProductEditDialog);
import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AuthenticationState, IAccountInfo } from 'react-aad-msal';
import { Group, PermissionService } from '../../services/PermissionService';
import styles from './Sidebar.module.scss';

import MicrosoftIcon from 'mdi-react/MicrosoftIcon';
import MonitorDashboardIcon from 'mdi-react/MonitorDashboardIcon';
import AccountBoxOutlineIcon from 'mdi-react/AccountBoxOutlineIcon';
import CogIcon from 'mdi-react/CogIcon';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import DomainIcon from 'mdi-react/DomainIcon';
import NewspaperVariantIcon from 'mdi-react/NewspaperVariantIcon';
import ContactsOutlineIcon from 'mdi-react/ContactsOutlineIcon';
import CartVariantIcon from 'mdi-react/CartVariantIcon';
import TextSubjectIcon from 'mdi-react/TextSubjectIcon';
import { GetUserMicrosoftTenantsDTO } from '../../models/Microsoft/getUserMicrosoftTenantsDTO';
import Axios from 'axios';
import { LocalizationService } from '../../services/LocalizationService';
import { Flex } from '@fluentui/react-northstar';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronTripleRightIcon from 'mdi-react/ChevronTripleRightIcon';

interface ISidebarProps extends WithTranslation, RouteComponentProps {
    authenticationState: AuthenticationState | undefined;
    accountInfo: IAccountInfo | null;
}

interface ISidebarState {
    microsoftTenants: GetUserMicrosoftTenantsDTO[];
    opened: string[],
}

class Sidebar extends React.Component<ISidebarProps> {

    public state: ISidebarState = {
        microsoftTenants: [],
        opened: [],
    }

    public async componentDidMount() {
        this.refreshMicrosoftTenants();
    }

    public componentDidUpdate(prevProps: ISidebarProps) {
        if (prevProps.authenticationState !== this.props.authenticationState) {
            this.refreshMicrosoftTenants();
        }
    }

    public refreshMicrosoftTenants = async () => {
        if (this.props.authenticationState === AuthenticationState.Authenticated) {

            let opened: string[] = [];
            const microsoftMatches = this.props.location.pathname.match(new RegExp('^/microsoft/([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})', 'i'));

            if (microsoftMatches && microsoftMatches.length >= 2) {
                opened.push(`microsoft-${microsoftMatches[1]}`);
                this.setState({ opened: opened });
            } else if (PermissionService.isMemberOfAnyGroup([Group.ADMINISTRATORS, Group.EMPLOYEES])) {
                opened.push('admin');
                this.setState({ opened: opened });
                if (this.props.location?.pathname === '/') this.props.history.push('/admin/dashboard');
            }

            try {
                const responseMicrosoftTenants = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/tenants');
                this.setState({ microsoftTenants: responseMicrosoftTenants.data });
                if (responseMicrosoftTenants.data.length > 0 && opened.length === 0) {
                    opened.push(`microsoft-${responseMicrosoftTenants.data[0].id}`);
                    this.setState({ opened: opened });
                    if (this.props.location?.pathname === '/') this.props.history.push(`/microsoft/${responseMicrosoftTenants.data[0].id}/dashboard`);
                }
            } catch (error: any) {
                console.error(error);
            }

        } else {
            this.setState({ microsoftTenants: [] })
        }
    }

    public changeLanguage = (language: string) => {
        LocalizationService.changeLanguage(language);
    }

    public toggleNavigation = (key: string) => {
        this.setState((prevState: ISidebarState) => {
            const opened: string[] = [...prevState.opened];
            if (opened.indexOf(key) !== -1) {
                opened.splice(opened.indexOf(key), 1);
            } else {
                opened.push(key);
            }
            return { opened: opened };
        });
    }

    public isOpen = (key: string): boolean => {
        return this.state.opened.indexOf(key) !== -1;
    }

    public getSupportLink = (): string => {
        const recipient = window.env.REACT_APP_SUPPORT_RECIPIENT;
        const subject = encodeURIComponent(window.env[`REACT_APP_SUPPORT_SUBJECT_${this.props.i18n.language}`.toUpperCase()]);
        const message = encodeURIComponent(window.env[`REACT_APP_SUPPORT_MESSAGE_${this.props.i18n.language}`.toUpperCase()]);
        return `mailto:${recipient}?subject=${subject}&body=${message}`;
    }

    render() {
        return (
            <div className={styles.Sidebar}>
                <div className={styles.SidebarNavigation}>
                    <ul>
                        {this.props.authenticationState === AuthenticationState.Authenticated && PermissionService.isMemberOfAnyGroup([Group.ADMINISTRATORS, Group.EMPLOYEES]) ? <React.Fragment>
                            <li key="admin" className={this.isOpen('admin') ? styles.SidebarOpen : undefined}>
                                <button onClick={() => this.toggleNavigation('admin')}>
                                    <Flex hAlign="start" vAlign="start">
                                        <div className="navigation-label"><CogIcon /><span>{this.props.t('sidebar.navigation.administration.title')}</span></div>
                                        <div className="navigation-chevron">{this.isOpen('admin') ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
                                    </Flex>
                                </button>
                                <ul>
                                    <li><NavLink to="/admin/dashboard"><MonitorDashboardIcon />{this.props.t('sidebar.navigation.administration.dashboard')}</NavLink ></li>
                                    {PermissionService.isMemberOfAnyGroup([Group.ADMINISTRATORS]) ? <li><NavLink to="/admin/users"><AccountGroupIcon />{this.props.t('sidebar.navigation.administration.users')}</NavLink ></li> : null}
                                    <li><NavLink to="/admin/contacts"><ContactsOutlineIcon />{this.props.t('sidebar.navigation.administration.contacts')}</NavLink ></li>
                                    <li><NavLink to="/admin/cancom/products"><ChevronTripleRightIcon />{this.props.t('sidebar.navigation.administration.cancom.products')}</NavLink ></li>
                                    <li className={styles.SidebarOpen}>
                                        <NavLink to="/admin/microsoft" exact><MicrosoftIcon />{this.props.t('sidebar.navigation.administration.microsoft.title')}</NavLink >
                                        <ul>
                                            <li><NavLink to="/admin/microsoft/tenants"><DomainIcon />{this.props.t('sidebar.navigation.administration.microsoft.tenants')}</NavLink ></li>
                                            <li><NavLink to="/admin/microsoft/products"><NewspaperVariantIcon />{this.props.t('sidebar.navigation.administration.microsoft.products')}</NavLink ></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </React.Fragment> : null}
                        {this.props.authenticationState === AuthenticationState.Authenticated && PermissionService.isMemberOfAnyGroup([Group.ADMINISTRATORS, Group.EMPLOYEES, Group.CUSTOMERS]) ? <React.Fragment>
                            <li><NavLink to={`/cancom/products`}><ChevronTripleRightIcon />{this.props.t('sidebar.navigation.cancom.products')}</NavLink ></li>
                        </React.Fragment> : null}
                        {this.props.authenticationState === AuthenticationState.Authenticated && this.state.microsoftTenants && this.state.microsoftTenants.length > 0 ? <React.Fragment>
                            {this.state.microsoftTenants.map((microsoftTenant: GetUserMicrosoftTenantsDTO) => (
                                <li key={`microsoft-${microsoftTenant.id}`} className={this.isOpen(`microsoft-${microsoftTenant.id}`) ? styles.SidebarOpen : undefined}>
                                    <button onClick={() => this.toggleNavigation(`microsoft-${microsoftTenant.id}`)}>
                                        <Flex hAlign="start" vAlign="start" title={microsoftTenant.name}>
                                            <div className="navigation-label"><MicrosoftIcon /><span>{microsoftTenant.name}</span></div>
                                            <div className="navigation-chevron">{this.isOpen(`microsoft-${microsoftTenant.id}`) ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
                                        </Flex>
                                    </button>
                                    <ul>
                                        <li><NavLink to={`/microsoft/${microsoftTenant.id}/dashboard`}><MonitorDashboardIcon />{this.props.t('sidebar.navigation.microsoft.dashboard')}</NavLink ></li>
                                        <li><NavLink to={`/microsoft/${microsoftTenant.id}/subscriptions`}><CartVariantIcon />{this.props.t('sidebar.navigation.microsoft.subscriptions')}</NavLink ></li>
                                        <li><NavLink to={`/microsoft/${microsoftTenant.id}/products`}><NewspaperVariantIcon />{this.props.t('sidebar.navigation.microsoft.products')}</NavLink ></li>
                                        <li><NavLink to={`/microsoft/${microsoftTenant.id}/contacts`}><AccountBoxOutlineIcon />{this.props.t('sidebar.navigation.microsoft.contacts')}</NavLink ></li>
                                    </ul>
                                </li>
                            ))}
                        </React.Fragment> : null}
                        {this.props.authenticationState !== AuthenticationState.Authenticated ? <React.Fragment>
                            <li><NavLink to="/imprint"><TextSubjectIcon />{this.props.t('sidebar.navigation.common.imprint')}</NavLink ></li>
                            <li><NavLink to="/privacy"><TextSubjectIcon />{this.props.t('sidebar.navigation.common.privacy')}</NavLink ></li>
                            <li><NavLink to="/terms"><TextSubjectIcon />{this.props.t('sidebar.navigation.common.terms')}</NavLink ></li>
                        </React.Fragment> : null}
                    </ul>
                </div>
                <div className={styles.SidebarFooter}>
                    <div className="language-selector">
                        <span onClick={() => this.changeLanguage('en')}>{this.props.t('footer.languageEN')}</span>
                        &nbsp;|&nbsp;
                        <span onClick={() => this.changeLanguage('de')}>{this.props.t('footer.languageDE')}</span>
                    </div>
                    <div className="legal">
                        &copy; CANCOM Austria AG<br />
                        <a href={window.env[`REACT_APP_LEGAL_IMPRINT_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.imprint')}</a>
                        <a href={window.env[`REACT_APP_LEGAL_TOU_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.tou')}</a>
                        <a href={window.env[`REACT_APP_LEGAL_PRIVACY_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.privacy')}</a>
                        <a href={window.env[`REACT_APP_LEGAL_GTC_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.gtc')}</a>
                        <a href={window.env[`REACT_APP_LEGAL_MSCA_URL_${this.props.i18n.language}`.toUpperCase()]} target="_blank" rel="noreferrer">{this.props.t('footer.msca')}</a>
                        <a href={this.getSupportLink()}>{this.props.t('footer.support')}</a>
                    </div>
                </div>
                <div className={styles.SidebarVersion}>{process.env.REACT_APP_VERSION}</div>
            </div>
        );
    }

}

export default withTranslation()(withRouter(Sidebar));
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from '@fluentui/react-northstar';
import styles from './MigrationNotice.module.scss';

interface IMigrationNoticeProps extends WithTranslation, RouteComponentProps { }

class MigrationNotice extends React.Component<IMigrationNoticeProps> {

  public onClose = () => {
    this.props.history.push(this.props.location.pathname);
  }

  render() {
    if (this.props.location.search.indexOf('redirected') === -1) {
      return null;
    }
    return (
      <div className={styles.MigrationNotice}>
        <div className={styles.MigrationNoticeContent}>
          <h1>{this.props.t('migration.headline')}</h1>
          <div dangerouslySetInnerHTML={{ __html: this.props.t('migration.content') }} />
          <Button primary fluid content={this.props.t('migration.button')} onClick={this.onClose} />
        </div>
      </div>
    );
  }

}

export default withTranslation()(withRouter(MigrationNotice));
import { Button, Flex, Grid, Input, Loader, SearchIcon, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import { LocalizationService } from '../../../services/LocalizationService';
import placeholder from '../../../images/product-placeholder.png';
import styles from './CANCOMProducts.module.scss';
import { GetProductsDTO } from '../../../models/Administration/CANCOMProductController/getProductsDTO';
import CANCOMProductRequest from './CANCOMProductRequest';
import CANCOMProductDetails from './CANCOMProductDetails';

interface ICANCOMProductsProps extends WithTranslation, RouteComponentProps {

}

interface ICANCOMProductsState {
    error: IAlertErrorProps | null;
    loading: boolean;
    products: GetProductsDTO[];
    items: GetProductsDTO[];
    openDetailsDialog: boolean;
    openRequestDialog: boolean;
    selectedProduct: GetProductsDTO | null;
    searchInput: string;
}

class CANCOMProducts extends React.Component<ICANCOMProductsProps, ICANCOMProductsState> {

    public state: ICANCOMProductsState = {
        error: null,
        loading: false,
        products: [],
        items: [],
        openDetailsDialog: false,
        openRequestDialog: false,
        selectedProduct: null,
        searchInput: '',
    };

    public componentDidMount() {
        this.getProducts();
    }

    public getProducts = async () => {
        this.setState({ error: null, loading: true, products: [], items: [], searchInput: '' });
        try {
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/cancom/products');
            this.setState({ loading: false, products: response.data });
            this.onSearch(this.state.searchInput);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getProducts() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.cancom.products.undefined', retry: () => this.getProducts() } });
            }
        }
    }

    public onSearch = (searchInput: string) => {
        const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
        const items = this.state.products.filter((product: GetProductsDTO) => LocalizationService.getLocalizedString(product?.title, this.props.i18n.language).match(searchRegex));
        this.setState({ searchInput: searchInput, items: items });
    }

    public openDetailsDialog = (product: GetProductsDTO) => {
        this.setState({ openDetailsDialog: true, selectedProduct: product });
    }

    public closeDetailsDialog = () => {
        this.setState({ openDetailsDialog: false, selectedProduct: null });
    }

    public openRequestDialog = (product: GetProductsDTO) => {
        this.setState({ openRequestDialog: true, selectedProduct: product });
    }

    public closeRequestDialog = () => {
        this.setState({ openRequestDialog: false, selectedProduct: null });
    }

    render() {
        return (
            <div className={styles.CANCOMProducts}>
                <Flex space="between" vAlign="start">
                    <h1>{this.props.t('cancom.products.title')}</h1>
                    {!this.state.loading && !this.state.error ? <div className="search">
                        <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off"
                            icon={<SearchIcon />} placeholder={this.props.t('cancom.products.search')}
                            value={this.state.searchInput} onChange={(evt: any) => this.onSearch(evt.target?.value)} />
                    </div> : null}
                </Flex>
                {this.state.loading ? <Loader /> : null}
                {!this.state.loading && this.state.error ? <AlertError {...this.state.error} /> : null}
                {!this.state.loading && !this.state.error ? <React.Fragment>
                    <Grid columns={2}>
                        {this.state.items.map((product: GetProductsDTO) => {
                            return (
                                <Segment key={product.id} className="item" onClick={(evt: any) => { evt.stopPropagation(); this.openDetailsDialog(product); }}>
                                    <Flex space="between">
                                        <Flex gap="gap.small" className="product">
                                            <img src={product.tileImage ? product.tileImage : placeholder} alt="" />
                                            <div>
                                                <div><Text weight="semibold">{LocalizationService.getLocalizedString(product.title, this.props.i18n.language)}</Text></div>
                                                <div><Text size="small">{LocalizationService.getLocalizedString(product.summary, this.props.i18n.language)}</Text></div>
                                            </div>
                                        </Flex>
                                        <div className="buttons">
                                            <div><Button content={this.props.t('cancom.products.contact')} onClick={(evt: any) => { evt.stopPropagation(); this.openRequestDialog(product); }} /></div>
                                            {LocalizationService.getLocalizedString(product.description, this.props.i18n.language)?.length > 0 && <div>
                                                <Button content={this.props.t('cancom.products.showmore')} onClick={(evt: any) => { evt.stopPropagation(); this.openDetailsDialog(product); }} />
                                            </div>}
                                        </div>
                                    </Flex>
                                </Segment>
                            );
                        })}
                    </Grid>
                </React.Fragment> : null}
                {this.state.openDetailsDialog && this.state.selectedProduct ? <CANCOMProductDetails onClose={this.closeDetailsDialog} product={this.state.selectedProduct} /> : null}
                {this.state.openRequestDialog && this.state.selectedProduct ? <CANCOMProductRequest onClose={this.closeRequestDialog} productId={this.state.selectedProduct?.id} /> : null}
            </div>
        );
    }
}

export default withTranslation()(CANCOMProducts);
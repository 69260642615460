import { AuthService } from '../services/AuthService';
import Axios from 'axios';
import i18n from 'i18next';

Axios.interceptors.request.use(async (config) => {
  const accessToken: string = await AuthService.getAccessToken();
  if (accessToken && accessToken.length > 0) {
    config.headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Accept-Language': i18n.language,
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
import { Alert, Button, Checkbox, Flex, Text, TextArea } from '@fluentui/react-northstar';
import Axios, { AxiosResponse } from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { GetMicrosoftSubscriptionsDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsDTO';
import { LocalizationService } from '../../../services/LocalizationService';
import { SetMicrosoftSubscriptionQuantityDTO } from '../../../models/Microsoft/setMicrosoftSubscriptionQuantityDTO';
import CheckIcon from 'mdi-react/CheckIcon';
import InformationOutlineIcon from 'mdi-react/InformationOutlineIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import styles from './MicrosoftSubscriptions.module.scss';
import { ColumnActionsMode, DetailsList, DetailsListLayoutMode, IColumn, MessageBar, MessageBarType, SelectionMode } from '@fluentui/react';
import placeholder from '../../../images/product-placeholder.png';
import { GetUserDTO } from '../../../models/Common/UserController/getUserDTO';
import moment from 'moment';
import MicrosoftProductTypeBadge from '../../../components/microsoft/ProductTypeBadge';

interface IMicrosoftSubscriptionsCheckoutProps extends WithTranslation, RouteComponentProps {
    subscriptions: GetMicrosoftSubscriptionsDTO[];
    formData: any;
}

interface IMicrosoftSubscriptionsCheckoutState {
    subscriptions: GetMicrosoftSubscriptionsDTO[];
    loading: boolean;
    finished: boolean;
    queue: any;
    step: number;
    terms: {
        tou: boolean;
        gtc: boolean;
        msca: boolean;
    },
    termsAccepted: Date | null;
}

class MicrosoftSubscriptionsCheckout extends React.Component<IMicrosoftSubscriptionsCheckoutProps, IMicrosoftSubscriptionsCheckoutState> {

    public state: IMicrosoftSubscriptionsCheckoutState = {
        subscriptions: [],
        loading: false,
        finished: false,
        queue: {},
        step: 1,
        terms: {
            tou: false,
            gtc: false,
            msca: false,
        },
        termsAccepted: null,
    }

    public componentDidMount() {

        const subscriptions = this.props.subscriptions.filter((subscription: GetMicrosoftSubscriptionsDTO) => this.props.formData[subscription.subscription.id] !== subscription.subscription.quantity);

        const queue: any = {};
        subscriptions.forEach((subscription: GetMicrosoftSubscriptionsDTO) => {
            queue[subscription.subscription.id] = {
                quantity: this.props.formData[subscription.subscription.id],
                status: 'open',
                error: null,
            };
        });

        this.setState({
            subscriptions: subscriptions,
            queue: queue,
        });

        Axios.get(window.env.REACT_APP_API_BASE + '/api/user/me').then((response: AxiosResponse) => {
            const user: GetUserDTO = response.data;
            if (user.termsAccepted) {
                this.setState({
                    terms: {
                        tou: true,
                        gtc: true,
                        msca: true,
                    },
                    termsAccepted: user.termsAccepted,
                })
            }
        });

    }

    public subscriptionsChanged = () => {
        let subscriptionsChanged = false;
        this.props.subscriptions.forEach((subscription: GetMicrosoftSubscriptionsDTO) => {
            if (subscription.subscription.quantity !== this.props.formData[subscription.subscription.id]) subscriptionsChanged = true;
        });
        return subscriptionsChanged;
    }

    public navigateTo = (step: number) => {
        this.setState({ step: step });
    }

    public onChangeNote = (subscription: GetMicrosoftSubscriptionsDTO, note: string) => {
        const queue = { ...this.state.queue };
        queue[subscription.subscription.id] = { ...this.state.queue[subscription.subscription.id], note: note };
        this.setState({ queue: queue });
    }

    public onChangeTerms = (key: string, checked: boolean) => {
        const terms: any = { ...this.state.terms };
        terms[key] = checked;
        this.setState({ terms: terms });
    }

    public onSubmit = async () => {

        const queue: any = {};
        Object.keys(this.state.queue).forEach((subscriptionId: string) => {
            queue[subscriptionId] = { ...this.state.queue[subscriptionId], status: 'waiting', error: null };
        });

        this.setState({ loading: true, queue: queue });

        await Object.keys(this.state.queue).reduce(async (memo: any, subscriptionId: string) => {

            await memo;

            try {

                let queue: any = { ...this.state.queue };
                queue[subscriptionId] = { ...this.state.queue[subscriptionId], status: 'processing', error: null };
                this.setState({ queue: queue });

                const tenantId = (this.props.match.params as any).tenantId;
                const data: SetMicrosoftSubscriptionQuantityDTO = {
                    subscriptionId: subscriptionId,
                    quantity: this.state.queue[subscriptionId].quantity,
                    note: this.state.queue[subscriptionId].note,
                };
                await Axios.patch(window.env.REACT_APP_API_BASE + '/api/microsoft/' + tenantId + '/subscriptions/quantity', data);

                queue = { ...this.state.queue };
                queue[subscriptionId] = { ...this.state.queue[subscriptionId], status: 'provisioned', error: null };
                this.setState({ queue: queue });

            } catch (error: any) {

                const queue: any = { ...this.state.queue };
                queue[subscriptionId] = {
                    ...this.state.queue[subscriptionId],
                    error: error?.response?.data?.errorCode ? error.response.data.errorCode : 'error.microsoft.checkout.undefined',
                    status: 'failed',
                };
                this.setState({ queue: queue });

                console.error(error);

            }

        }, null);

        this.setState({
            loading: false,
            finished: true,
        })

    }

    render() {

        const columns: IColumn[] = [
            {
                key: 'name',
                name: this.props.t('microsoft.subscriptions.checkout.headers.name'),
                minWidth: 480,
                columnActionsMode: ColumnActionsMode.disabled,
                isMultiline: true,
                isResizable: false,
                onRender: (subscription: GetMicrosoftSubscriptionsDTO) => {
                    return (
                        <Flex gap="gap.small" className="product">
                            <img src={subscription.product.tileImage ? subscription.product.tileImage : placeholder} alt="" />
                            <div>
                                <div>
                                    <Text size="medium" weight="semibold">
                                        {LocalizationService.getLocalizedString(subscription.product.title, this.props.i18n.language)}
                                        <MicrosoftProductTypeBadge productType={subscription.product.productType} />
                                    </Text>
                                </div>
                                {/* <div><Text size="small">{LocalizationService.getLocalizedString(subscription.product.summary, this.props.i18n.language)}</Text></div> */}
                            </div>
                        </Flex>
                    );
                }
            },
            {
                key: 'current',
                name: this.props.t('microsoft.subscriptions.checkout.headers.current'),
                minWidth: 120,
                columnActionsMode: ColumnActionsMode.disabled,
                isResizable: false,
                onRender: (subscription: GetMicrosoftSubscriptionsDTO) => {
                    return <Text size="medium">{subscription.subscription.quantity}</Text>;
                }
            },
            {
                key: 'new',
                name: this.props.t('microsoft.subscriptions.checkout.headers.new'),
                minWidth: 120,
                columnActionsMode: ColumnActionsMode.disabled,
                isResizable: false,
                onRender: (subscription: GetMicrosoftSubscriptionsDTO) => {
                    return <Text size="medium">{this.props.formData[subscription.subscription.id]}</Text>;
                }
            },
            {
                key: 'change',
                name: this.props.t('microsoft.subscriptions.checkout.headers.change'),
                minWidth: 120,
                columnActionsMode: ColumnActionsMode.disabled,
                isResizable: false,
                onRender: (subscription: GetMicrosoftSubscriptionsDTO) => {
                    return <Text size="medium">
                        {this.props.formData[subscription.subscription.id] > subscription.subscription.quantity ? '+' : null}
                        {this.props.formData[subscription.subscription.id] - subscription.subscription.quantity}
                    </Text>;
                }
            },
            {
                key: 'note',
                name: this.props.t('microsoft.subscriptions.checkout.headers.note'),
                minWidth: 320,
                columnActionsMode: ColumnActionsMode.disabled,
                isResizable: false,
                onRender: (subscription: GetMicrosoftSubscriptionsDTO) => {
                    return <Text size="medium">
                        {this.state.step === 1
                            ? <TextArea required={false} fluid={true} variables={{ height: '50px' }} resize="none"
                                value={this.state.queue[subscription.subscription.id].note}
                                onChange={(evt: any) => { this.onChangeNote(subscription, evt.target?.value) }} />
                            : <span style={{ whiteSpace: 'pre-wrap' }}>{this.state.queue[subscription.subscription.id].note}</span>
                        }
                    </Text>;
                }
            }
        ];

        let content = null;
        if (this.state.step === 1) {
            content = (
                <React.Fragment>
                    <h2>{this.props.t('microsoft.subscriptions.checkout.summary.title')}</h2>
                    {this.state.subscriptions.length === 0
                        ? <Alert warning content={this.props.t('microsoft.subscriptions.checkout.summary.empty')} />
                        : <DetailsList columns={columns} items={this.state.subscriptions} selectionMode={SelectionMode.none} layoutMode={DetailsListLayoutMode.fixedColumns} />}
                    <div className={styles.MicrosoftSubscriptionsFooter}>
                        <Button content={this.props.t('microsoft.subscriptions.checkout.buttons.cancel')} onClick={() => this.props.history.push(`${this.props.location.pathname.replace('/checkout', '')}`)} />
                        <Button primary content={this.props.t('microsoft.subscriptions.checkout.buttons.continue')} onClick={() => this.navigateTo(this.state.termsAccepted ? 3 : 2)} disabled={!this.subscriptionsChanged()} />
                    </div>
                </React.Fragment >
            );
        } else if (this.state.step === 2) {
            content = (
                <React.Fragment>
                    <h2>{this.props.t('microsoft.subscriptions.checkout.terms.title')}</h2>
                    <p>{this.props.t('microsoft.subscriptions.checkout.terms.summary')}</p>
                    {this.state.termsAccepted && <div style={{ marginBottom: '12px' }}><MessageBar messageBarType={MessageBarType.success}>{this.props.t('microsoft.subscriptions.checkout.terms.accepted', { date: moment(this.state.termsAccepted).format('DD.MM.YYYY') })}</MessageBar></div>}
                    <div><Checkbox label={<span dangerouslySetInnerHTML={{ __html: this.props.t('microsoft.subscriptions.checkout.terms.tou', { url: window.env[`REACT_APP_LEGAL_TOU_URL_${this.props.i18n.language}`.toUpperCase()] }) }} />}
                        checked={this.state.terms.tou} onChange={(evt: any, input: any) => { this.onChangeTerms('tou', input.checked) }} /></div>
                    <div><Checkbox label={<span dangerouslySetInnerHTML={{ __html: this.props.t('microsoft.subscriptions.checkout.terms.gtc', { url: window.env[`REACT_APP_LEGAL_GTC_URL_${this.props.i18n.language}`.toUpperCase()] }) }} />}
                        checked={this.state.terms.gtc} onChange={(evt: any, input: any) => { this.onChangeTerms('gtc', input.checked) }} /></div>
                    <div><Checkbox label={<span dangerouslySetInnerHTML={{ __html: this.props.t('microsoft.subscriptions.checkout.terms.msca', { url: window.env[`REACT_APP_LEGAL_MSCA_URL_${this.props.i18n.language}`.toUpperCase()] }) }} />}
                        checked={this.state.terms.msca} onChange={(evt: any, input: any) => { this.onChangeTerms('msca', input.checked) }} /></div>
                    <div className={styles.MicrosoftSubscriptionsFooter}>
                        <Button content={this.props.t('microsoft.subscriptions.checkout.buttons.back')} onClick={() => this.navigateTo(1)} />
                        <Button primary content={this.props.t('microsoft.subscriptions.checkout.buttons.continue')} onClick={() => this.navigateTo(3)}
                            disabled={!this.state.terms.tou || !this.state.terms.gtc || !this.state.terms.msca} />
                    </div>
                </React.Fragment>
            );
        } else if (this.state.step === 3) {
            const stateColumns = [...columns];
            stateColumns.splice(4, 0, {
                key: 'status',
                name: this.props.t('microsoft.subscriptions.checkout.headers.status'),
                minWidth: 300,
                columnActionsMode: ColumnActionsMode.disabled,
                isMultiline: true,
                isResizable: false,
                onRender: (subscription: GetMicrosoftSubscriptionsDTO) => {
                    const status = this.state.queue[subscription.subscription.id].status;
                    return <Flex vAlign="start" className="status">
                        <div className="status-icon">
                            <Text error={status === 'failed'} success={status === 'provisioned'}>
                                {status === 'open' ? <InformationOutlineIcon /> : null}
                                {status === 'waiting' ? <ClockOutlineIcon /> : null}
                                {status === 'processing' ? <LoadingIcon className="spin" /> : null}
                                {status === 'provisioned' ? <CheckCircleOutlineIcon /> : null}
                                {status === 'failed' ? <AlertCircleOutlineIcon /> : null}
                            </Text>
                        </div>
                        <div className="status-message">
                            <Text size="medium" error={status === 'failed'} success={status === 'provisioned'}>
                                &nbsp;<Text weight={status === 'failed' ? 'semibold' : 'regular'}>{this.props.t(`microsoft.subscriptions.checkout.finalize.status.${status}`)}</Text>
                                {this.state.queue[subscription.subscription.id].error ? <span>&nbsp;{this.props.t(this.state.queue[subscription.subscription.id].error)}</span> : null}
                            </Text>
                        </div>
                    </Flex>;
                }
            });
            content = (
                <React.Fragment>
                    <h2>{this.props.t('microsoft.subscriptions.checkout.finalize.title')}</h2>
                    {this.state.subscriptions.some((subscription: GetMicrosoftSubscriptionsDTO) => subscription.product.productType === 'OnlineServicesNCE') && <div style={{ marginBottom: '12px' }}>
                        <MessageBar messageBarType={MessageBarType.warning}>{this.props.t('microsoft.subscriptions.checkout.finalize.delay')}</MessageBar>
                    </div>}
                    <DetailsList columns={stateColumns} items={this.state.subscriptions} selectionMode={SelectionMode.none} layoutMode={DetailsListLayoutMode.fixedColumns} />
                    <div className={styles.MicrosoftSubscriptionsFooter}>
                        {!this.state.finished ? <React.Fragment>
                            <Button content={this.props.t('microsoft.subscriptions.checkout.buttons.back')} onClick={() => this.navigateTo(2)} disabled={this.state.loading} />
                            <Button primary content={this.props.t('microsoft.subscriptions.checkout.buttons.order')} onClick={() => this.onSubmit()} disabled={this.state.loading} />
                        </React.Fragment> : <Button primary content={this.props.t('microsoft.subscriptions.checkout.buttons.finish')} onClick={() => this.props.history.push(`${this.props.location.pathname.replace('/subscriptions/checkout', '/dashboard')}`)} />}
                    </div>
                </React.Fragment >
            );
        }

        return (
            <div className={styles.MicrosoftSubscriptionsCheckout}>
                <Flex vAlign="center" hAlign="center" className="progress">
                    <div className={this.state.step >= 1 ? 'step active' : 'step'}>
                        <div className="label">{this.state.step > 1 ? <CheckIcon /> : <Text size="large" weight="semibold">1</Text>}</div>
                    </div>
                    <div className="step-label">{this.props.t('microsoft.subscriptions.checkout.steps.step1')}</div>
                    <div className={this.state.step >= 2 ? 'ruler active' : 'ruler'}><div className="hr" /></div>
                    <div className={this.state.step >= 2 ? 'step active' : 'step'}>
                        <div className="label">{this.state.step > 2 ? <CheckIcon /> : <Text size="large" weight="semibold">2</Text>}</div>
                    </div>
                    <div className="step-label">{this.props.t('microsoft.subscriptions.checkout.steps.step2')}</div>
                    <div className={this.state.step >= 3 ? 'ruler active' : 'ruler'}><div className="hr" /></div>
                    <div className={this.state.step >= 3 ? 'step active' : 'step'}>
                        <div className="label">{this.state.finished ? <CheckIcon /> : <Text size="large" weight="semibold">3</Text>}</div>
                    </div>
                    <div className="step-label">{this.props.t('microsoft.subscriptions.checkout.steps.step3')}</div>
                </Flex>
                {content}
            </div>
        );
    }

}

export default withTranslation()(MicrosoftSubscriptionsCheckout);
import { Button, Flex, Loader, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import { GetMicrosoftSubscriptionsDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsDTO';
import { GetMicrosoftSubscriptionsSubscriptionDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsSubscriptionDTO';
import { GetMicrosoftSubscriptionsSubscriptionStatusDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsSubscriptionStatusDTO';
import { LocalizationService } from '../../../services/LocalizationService';
import styles from './MicrosoftDashboard.module.scss';
import moment from 'moment';
import { ColumnActionsMode, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react';
import placeholder from '../../../images/product-placeholder.png';
import { GetMicrosoftSubscriptionsFilterDTO } from '../../../models/Microsoft/getMicrosoftSubscriptionsFilterDTO';
import MicrosoftProductTypeBadge from '../../../components/microsoft/ProductTypeBadge';

interface IMicrosoftDashboardSubscriptionsProps extends WithTranslation, RouteComponentProps {

}

interface IMicrosoftDashboardSubscriptionsState {
    error: IAlertErrorProps | null;
    loading: boolean;
    subscriptions: GetMicrosoftSubscriptionsDTO[];
}

class MicrosoftDashboardSubscriptions extends React.Component<IMicrosoftDashboardSubscriptionsProps, IMicrosoftDashboardSubscriptionsState> {

    public state: IMicrosoftDashboardSubscriptionsState = {
        error: null,
        loading: true,
        subscriptions: [],
    }

    public componentDidMount() {
        this.getTenantSubscriptions();
    }

    public componentDidUpdate(prevProps: IMicrosoftDashboardSubscriptionsProps) {
        if ((prevProps.match.params as any).tenantId !== (this.props.match.params as any).tenantId) {
            this.getTenantSubscriptions();
        }
    }

    public getTenantSubscriptions = async () => {
        const tenantId = (this.props.match.params as any).tenantId;
        this.setState({ error: null, loading: true, subscriptions: [] });
        try {
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/' + tenantId + '/subscriptions?filter=' + GetMicrosoftSubscriptionsFilterDTO.active);
            this.setState({ loading: false, subscriptions: response.data });
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getTenantSubscriptions() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.microsoft.subscriptions.undefined', retry: () => this.getTenantSubscriptions() } });
            }
        }
    }

    public getSubscriptionStatus = (subscription: GetMicrosoftSubscriptionsSubscriptionDTO): string => {
        if (subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.none) {
            return this.props.t('microsoft.dashboard.subscriptions.status.none');
        } else if (subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.active && subscription.autoRenewEnabled) {
            return this.props.t('microsoft.dashboard.subscriptions.status.activeWithRenewal', { date: moment(subscription.commitmentEndDate).format('L') });
        } else if (subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.active && !subscription.autoRenewEnabled) {
            return this.props.t('microsoft.dashboard.subscriptions.status.active');
        } else if (subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.suspended) {
            return this.props.t('microsoft.dashboard.subscriptions.status.suspended');
        } else if (subscription.status === GetMicrosoftSubscriptionsSubscriptionStatusDTO.deleted) {
            return this.props.t('microsoft.dashboard.subscriptions.status.deleted');
        }
        return this.props.t('microsoft.dashboard.subscriptions.status.unknown');
    }

    public getTable = () => {

        const columns: IColumn[] = [
            {
                key: 'name',
                name: this.props.t('microsoft.dashboard.subscriptions.headers.name'),
                minWidth: 0,
                columnActionsMode: ColumnActionsMode.disabled,
                isMultiline: true,
                isResizable: false,
                onRender: (subscription: GetMicrosoftSubscriptionsDTO) => {
                    return (
                        <Flex gap="gap.small">
                            <img src={subscription.product.tileImage ? subscription.product.tileImage : placeholder} alt="" />
                            <div>
                                <div>
                                    <Text size="medium" weight="semibold">
                                        {LocalizationService.getLocalizedString(subscription.product.title, this.props.i18n.language)}
                                        <MicrosoftProductTypeBadge productType={subscription.product.productType} />
                                    </Text>
                                </div>
                                <div><Text size="small">{LocalizationService.getLocalizedString(subscription.product.summary, this.props.i18n.language)}</Text></div>
                            </div>
                        </Flex>
                    );
                }
            },
            {
                key: 'quantity',
                name: this.props.t('microsoft.dashboard.subscriptions.headers.quantity'),
                minWidth: 100,
                maxWidth: 100,
                columnActionsMode: ColumnActionsMode.disabled,
                isResizable: false,
                onRender: (subscription: GetMicrosoftSubscriptionsDTO) => {
                    return <Text size="small">{subscription.subscription.quantity}</Text>;
                }
            },
            /* disabled as it might be confusing for customers, they are able to cancel on a daily basis
            {
                key: 'status',
                name: this.props.t('microsoft.dashboard.subscriptions.headers.status'),
                minWidth: 240,
                maxWidth: 240,
                isMultiline: true,
                isResizable: false,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (subscription: GetMicrosoftSubscriptionsDTO) => {
                    return <Text size="small">{this.getSubscriptionStatus(subscription.subscription)}</Text>;
                }
            }
            */
        ];

        return <DetailsList columns={columns} items={this.state.subscriptions} layoutMode={DetailsListLayoutMode.justified} selectionMode={SelectionMode.none} />;

    }

    render() {
        return (
            <div className={styles.MicrosoftDashboardSubscriptions}>
                <Segment style={{ marginBottom: '1px' }}>
                    <Text size="large">{this.props.t('microsoft.dashboard.subscriptions.title')}</Text>
                </Segment>
                <Segment>
                    {this.state.loading ? <Loader /> : null}
                    {!this.state.loading && this.state.error ? <AlertError {...this.state.error} /> : null}
                    {!this.state.loading && !this.state.error && this.state.subscriptions.length === 0 ? <div>{this.props.t('microsoft.dashboard.subscriptions.noresults')}</div> : null}
                    {!this.state.loading && !this.state.error && this.state.subscriptions.length > 0 ? <React.Fragment>
                        {this.getTable()}
                        <div className="manage-subscriptions">
                            <Button primary content={this.props.t('microsoft.dashboard.subscriptions.manage')}
                                onClick={() => this.props.history.push(`/microsoft/${(this.props.match.params as any).tenantId}/subscriptions`)} />
                        </div>
                    </React.Fragment> : null}
                </Segment>
            </div>
        );
    }

}

export default withTranslation()(MicrosoftDashboardSubscriptions);
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { Alert, Button, CloseIcon, Dialog, Flex, Input, Loader, SearchIcon, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import { GetTenantsDTO } from '../../../../models/Administration/MicrosoftTenantController/getTenantsDTO';
import { Persona, PersonaSize } from '@fluentui/react';
import AccountRemoveIcon from 'mdi-react/AccountRemoveIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import { GetTenantUsersDTO } from '../../../../models/Administration/MicrosoftTenantController/getTenantUsersDTO';
import { GetUsersDTO } from '../../../../models/Administration/UserController/getUsersDTO';
import { FormValidationMessages } from '../../../../components/formValidation/FormValidationMessages';
import AlertError, { IAlertErrorProps } from '../../../../components/alerts/AlertError';
import styles from './MicrosoftTenants.module.scss';
import validate from 'validate.js';

interface IMicrosoftTenantUsersDialogProps extends WithTranslation {
    onClose?: any;
    tenant: GetTenantsDTO;
}

interface IMicrosoftTenantUsersDialogState {
    error: string | null;
    assigned: { loading: boolean, users?: GetTenantUsersDTO[], error: IAlertErrorProps | null };
    assignedUserRemoved: GetTenantUsersDTO | null;
    available: { loading: boolean, users?: GetUsersDTO[], error: IAlertErrorProps | null };
    search: { input: string, users: GetUsersDTO[] };
    inviteLoading: boolean;
    inviteError: any;
    inviteSuccess: any;
    inviteFormData: {
        invitedUserDisplayName: string;
        invitedUserEmailAddress: string;
        inviteRedirectUrl: string;
    };
    inviteFormValidation?: {
        invitedUserDisplayName?: string[];
        invitedUserEmailAddress?: string[];
    };
}

class MicrosoftTenantUsersDialog extends React.Component<IMicrosoftTenantUsersDialogProps, IMicrosoftTenantUsersDialogState> {

    public state: IMicrosoftTenantUsersDialogState = {
        error: null,
        assigned: { loading: true, error: null },
        assignedUserRemoved: null,
        available: { loading: true, error: null },
        search: { input: '', users: [] },
        inviteLoading: false,
        inviteError: null,
        inviteSuccess: null,
        inviteFormData: {
            invitedUserDisplayName: '',
            invitedUserEmailAddress: '',
            inviteRedirectUrl: `${window.location.protocol}//${window.location.host}`,
        },
        inviteFormValidation: {},
    }

    public async componentDidMount() {
        this.getAssignedUsers();
        this.getAvailableUsers();
    }

    public getAssignedUsers = async () => {
        try {
            this.setState({ assigned: { loading: true, users: [], error: null } });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/users');
            this.setState({ assigned: { loading: false, users: response.data, error: null } });
            this.onSearch(this.state.search.input);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ assigned: { loading: false, error: { message: error.response.data.errorCode, retry: () => this.getAssignedUsers() } } });
            } else {
                this.setState({ assigned: { loading: false, error: { message: 'error.admin.microsoft.tenants.loadassignedusers', retry: () => this.getAssignedUsers() } } });
            }
        }
    }

    public getAvailableUsers = async () => {
        try {
            this.setState({ available: { loading: true, error: null } });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/users/available');
            this.setState({ available: { loading: false, users: response.data, error: null } });
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ available: { loading: false, error: { message: error.response.data.errorCode, retry: () => this.getAvailableUsers() } } });
            } else {
                this.setState({ available: { loading: false, error: { message: 'error.admin.microsoft.tenants.loadavailableusers', retry: () => this.getAvailableUsers() } } });
            }
        }
    }

    public onSearch = (searchInput: string) => {
        const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
        let searchResults: GetUsersDTO[] = [];
        if (this.state.available.users && this.state.assigned.users && searchInput && searchInput.length >= 2) {
            searchResults = this.state.available.users.filter((user: GetUsersDTO) => this.state.assigned.users && !this.state.assigned.users.some((tenantUser: GetTenantUsersDTO) => tenantUser.id === user.id) && (user.displayName?.match(searchRegex) || user.mail?.match(searchRegex)));
        }
        this.setState({ search: { input: searchInput, users: searchResults.slice(0, 10) } });
    }

    public onChangeInvite = (field: string, value: any) => {
        const inviteFormData: any = { ...this.state.inviteFormData };
        inviteFormData[field] = value;
        this.setState({ inviteFormData: inviteFormData });
    }

    public onSubmitInvite = async () => {

        const errors = validate(this.state.inviteFormData, {
            invitedUserDisplayName: {
                presence: { allowEmpty: false, message: this.props.t('admin.users.dialog.invite.validation.presence') },
            },
            invitedUserEmailAddress: {
                presence: { allowEmpty: false, message: this.props.t('admin.users.dialog.invite.validation.presence') },
                email: { message: this.props.t('admin.users.dialog.invite.validation.email') },
            },
        });

        this.setState({ inviteError: null, inviteSuccess: null, inviteFormValidation: errors });

        if (!errors) {
            try {
                this.setState({ inviteLoading: true, inviteError: null, inviteSuccess: null });
                await Axios.post(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/invite', this.state.inviteFormData);
                this.setState({
                    assignedUserRemoved: null,
                    inviteLoading: false,
                    inviteFormValidation: {},
                    inviteSuccess: {
                        message: 'admin.microsoft.tenants.dialog.users.sections.invite.success',
                        data: {
                            name: this.state.inviteFormData.invitedUserDisplayName,
                            tenant: this.props.tenant.name,
                        }
                    },
                    inviteFormData: {
                        invitedUserDisplayName: '',
                        invitedUserEmailAddress: '',
                        inviteRedirectUrl: `${window.location.protocol}//${window.location.host}`,
                    }
                });
                this.getAssignedUsers();
            } catch (error: any) {
                console.error(error);
                if (error?.response?.data?.errorCode) {
                    this.setState({ inviteLoading: false, inviteError: { message: error.response.data.errorCode } });
                } else {
                    this.setState({ inviteLoading: false, inviteError: { message: 'error.admin.microsoft.tenants.inviteuser' } });
                }
            }
        }

    }

    public onAddUser = async (user: GetUsersDTO | GetTenantUsersDTO | null) => {
        try {
            if (user === null) return;
            this.setState({ assigned: { ...this.state.assigned, loading: true }, assignedUserRemoved: null });
            await Axios.put(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/users/' + user?.id);
            this.getAssignedUsers();
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ assigned: { ...this.state.assigned, loading: false, error: { message: error.response.data.errorCode, retry: () => this.onAddUser(user) } } });
            } else {
                this.setState({ assigned: { ...this.state.assigned, loading: false, error: { message: 'error.admin.microsoft.tenants.adduser', retry: () => this.onAddUser(user) } } });
            }
        }
    }

    public onRemoveUser = async (user: GetTenantUsersDTO) => {
        try {
            this.setState({ assigned: { ...this.state.assigned, loading: true }, assignedUserRemoved: null });
            await Axios.delete(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants/' + this.props.tenant.id + '/users/' + user.id);
            this.setState({ assignedUserRemoved: user });
            this.getAssignedUsers();
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ assigned: { ...this.state.assigned, loading: false, error: { message: error.response.data.errorCode, retry: () => this.onRemoveUser(user) } } });
            } else {
                this.setState({ assigned: { ...this.state.assigned, loading: false, error: { message: 'error.admin.microsoft.tenants.removeuser', retry: () => this.onRemoveUser(user) } } });
            }
        }
    }

    render() {

        const form = (
            <div className={styles.MicrosoftTenantUsersDialog}>
                <Flex gap="gap.small" style={{ marginTop: '8px' }}>
                    <div style={{ width: '50%' }}>
                        <Segment style={{ marginBottom: '1px' }}>
                            <strong>{this.props.t('admin.microsoft.tenants.dialog.users.sections.assigned.title')}</strong>
                        </Segment>
                        <Segment className="assigned">
                            {!this.state.assigned.loading && this.state.assigned.error ? <AlertError {...this.state.assigned.error} /> : null}
                            {this.state.assigned.loading ? <Loader /> : <React.Fragment>
                                {this.state.assignedUserRemoved ? <Alert warning={true} className="removed">
                                    {this.props.t('admin.microsoft.tenants.dialog.users.sections.assigned.removed', { name: this.state.assignedUserRemoved.displayName })}
                                    <Text weight="semibold" className="revert" onClick={() => this.onAddUser(this.state.assignedUserRemoved)}>
                                        {this.props.t('admin.microsoft.tenants.dialog.users.sections.assigned.revert')}
                                    </Text>
                                </Alert> : null}
                                {this.state.assigned.users && this.state.assigned.users.length === 0 ? <div>{this.props.t('admin.microsoft.tenants.dialog.users.sections.assigned.empty')}</div> : null}
                                {this.state.assigned.users && this.state.assigned.users.length > 0 ? this.state.assigned.users.map((user: GetTenantUsersDTO) => (
                                    <Flex key={user.id} space="between" vAlign="center" className="item">
                                        <Persona text={user.displayName} secondaryText={user.mail} showSecondaryText={true} size={PersonaSize.size32} />
                                        <Button size="small" icon={<AccountRemoveIcon />} content={this.props.t('admin.microsoft.tenants.dialog.users.sections.assigned.remove')} onClick={() => this.onRemoveUser(user)} />
                                    </Flex>
                                )) : null}
                            </React.Fragment>}
                        </Segment>
                    </div>
                    <div style={{ width: '50%' }}>
                        <Segment style={{ marginBottom: '1px' }}>
                            <strong>{this.props.t('admin.microsoft.tenants.dialog.users.sections.available.title')}</strong>
                        </Segment>
                        <Segment className="available">
                            {!this.state.available.loading && this.state.available.error ? <AlertError {...this.state.available.error} /> : null}
                            {!this.state.available.error ? <div>
                                <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off" icon={<SearchIcon />} placeholder={this.props.t('admin.microsoft.tenants.dialog.users.sections.available.search')}
                                    disabled={this.state.available.loading} value={this.state.search.input} onChange={(evt: any) => this.onSearch(evt.target?.value)} />
                            </div> : null}
                            {this.state.search.users.map((user: GetUsersDTO) => (
                                <Flex key={user.id} space="between" vAlign="center" className="item">
                                    <Persona text={user.displayName} secondaryText={user.mail} showSecondaryText={true} size={PersonaSize.size32} />
                                    <Button size="small" icon={<AccountPlusIcon />} content={this.props.t('admin.microsoft.tenants.dialog.users.sections.available.add')} onClick={() => this.onAddUser(user)} />
                                </Flex>
                            ))}
                        </Segment>
                        {this.state.search.input === undefined || this.state.search.input.length === 0 ? <React.Fragment>
                            <Segment style={{ marginTop: '12px', marginBottom: '1px' }}>
                                <strong>{this.props.t('admin.microsoft.tenants.dialog.users.sections.invite.title')}</strong>
                            </Segment>
                            <Segment className="invite">
                                {!this.state.inviteLoading && this.state.inviteError ? <Alert danger={true}><span><Trans i18nKey={this.state.inviteError.message} values={this.state.inviteError.data} /></span></Alert> : null}
                                {!this.state.inviteLoading && this.state.inviteSuccess ? <Alert success={true}><span><Trans i18nKey={this.state.inviteSuccess.message} values={this.state.inviteSuccess.data} /></span></Alert> : null}
                                <div style={{ marginBottom: '12px' }}>
                                    <Input label={this.props.t('admin.microsoft.tenants.dialog.users.sections.invite.name')} placeholder={this.props.t('admin.microsoft.tenants.dialog.users.sections.invite.name')}
                                        required={true} fluid={true} autoComplete="off" autoCorrect="off"
                                        value={this.state.inviteFormData.invitedUserDisplayName} disabled={this.state.inviteLoading}
                                        showSuccessIndicator={false} onChange={(evt: any) => { this.onChangeInvite('invitedUserDisplayName', evt.target?.value) }}
                                        error={this.state.inviteFormValidation?.invitedUserDisplayName && this.state.inviteFormValidation?.invitedUserDisplayName.length > 0} />
                                    <FormValidationMessages messages={this.state.inviteFormValidation?.invitedUserDisplayName} />
                                </div>
                                <div style={{ marginBottom: '12px' }}>
                                    <Input type="email" label={this.props.t('admin.microsoft.tenants.dialog.users.sections.invite.mail')} placeholder={this.props.t('admin.microsoft.tenants.dialog.users.sections.invite.mail')}
                                        required={true} fluid={true} autoComplete="off" autoCorrect="off"
                                        value={this.state.inviteFormData.invitedUserEmailAddress} disabled={this.state.inviteLoading}
                                        showSuccessIndicator={false} onChange={(evt: any) => { this.onChangeInvite('invitedUserEmailAddress', evt.target?.value) }}
                                        error={this.state.inviteFormValidation?.invitedUserEmailAddress && this.state.inviteFormValidation?.invitedUserEmailAddress.length > 0} />
                                    <FormValidationMessages messages={this.state.inviteFormValidation?.invitedUserEmailAddress} />
                                </div>
                                <div>
                                    <Button primary content={this.props.t('admin.microsoft.tenants.dialog.users.sections.invite.submit')} onClick={this.onSubmitInvite} disabled={this.state.inviteLoading} loading={this.state.inviteLoading} />
                                </div>
                            </Segment>
                        </React.Fragment> : null}
                    </div>
                </Flex>
            </div>
        );

        return (
            <Dialog
                open={true}
                closeOnOutsideClick={false}
                content={form}
                header={this.props.t('admin.microsoft.tenants.dialog.users.title', { name: this.props.tenant.name })}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('admin.microsoft.tenants.dialog.common.close'), onClick: () => this.props.onClose && this.props.onClose() }} />
        );

    }

}

export default withTranslation()(MicrosoftTenantUsersDialog);
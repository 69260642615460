import { AuthService } from "./AuthService";

export class Group {
    public static ADMINISTRATORS: string = window.env.REACT_APP_AAD_GROUP_ADMINISTRATORS || 'undefined';
    public static EMPLOYEES: string = window.env.REACT_APP_AAD_GROUP_EMPLOYEES || 'undefined';
    public static CUSTOMERS: string = window.env.REACT_APP_AAD_GROUP_CUSTOMERS || 'undefined';
    public static ANY: string[] = [Group.ADMINISTRATORS, Group.EMPLOYEES, Group.CUSTOMERS];
}

export class PermissionService {

    public static hasGroups(): boolean {
        return AuthService.getGroups() && AuthService.getGroups().length > 0;
    }

    public static isMemberOfGroup(group: string): boolean {
        return AuthService.getGroups() && AuthService.getGroups().indexOf(group) !== -1;
    }

    public static isMemberOfAnyGroup(groups: string[]): boolean {
        return AuthService.getGroups() && groups.some((group) => AuthService.getGroups().indexOf(group) !== -1);
    }

}
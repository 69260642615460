import React from 'react';
import { CloseIcon, Dialog } from '@fluentui/react-northstar';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

interface IMicrosoftSubscriptionScheduledChangesDeleteDialogProps extends WithTranslation {
    onClose?: any;
}

class MicrosoftSubscriptionScheduledChangesDeleteDialog extends React.Component<IMicrosoftSubscriptionScheduledChangesDeleteDialogProps> {

    render() {

        const content = (
            <React.Fragment>
                <div style={{ marginTop: '6px' }}><Trans i18nKey="microsoft.subscriptions.dialog.deletechanges.message" /></div>
            </React.Fragment>
        );

        return (
            <Dialog
                open={true}
                styles={{ width: '420px' }}
                closeOnOutsideClick={false}
                onCancel={() => this.props.onClose && this.props.onClose(false)}
                onConfirm={() => this.props.onClose && this.props.onClose(true)}
                cancelButton={{ content: this.props.t('microsoft.subscriptions.dialog.deletechanges.cancel') }}
                confirmButton={{ content: this.props.t('microsoft.subscriptions.dialog.deletechanges.confirm'), primary: true }}
                content={content}
                header={this.props.t('microsoft.subscriptions.dialog.deletechanges.title')}
                headerAction={{ icon: <CloseIcon />, title: this.props.t('microsoft.subscriptions.dialog.deletechanges.close'), onClick: () => this.props.onClose && this.props.onClose(false) }} />
        );

    }

}

export default withTranslation()(MicrosoftSubscriptionScheduledChangesDeleteDialog);
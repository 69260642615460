import { Button, Flex, Input, SearchIcon } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ReloadIcon from 'mdi-react/ReloadIcon';
import DatabaseSyncOutlineIcon from 'mdi-react/DatabaseSyncOutlineIcon';
import MicrosoftTenantsRefresh from './MicrosoftTenantsRefresh';
import NewspaperVariantIcon from 'mdi-react/NewspaperVariantIcon';
import AccountBoxOutlineIcon from 'mdi-react/AccountBoxOutlineIcon';
import AccountIcon from 'mdi-react/AccountIcon';
import { GetTenantsDTO } from '../../../../models/Administration/MicrosoftTenantController/getTenantsDTO';
import MicrosoftTenantContactsDialog from './MicrosoftTenantContactsDialog';
import MicrosoftTenantProductsDialog from './MicrosoftTenantProductsDialog';
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import MicrosoftTenantUsersDialog from './MicrosoftTenantUsersDialog';
import AlertError, { IAlertErrorProps } from '../../../../components/alerts/AlertError';

interface IMicrosoftTenantsProps extends WithTranslation {

}

interface IMicrosoftTenantsState {
    error: IAlertErrorProps | null;
    loading: boolean;
    tenants: GetTenantsDTO[],
    openSynchronizeTenants: boolean;
    openMicrosoftTenantContacts: boolean;
    openMicrosoftTenantProducts: boolean;
    openMicrosoftTenantUsers: boolean;
    selectedTenant: GetTenantsDTO | null;
    searchInput: string;
    items: GetTenantsDTO[],
}

class MicrosoftTenants extends React.Component<IMicrosoftTenantsProps, IMicrosoftTenantsState> {

    public state: IMicrosoftTenantsState = {
        error: null,
        loading: false,
        tenants: [],
        openSynchronizeTenants: false,
        openMicrosoftTenantContacts: false,
        openMicrosoftTenantProducts: false,
        openMicrosoftTenantUsers: false,
        selectedTenant: null,
        searchInput: '',
        items: [],
    }

    public async componentDidMount() {
        this.getTenants();
    }

    public getTenants = async () => {
        try {
            this.setState({ loading: true, tenants: [], error: null });
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/admin/microsoft/tenants');
            this.setState({ loading: false, tenants: response.data });
            this.onSearch(this.state.searchInput);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getTenants() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.admin.microsoft.tenants.loadlist', retry: () => this.getTenants() } });
            }
        }
    }

    public openSynchronizeTenants = () => {
        this.setState({ openSynchronizeTenants: true });
    }

    public closeSynchronizeTenants = () => {
        this.setState({ openSynchronizeTenants: false });
        this.getTenants();
    }

    public openMicrosoftTenantContacts = (tenant: GetTenantsDTO) => {
        this.setState({ openMicrosoftTenantContacts: true, selectedTenant: tenant });
    }

    public closeMicrosoftTenantContacts = () => {
        this.setState({ openMicrosoftTenantContacts: false, selectedTenant: null });
    }

    public openMicrosoftTenantProducts = (tenant: GetTenantsDTO) => {
        this.setState({ openMicrosoftTenantProducts: true, selectedTenant: tenant });
    }

    public closeMicrosoftTenantProducts = () => {
        this.setState({ openMicrosoftTenantProducts: false, selectedTenant: null });
    }

    public openMicrosoftTenantUsers = (tenant: GetTenantsDTO) => {
        this.setState({ openMicrosoftTenantUsers: true, selectedTenant: tenant });
    }

    public closeMicrosoftTenantUsers = () => {
        this.setState({ openMicrosoftTenantUsers: false, selectedTenant: null });
    }

    public onSearch = (searchInput: string) => {
        const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
        const items = this.state.tenants.filter((tenant: GetTenantsDTO) => tenant.name?.match(searchRegex) || tenant.domain?.match(searchRegex));
        this.setState({ searchInput: searchInput, items: items });
    }

    render() {

        const columns: IColumn[] = [{
            key: 'name',
            name: this.props.t('admin.microsoft.tenants.list.headers.name'),
            fieldName: 'name',
            minWidth: 0,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true
        }, {
            key: 'domain',
            name: this.props.t('admin.microsoft.tenants.list.headers.domain'),
            fieldName: 'domain',
            minWidth: 480,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true
        }, {
            key: 'actions',
            name: this.props.t('admin.microsoft.tenants.list.headers.actions'),
            minWidth: 550,
            maxWidth: 550,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (tenant: GetTenantsDTO) => {
                return <Flex gap="gap.small">
                    <Button content={this.props.t('admin.microsoft.tenants.list.actions.users')} icon={<AccountIcon />} iconPosition="before" onClick={() => this.openMicrosoftTenantUsers(tenant)} />
                    <Button content={this.props.t('admin.microsoft.tenants.list.actions.contacts')} icon={<AccountBoxOutlineIcon />} iconPosition="before" onClick={() => this.openMicrosoftTenantContacts(tenant)} />
                    <Button content={this.props.t('admin.microsoft.tenants.list.actions.products')} icon={<NewspaperVariantIcon />} iconPosition="before" onClick={() => this.openMicrosoftTenantProducts(tenant)} />
                </Flex>;
            }
        }];

        const table = <ShimmeredDetailsList
            columns={columns}
            items={this.state.items}
            layoutMode={DetailsListLayoutMode.justified}
            selectionMode={SelectionMode.none}
            enableShimmer={this.state.loading} />;

        return (
            <div>
                <h1>{this.props.t('admin.microsoft.tenants.list.title')}</h1>
                <Flex space="between">
                    <Flex gap="gap.small">
                        <Button content={this.props.t('admin.microsoft.tenants.list.refresh')} icon={<ReloadIcon />} iconPosition="before" onClick={this.getTenants} />
                        <Button content={this.props.t('admin.microsoft.tenants.list.sync')} icon={<DatabaseSyncOutlineIcon />} iconPosition="before" onClick={this.openSynchronizeTenants} />
                    </Flex>
                    <div style={{ width: '280px' }}>
                        {!this.state.loading && !this.state.error ? <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off" icon={<SearchIcon />} placeholder={this.props.t('admin.microsoft.tenants.list.search')}
                            value={this.state.searchInput} onChange={(evt: any) => this.onSearch(evt.target?.value)} /> : null}
                    </div>
                </Flex>
                {!this.state.error ? table : null}
                {!this.state.loading && this.state.error ? <div style={{ marginTop: '12px' }}><AlertError {...this.state.error} /></div> : null}
                {this.state.openSynchronizeTenants ? <MicrosoftTenantsRefresh onClose={this.closeSynchronizeTenants} /> : null}
                {this.state.openMicrosoftTenantUsers && this.state.selectedTenant ? <MicrosoftTenantUsersDialog onClose={this.closeMicrosoftTenantUsers} tenant={this.state.selectedTenant} /> : null}
                {this.state.openMicrosoftTenantContacts && this.state.selectedTenant ? <MicrosoftTenantContactsDialog onClose={this.closeMicrosoftTenantContacts} tenant={this.state.selectedTenant} /> : null}
                {this.state.openMicrosoftTenantProducts && this.state.selectedTenant ? <MicrosoftTenantProductsDialog onClose={this.closeMicrosoftTenantProducts} tenant={this.state.selectedTenant} /> : null}
            </div>
        );

    }

}

export default withTranslation()(MicrosoftTenants);
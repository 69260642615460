import { Button, Flex, Grid, Input, Loader, SearchIcon, Segment, Text } from '@fluentui/react-northstar';
import Axios from 'axios';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import AlertError, { IAlertErrorProps } from '../../../components/alerts/AlertError';
import { GetMicrosoftProductsDTO } from '../../../models/Microsoft/getMicrosoftProductsDTO';
import { LocalizationService } from '../../../services/LocalizationService';
import MicrosoftProductRequest from './MicrosoftProductRequest';
import placeholder from '../../../images/product-placeholder.png';
import styles from './MicrosoftProducts.module.scss';
import MicrosoftProductTypeBadge from '../../../components/microsoft/ProductTypeBadge';

interface IMicrosoftProductsProps extends WithTranslation, RouteComponentProps {

}

interface IMicrosoftProductsState {
    error: IAlertErrorProps | null;
    loading: boolean;
    products: GetMicrosoftProductsDTO[];
    items: GetMicrosoftProductsDTO[];
    openRequestDialog: boolean;
    selectedProduct: string | null;
    searchInput: string;
}

class MicrosoftProducts extends React.Component<IMicrosoftProductsProps, IMicrosoftProductsState> {

    public state: IMicrosoftProductsState = {
        error: null,
        loading: false,
        products: [],
        items: [],
        openRequestDialog: false,
        selectedProduct: null,
        searchInput: '',
    };

    public componentDidMount() {
        this.getTenantProducts();
    }

    public componentDidUpdate(prevProps: IMicrosoftProductsProps) {
        if ((prevProps.match.params as any).tenantId !== (this.props.match.params as any).tenantId) {
            this.getTenantProducts();
        }
    }

    public getTenantProducts = async () => {
        const tenantId = (this.props.match.params as any).tenantId;
        this.setState({ error: null, loading: true, products: [], items: [], searchInput: '' });
        try {
            const response = await Axios.get(window.env.REACT_APP_API_BASE + '/api/microsoft/' + tenantId + '/products');
            this.setState({ loading: false, products: response.data });
            this.onSearch(this.state.searchInput);
        } catch (error: any) {
            console.error(error);
            if (error?.response?.data?.errorCode) {
                this.setState({ loading: false, error: { message: error.response.data.errorCode, retry: () => this.getTenantProducts() } });
            } else {
                this.setState({ loading: false, error: { message: 'error.microsoft.products.undefined', retry: () => this.getTenantProducts() } });
            }
        }
    }

    public onSearch = (searchInput: string) => {
        const searchRegex: RegExp = new RegExp('.*' + (searchInput || '').replace(/\s+/gi, '.*') + '.*', 'gi');
        const items = this.state.products.filter((product: GetMicrosoftProductsDTO) => LocalizationService.getLocalizedString(product?.title, this.props.i18n.language).match(searchRegex));
        this.setState({ searchInput: searchInput, items: items });
    }

    public openRequestDialog = (product: GetMicrosoftProductsDTO) => {
        this.setState({ openRequestDialog: true, selectedProduct: product.id });
    }

    public closeRequestDialog = () => {
        this.setState({ openRequestDialog: false });
    }

    render() {
        return (
            <div className={styles.MicrosoftProducts}>
                <Flex space="between" vAlign="start">
                    <h1>{this.props.t('microsoft.products.title')}</h1>
                    {!this.state.loading && !this.state.error ? <div className="search">
                        <Input fluid={true} clearable={true} autoComplete="off" autoCorrect="off"
                            icon={<SearchIcon />} placeholder={this.props.t('microsoft.products.search')}
                            value={this.state.searchInput} onChange={(evt: any) => this.onSearch(evt.target?.value)} />
                    </div> : null}
                </Flex>
                {this.state.loading ? <Loader /> : null}
                {!this.state.loading && this.state.error ? <AlertError {...this.state.error} /> : null}
                {!this.state.loading && !this.state.error ? <React.Fragment>
                    <Grid columns={2}>
                        {this.state.items.map((product: GetMicrosoftProductsDTO) => {
                            return (
                                <Segment key={product.id} className="item">
                                    <Flex space="between">
                                        <Flex gap="gap.small" className="product">
                                            <img src={product.tileImage ? product.tileImage : placeholder} alt="" />
                                            <div>
                                                <div>
                                                    <Text weight="semibold">
                                                        {LocalizationService.getLocalizedString(product.title, this.props.i18n.language)}
                                                        <MicrosoftProductTypeBadge productType={product.productType} />
                                                    </Text>
                                                </div>
                                                <div><Text size="small">{LocalizationService.getLocalizedString(product.summary, this.props.i18n.language)}</Text></div>
                                            </div>
                                        </Flex>
                                        <div>
                                            <Button content={this.props.t('microsoft.products.contact')} onClick={() => this.openRequestDialog(product)} />
                                        </div>
                                    </Flex>
                                </Segment>
                            );
                        })}
                    </Grid>
                </React.Fragment> : null}
                {this.state.openRequestDialog && this.state.selectedProduct ? <MicrosoftProductRequest onClose={this.closeRequestDialog} tenantId={(this.props.match.params as any).tenantId} productId={this.state.selectedProduct} /> : null}
            </div>
        );
    }
}

export default withTranslation()(MicrosoftProducts);
import React from 'react';
import { Alert } from '@fluentui/react-northstar';
import { withTranslation, WithTranslation } from 'react-i18next';

const stylesRetry: any = {
    textDecoration: 'none',
    fontWeight: '600',
    cursor: 'pointer',
    marginLeft: '3px',
}

export interface IAlertErrorProps {
    message?: string | undefined;
    retry?: any;
}

interface IAlertErrorExtendedProps extends IAlertErrorProps, WithTranslation {

}

class AlertError extends React.Component<IAlertErrorExtendedProps> {
    render() {
        return (
            <Alert danger={true}>
                {this.props.children ? this.props.children : <React.Fragment>
                    {this.props.message ? this.props.t(this.props.message) : null}
                    {this.props.retry ? <span style={stylesRetry} onClick={this.props.retry}>{this.props.t('error.common.retry')}</span> : null}
                </React.Fragment>}
            </Alert>
        )
    }
}

export default withTranslation()(AlertError);